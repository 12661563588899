import { Helmet } from 'react-helmet-async';

function HomeHelmetComponent() {
  return (
    <Helmet>
      {/* Sivuston otsikko */}
      <title>Spiderlegs Productions | Tapahtumatekniikkaratkaisut</title>
      
      {/* Meta-kuvaus */}
      <meta name="description" content="Spiderlegs Productions tarjoaa räätälöidyt äänentoisto- ja valaistusratkaisut, jotka luovat upean tunnelman juhliin, häihin ja yritystilaisuuksiin." />
      
      {/* Avainsanat */}
      <meta name="keywords" content="äänentoisto, valaistus, tapahtumatekniikka, juhla, tilaisuudet, äänentoisto- ja valaistusratkaisut, bändit, häät, yritystapahtumat, juhla-tekniikka, tapahtumapalvelut, Etelä-Suomi, tekniset ratkaisut, vuokraus, räätälöity, tunnelma, tapahtumat" />
      
      {/* Sivuston kirjoittaja */}
      <meta name="author" content="Arttu Stenius" />
      
      {/* Kanoninen linkki */}
      <link rel="canonical" href="https://spiderlegsproductions.com/" />
      
      {/* Open Graph -tunnisteet */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Spiderlegs Productions | Tapahtumatekniikkaratkaisut" />
      <meta property="og:description" content="Spiderlegs Productions tarjoaa räätälöidyt äänentoisto- ja valaistusratkaisut, jotka luovat upean tunnelman juhliin, häihin ja yritystilaisuuksiin." />
      <meta property="og:image" content="https://spiderlegsproductions.com/assets/new_logo.webp" />
      <meta property="og:url" content="https://spiderlegsproductions.com/" />
      
      {/* Twitter-kortin tunnisteet */}
      <meta name="twitter:title" content="Spiderlegs Productions | Tapahtumatekniikkaratkaisut" />
      <meta name="twitter:description" content="Spiderlegs Productions tarjoaa räätälöidyt äänentoisto- ja valaistusratkaisut, jotka luovat upean tunnelman juhliin, häihin ja yritystilaisuuksiin." />
      <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/new_logo.webp" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default HomeHelmetComponent;
