import { Helmet } from 'react-helmet-async';

// Komponentti, joka määrittelee Helmet-elementit PartyBox300-sivulle
function PartyBox300HelmetComponent() {
  return (
    <Helmet>
      {/* Sivun otsikko */}
      <title>Spiderlegs productions | JBL Partybox 300 + mikit</title>
      
      {/* Meta-kuvaus */}
      <meta name="description" content="Bilekaiutin JBL PartyBoxilla ja langattomilla mikrofoneilla bileet pystyyn missä vain. Helppokäyttöinen ja kompakti kokonaisuus kaikkiin tilaisuuksiin!" />
      
      {/* Avainsanat */}
      <meta name="keywords" content="JBL, Partybox, langattomat, mikrofonit, bilekaiutin, häät, bileet" />
      
      {/* Sivuston kirjoittaja */}
      <meta name="author" content="Arttu Stenius" />
      
      {/* Kanoninen linkki */}
      <link rel='canonical' href='https://spiderlegsproductions.com/bilekaiutin-mikrofoneilla' />
      
      {/* Open Graph -tunnisteet */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Spiderlegs productions | JBL Partybox 300 + mikit" />
      <meta property="og:description" content="Bilekaiutin JBL PartyBoxilla ja langattomilla mikrofoneilla bileet pystyyn missä vain. Helppokäyttöinen ja kompakti kokonaisuus kaikkiin tilaisuuksiin!" />
      <meta property="og:image" content="https://spiderlegsproductions.com/assets/JBL_Partybox_mikit.webp" />
      <meta property="og:url" content="https://spiderlegsproductions.com/bilekaiutin-mikrofoneilla" />
      
      {/* Twitter-kortin tunnisteet */}
      <meta name="twitter:title" content="Spiderlegs productions | JBL Partybox 300 + mikit" />
      <meta name="twitter:description" content="Bilekaiutin JBL PartyBoxilla ja langattomilla mikrofoneilla bileet pystyyn missä vain. Helppokäyttöinen ja kompakti kokonaisuus kaikkiin tilaisuuksiin!" />
      <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/JBL_Partybox_mikit.webp" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default PartyBox300HelmetComponent;
