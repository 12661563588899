
import { Helmet } from 'react-helmet-async';

function UKKHelmetComponent() {
  return (
    <Helmet>
      {/* Sivun otsikko ja kuvaus */}
      <title>Spiderlegs productions | Usein kysytyt kysymykset</title>
      <meta name="description" content="Usein kysytyt kysymykset | Tältä sivulta löytyy vastauksia kiperiin kysymyksiin, joita voi juolahtaa mieleen vuokratessaan äänentoistoa | FAQ" />
      {/* Avainsanat */}
      <meta name="keywords" content="ukk, faq, usein, kysyttyä, spiderlegs, productions" />
      {/* Sivun kirjoittaja */}
      <meta name="author" content="Arttu Stenius" />
      {/* Canonical-linkki */}
      <link rel='canonical' href='https://spiderlegsproductions.com/UKK' />
      {/* Open Graph -metatiedot */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Spiderlegs productions | Usein kysytyt kysymykset" />
      <meta property="og:description" content="Usein kysytyt kysymykset | Tältä sivulta löytyy vastauksia kiperiin kysymyksiin, joita voi juolahtaa mieleen vuokratessaan äänentoistoa | FAQ" />
      <meta property="og:image" content="https://spiderlegsproductions.com/assets/new_logo.webp" />
      <meta property="og:url" content="https://spiderlegsproductions.com/UKK" />
      {/* Twitter-metatiedot */}
      <meta name="twitter:title" content="Spiderlegs productions | Usein kysytyt kysymykset" />
      <meta name="twitter:description" content="Usein kysytyt kysymykset | Tältä sivulta löytyy vastauksia kiperiin kysymyksiin, joita voi juolahtaa mieleen vuokratessaan äänentoistoa | FAQ" />
      <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/new_logo.webp" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default UKKHelmetComponent;
