import { Helmet } from 'react-helmet-async';

function ADJStinger2HelmetComponent() {
  return (
    <div>
      <Helmet>
        {/* Page title */}
        <title>Spiderlegs productions | Multiefekti ADJ Stinger II</title>
        
        {/* Meta description */}
        <meta name="description" content="Multiefekti (ADJ stinger II) on moonflower, strobo ja laser yhdessä paketissa. Vaikuttava valoshow tällä yhdellä valolla! Tutustu lisää sivuillamme!" />
        
        {/* Meta keywords */}
        <meta name="keywords" content="Multiefekti, ADJ, Stinger, II, strobo, led, moonflower, dmx, laser" />
        
        {/* Meta author */}
        <meta name="author" content="Arttu Stenius" />
        
        {/* Canonical link */}
        <link rel='canonical' href='https://spiderlegsproductions.com/multiefekti-adj-stinger-ii' />
        
        {/* Open Graph tags */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Spiderlegs productions | Multiefekti ADJ Stinger II" />
        <meta property="og:description" content="Multiefekti (ADJ stinger II) on moonflower, strobo ja laser yhdessä paketissa. Vaikuttava valoshow tällä yhdellä valolla! Tutustu lisää sivuillamme!" />
        <meta property="og:image" content="https://spiderlegsproductions.com/assets/Multiefekti_ADJ_Stinger_II.webp" />
        <meta property="og:url" content="https://spiderlegsproductions.com/multiefekti-adj-stinger-ii" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:title" content="Spiderlegs productions | Multiefekti ADJ Stinger II" />
        <meta name="twitter:description" content="Multiefekti (ADJ stinger II) on moonflower, strobo ja laser yhdessä paketissa. Vaikuttava valoshow tällä yhdellä valolla! Tutustu lisää sivuillamme!" />
        <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/Multiefekti_ADJ_Stinger_II.webp" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    </div>
  );
}

export default ADJStinger2HelmetComponent;
