import { useState, useEffect } from 'react';
import { MenuItems } from './MenuItems';
import './Dropdown.css';
import { Link } from 'react-router-dom';

function Dropdown() {
  const [click, setClick] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const mobileScreenWidth = 881;

  function handleClick(subMenuType) {
    setClick(false);
    if (subMenuType === 1) {
      window.scrollTo({ top: 680, behavior: 'smooth' });  // Vierittää "Äänentoiston vuokraus" -otsikkoon
    } else if (subMenuType === 2) {
      window.scrollTo({ top: 2050, behavior: 'smooth' });  // Vierittää "Esitystekniikan vuokraus" -otsikkoon
    } else if (subMenuType === 3) {
      window.scrollTo({ top: 3950, behavior: 'smooth' });  // Vierittää "Muut palvelut" -otsikkoon
    }
  }

  return (
    <ul
      className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
      style={{ display: windowWidth < mobileScreenWidth && 'none' }}
    >
      {MenuItems.map((item) => (
        <li key={item.id}>
          {item.subMenu1 && (
            <>
              <span className="dropdown-submenu">
                <Link to='/palvelut' onClick={() => handleClick(1)}>{item.title}</Link>
              </span>
              <ul className="submenu">
                {item.subMenu1.map((subItem) => (
                  <li key={subItem.id}>
                    <Link
                      className={subItem.cName}
                      to={subItem.path}
                      onClick={() => setClick(false)}
                    >
                      {subItem.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
          {item.subMenu2 && (
            <>
              <span className="dropdown-submenu">
                <Link to='/palvelut' onClick={() => handleClick(2)}>{item.title}</Link>
              </span>
              <ul className="submenu">
                {item.subMenu2.map((subItem) => (
                  <li key={subItem.id}>
                    <Link
                      className={subItem.cName}
                      to={subItem.path}
                      onClick={() => setClick(false)}
                    >
                      {subItem.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
          {item.subMenu3 && (
            <>
              <span className="dropdown-submenu">
                <Link to='/palvelut' onClick={() => handleClick(3)}>{item.title}</Link>
              </span>
              <ul className="submenu">
                {item.subMenu3.map((subItem) => (
                  <li key={subItem.id}>
                    <Link
                      className={subItem.cName}
                      to={subItem.path}
                      onClick={() => setClick(false)}
                    >
                      {subItem.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
        </li>
      ))}
    </ul>
  );
}

export default Dropdown;
