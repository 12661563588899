import Footer from '../../components/Footer/Footer';
import JBL625W from '../../components/JBL625W/JBL625W';
import JBL625WHelmetComponent from './JBL625WHelmetComponent'; // Lisätty Helmet-komponentti

export default function JBL625WPage() { // Muutettu funktion nimi camelCase-tyyliseksi
    return (
        <>
            <JBL625WHelmetComponent /> {/* Lisätty Helmet-komponentti */}
            <JBL625W />
            <Footer />
        </>
    );
}
