import { Link } from "react-router-dom";
import Footer from '../../components/Footer/Footer';

export default function NotFound() {
    return (

        <>

            <div className="container h-screen dark:bg-violet bg-primary opacity-75 dark:bg-opacity-75">

                    <h2 data-aos="fade-left" className='font-customFont2 text-shadow-lg text-darkest dark:text-primary text-center text-5xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold pt-44'>Valitettavasti sivua ei löydy!</h2><br />
                    
                    <p data-aos="fade-right" className="font-customFont1 text-shadow-lg text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal text-center">
            
                        Hups! Näyttää siltä, että etsit sivua, jota ei ole olemassa. Älä huoli, ei se haittaa!<br />
                        Klikkaa itesesi <Link className="text-darkest underline text-bold" to="/">tästä</Link> etusivulle.
                    </p>

            </div>

            <Footer />

        </>
    );
}

