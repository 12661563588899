export const MenuItems = [
  {
    id: 1,
    title: "Äänentoiston vuokraus",
    path: "/palvelut",
    cName: "dropdown-link",
    subMenu1: [
      {
        id: 2,
        title: "240W bilekaiutin mikrofoneilla",
        path: "/bilekaiutin-mikrofoneilla",
        cName: "submenu-link",
      },
      {
        id: 3,
        title: "300W äänentoistopaketti",
        path: "/300w-aanentoistopaketti",
        cName: "submenu-link",
      },
      {
        id: 4,
        title: "625W äänentoistopaketti",
        path: "/625w-aanentoistopaketti",
        cName: "submenu-link",
      },
      {
        id: 5,
        title: "2000W äänentoistopaketti",
        path: "/2000w-aanentoistopaketti",
        cName: "submenu-link",
      },
      {
        id: 6,
        title: "Monitori FM12 600W 4 kpl",
        path: "/lavamonitori-db-fm12-600w",
        cName: "submenu-link",
      },
      {
        id: 7,
        title: "Mikseri Soundcraft FX 16",
        path: "/SoundcraftFX16",
        cName: "submenu-link",
      },
    ],
  },
  {
    id: 8,
    title: "Esitystekniikan vuokraus",
    path: "/palvelut",
    cName: "dropdown-link",
    subMenu2: [
      {
        id: 9,
        title: "ADJ Stinger II Multiefekti kaukosäätimellä",
        path: "/multiefekti-adj-stinger-ii",
        cName: "submenu-link",
      },
      {
        id: 10,
        title: "ADJ VF Volcano tehokas savukone 6x3W ledeillä",
        path: "/savukone-adj-volcano",
        cName: "submenu-link",
      },
      {
        id: 11,
        title: "LED-värivalot Chauvet 56-24C 6kpl",
        path: "/led-valot-chauvet-56-24c",
        cName: "submenu-link",
      },
      {
        id: 12,
        title: "ADJ myDMX GO langaton DMX-ohjain",
        path: "/ADJmyDMXGO",
        cName: "submenu-link",
      },
    ],
  },

  {
    id: 12,
    title: "Muut palvelut",
    path: "/muut-vuokrattavat-tuotteet",
    cName: "dropdown-link",
    subMenu3: [
      {
        id: 13,
        title: "Fujifilm Instax Mini-12 kompaktikamera",
        path: "/fujifilm-instax-mini-12",
        cName: "submenu-link",
      },
      // Lisää muut palvelut...
    ],
  },
];
