// src/components/Hero/Hero.jsx
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from 'aos';
import TextToSpeech from '../../TextToSpeech/textToSpeech';
import PropTypes from 'prop-types'; // Import PropTypes

// Images
import PreSonus from "../../assets/Presonus PA Mackie DLM12 2000W.webp";

const text =
  "Tarjoamme tapahtumaasi monipuolisia ja joustavia vuokrauspalveluja, jotka takaavat tilaisuutesi onnistumisen. Äänentoiston, esitystekniikan ja muiden vuokrattavien laitteidemme avulla hoidat tapahtumasi tärkeimmät osa-alueet helposti ja vaivattomasti, olipa kyseessä pienet juhlat tai suuret bileet – kaikki saman katon alta.";

const Hero = ({ theme = 'default-theme' }) => { // Aseta oletusarvo suoraan parametrissa
  useEffect(() => {
    AOS.init(); // Käynnistä AOS-kirjasto
    AOS.refresh();
  }, []); // Tyhjä riippuvuuslista varmistaa, että AOS alustetaan vain kerran komponentin mountissa

  return (
    <div className={`pb-20 ${theme}`}>
      <div className="container min-h-[620px] flex">
        <div className="grid grid-cols-1 xsm:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 mdd:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 place-items-center">
          <div
            data-aos="fade-left"
            className="xxxsm:order-1 xxsm:order-1 xsm:order-1 sm:order-1 md:order-2 mdd:order-2 lg:order-2 xl:order-2 2xl:order-2"
          >
            {/* 2000W äänentoistopaketti kuva */}
            <img
              src={PreSonus}
              title="PreSonus 2000W äänentoistopaketti"
              alt="PreSonus 2000W äänentoistopaketti"
              className="xxxsm:scale-110 xxsm:scale-110 xsm:scale-110 sm:scale-125 md:scale-125 mdd:scale-125 pt-10 lg:scale-150 xl:scale-150 2xl:scale-150 pb-10 rotate-6 relative z-20 max-h-[500px] drop-shadow-[10px_10px_8px_rgba(0,0,0,0.80)]"
            />
          </div>

          {/* Tekstilaatikko */}
          <div
            data-aos="fade-right"
            className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 p-5 order-1 rounded-3xl z-15"
            style={{ maxWidth: '100%' }}
          >
            {/* Iskulause */}
            <div data-aos="fade-right" className="flex flex-row items-center">
              <p className="text-darkest dark:text-primary text-left text-2xl font-customFont1 font-bold text-shadow-lg">
                VAIVATTOMIA
              </p>
              <div className="speech-title ml-5 flex justify-center">
                <TextToSpeech text={text} />
              </div>
            </div>

            {/* Otsikko */}
            <h1 className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight">
              TÄYDEN PALVELUN TAPAHTUMARATKAISUJA
            </h1>

            {/* Teksti */}
            <p data-aos="fade-right" className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
              Tarjoamme tapahtumaasi monipuolisia ja joustavia vuokrauspalveluja, jotka takaavat tilaisuutesi onnistumisen.<br /><br />
              Äänentoiston, esitystekniikan ja muiden vuokrattavien laitteidemme avulla hoidat tapahtumasi tärkeimmät osa-alueet helposti ja vaivattomasti, olipa kyseessä pienet juhlat tai suuret bileet – kaikki saman katon alta.
            </p>

            <div data-aos="fade-up" className="hero-button pt-4">
              {/* Linkki vuokrattaviin button */}
              <Link
                to="/palvelut"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                }}
              >
                <button
                  className="h-12 w-48 
                    dark:bg-primary 
                    dark:hover:border-2
                    dark:hover:opacity-90
                    dark:border-white
                    dark:text-darkest 
                    bg-darkest 
                    hover:border-2
                    hover:border-opacity-90
                    hover:opacity-90
                    border-linkcolhover
                    text-primary 
                    font-customFont1 
                    font-bold 
                    text-2xl 
                    overflow-hidden 
                    transform hover:scale-x-110 
                    hover:scale-y-105 transition 
                    duration-300 ease-in
                    items-center 
                    rounded-full 
                    cursor-pointer"
                  style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                  aria-label="Aloita palveluiden selaaminen" // Added aria-label for accessibility
                >
                  Aloitetaan!
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Define prop types for the component
Hero.propTypes = {
  theme: PropTypes.string, // Validate the 'theme' prop
};

// Poista defaultProps, koska oletusarvot määritetään nyt suoraan parametrissa
// Hero.defaultProps = {
//   theme: 'default-theme', // Default theme if none is provided
// };

export default Hero;
