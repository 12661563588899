import { Helmet } from 'react-helmet-async';

function ADJmyDMXGOHelmetComponent() {
  return (
    <div>
      <Helmet>
        {/* Sivun otsikko */}
        <title>Spiderlegs productions | ADJ myDMX GO langaton DMX-ohjain</title>
        
        {/* Meta-kuvaus */}
        <meta name="description" content="ADJ myDMX GO on vallankumouksellinen uusi valaistuksen ohjausjärjestelmä, joka on erittäin tehokas ja uskomattoman helppokäyttöinen. Tutustu lisää sivuillamme!" />
        
        {/* Avainsanat */}
        <meta name="keywords" content="DMX ohjain, ADJ, myDMX go, sovellus, android, ipad" />
        
        {/* Sivun kirjoittaja */}
        <meta name="author" content="Arttu Stenius" />
        
        {/* Kanoninen linkki */}
        <link rel="canonical" href="https://spiderlegsproductions.com/ADJmyDMXGO" />
        
        {/* Open Graph -tunnisteet */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Spiderlegs productions | ADJ myDMX GO langaton DMX-ohjain" />
        <meta property="og:description" content="ADJ myDMX GO on vallankumouksellinen uusi valaistuksen ohjausjärjestelmä, joka on erittäin tehokas ja uskomattoman helppokäyttöinen. Tutustu lisää sivuillamme!" />
        <meta property="og:image" content="https://spiderlegsproductions.com/assets/ADJMyDMXGO1.webp" />
        <meta property="og:url" content="https://spiderlegsproductions.com/ADJmyDMXGO" />
        
        {/* Twitter-kortin tunnisteet */}
        <meta name="twitter:title" content="Spiderlegs productions | ADJ myDMX GO langaton DMX-ohjain" />
        <meta name="twitter:description" content="ADJ myDMX GO on vallankumouksellinen uusi valaistuksen ohjausjärjestelmä, joka on erittäin tehokas ja uskomattoman helppokäyttöinen. Tutustu lisää sivuillamme!" />
        <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/ADJMyDMXGO1.webp" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    </div>
  );
}

export default ADJmyDMXGOHelmetComponent;
