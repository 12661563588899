// src/components/Navbar/Navbar.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Navbar.css';
import Dropdown from '../../components/Navbar/Dropdown'; 
import { GiLongLeggedSpider } from "react-icons/gi";
import { HiMenu, HiX } from 'react-icons/hi';
import lightmode from '../../assets/light-mode.webp';
import darkmode from '../../assets/dark-mode.webp';
import { FaShoppingCart } from 'react-icons/fa';

const Navbar = ({ theme, setTheme }) => {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 881);
  const [cartCount, setCartCount] = useState(0);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (!isMobile) {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (!isMobile) {
      setDropdown(false);
    }
  };

  // Funktio korin määrän päivittämiseen
  const updateCartCount = () => {
    try {
      const cartItems = JSON.parse(localStorage.getItem("rentalCart")) || [];
      const count = cartItems.reduce((total, item) => total + (item.quantity || 0), 0);
      setCartCount(count);
    } catch (error) {
      console.error("Virhe korin määrän lukemisessa:", error);
      setCartCount(0);
    }
  };

  useEffect(() => {
    // Päivitä korin määrä komponentin mounttaamisen yhteydessä
    updateCartCount();

    // Kuuntele 'storage' eventteja (muutokset toisissa ikkunoissa)
    window.addEventListener("storage", updateCartCount);

    // Kuuntele 'focus' eventteja (ikkunan fokuksen saaminen)
    window.addEventListener("focus", updateCartCount);

    // Kuuntele omaa 'cartUpdated' eventtia
    window.addEventListener("cartUpdated", updateCartCount);

    // Kuuntele ikkunan resize tapahtumia ja päivitä isMobile
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 881);
      if (window.innerWidth > 881) {
        setClick(false); // Sulje mobiilimenu, kun ikkuna laajenee
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener("storage", updateCartCount);
      window.removeEventListener("focus", updateCartCount);
      window.removeEventListener("cartUpdated", updateCartCount);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className="navbar dark:bg-violet bg-primary">
      <div className="dark:text-primary navbar-logo font-bold font-customFont2 text-darkest flex items-center justify-center md:justify-start text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 3xl:text-4xl">
        <Link to="/" className="flex items-center dark:text-primary text-darkest">
          <GiLongLeggedSpider size={40} />
          &nbsp;&nbsp;<span>Spiderlegs productions</span>
        </Link>
        <button
          onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
          aria-label="Vaihda teemaa"
          className="toggle-button focus:outline-none"
        >
          <img
            src={theme === 'light' ? darkmode : lightmode}
            alt={theme === 'light' ? 'Dark mode' : 'Light mode'}
            title="Vaihda teemaa"
            className={`toggle-icon ${theme === 'light' ? 'rotating' : ''}`}
          />
        </button>
      </div>

      <button 
        aria-label="Avaa tai sulje menu"
        title="Menu" 
        className={`menu-icon dark:text-primary text-darkest ${click ? 'active' : ''}`} 
        onClick={handleClick}
      >
        {click ? <HiX size={40} /> : <HiMenu size={40} />}
      </button>

      <ul className={click ? 'nav-menu active' : 'nav-menu'}>
        <li title="Koti" className="nav-item">
          <Link to="/" className="nav-links" onClick={closeMobileMenu}>Koti</Link>
        </li>
        <li 
          title="Palvelut" 
          className="nav-item" 
          onMouseEnter={onMouseEnter} 
          onMouseLeave={onMouseLeave}
        >
          <Link to="/palvelut" className="nav-links" onClick={closeMobileMenu}>Palvelut</Link>
          {dropdown && <Dropdown toggle={dropdown} />}
        </li>
        <li title="Meistä" className="nav-item">
          <Link to="/meista" className="nav-links" onClick={closeMobileMenu}>Meistä</Link>
        </li>
        <li title="UKK" className="nav-item">
          <Link to="/UKK" className="nav-links" onClick={closeMobileMenu}>UKK</Link>
        </li>
        <li title="Varauskalenteri" className="nav-item">
          <Link to="/varauskalenteri" className="nav-links" onClick={closeMobileMenu}>Varauskalenteri</Link>
        </li>

        {/* Vuokrauskori */}
        <li title="Vuokrauskori" className="nav-item">
          <Link to="/varauskalenteri" className="nav-links-cart" onClick={closeMobileMenu}>
            <FaShoppingCart size={30} />
            {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

Navbar.propTypes = {
  theme: PropTypes.string.isRequired,
  setTheme: PropTypes.func.isRequired,
};

export default Navbar;
