import TextToSpeech from '../../TextToSpeech/textToSpeech';

// Components
import Footer from "../../components/Footer/Footer";
import Experience from "../../components/Experience/Experience";
import AboutUsHelmetComponent from './AboutUsHelmetComponent';
import Team from "../../components/Team/Team"; 
import Stage from '../../assets/lava.webp';

const text = "Spiderlegs Productions on Keravalla sijaitseva monipuolinen tapahtumapalveluiden tarjoaja. Toiminta-alueemme kattaa pääasiassa Etelä-Suomen, ja tarjoamme palveluja erityisesti häihin, puhetilaisuuksiin, teemabileisiin sekä yritysjuhliin. Tavoitteenamme on helpottaa juhlien järjestämistä tarjoamalla äänentoisto- ja tunnelmavalaistusratkaisuja saman katon alta. Lisäksi tarjoamme vuokralle muita laitteita, jotka tukevat tapahtumasi tarpeita. Pyrimme tarjoamaan kilpailukykyisiä hintoja sekä räätälöityjä ja kattavia palveluita, jotka vastaavat juuri sinun tarpeitasi tapahtuman järjestämisessä. Pitkän kokemuksemme ansiosta tunnemme erilaiset tapahtumatilat ja osaamme luoda niihin sopivan äänimaailman ja tunnelmallisen valaistuksen. Kartoitamme tarpeesi tarkasti ja tarjoamme sinulle juuri oikean ratkaisun. Voit valita ja vuokrata mieleisesi laittteesi, noutaa ne itse ja palauttaa sovitun aikamääreen sisällä tai antaa meidän järjestää tapahtumaanne sopivat ratkaisut alusta loppuun asti. Palveluihimme kuuluu myös edullinen kuljetus- ja asennuspalvelu sekä maksuton opastus laitteiden käyttöön. Haluamme, että voit keskittyä nauttimaan juhlista ilman turhaa stressiä järjestelyistä. Tarjoamme monipuoliset ratkaisut ja tuemme sinua koko tapahtuman ajan."

const AboutUs = () => {
  return (
    <div className="w-full px-0">
      <AboutUsHelmetComponent />

      {/* Layout ja jako kahteen laatikkoon */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 pt-10">

        {/* Tekstilaatikko */}
        <div className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl p-8 shadow-lg dark:bg-violet bg-primary dark:bg-opacity-90 bg-opacity-90 flex flex-col justify-center">
          <h1 className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight">
            TIETOA MEISTÄ
          </h1>
          <div className="flex items-center mt-6">
            <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
              <strong>Spiderlegs Productions</strong> on Keravalla sijaitseva monipuolinen tapahtumapalveluiden tarjoaja.<br /><br />
              Toiminta-alueemme kattaa pääasiassa Etelä-Suomen, ja tarjoamme palveluja erityisesti häihin, puhetilaisuuksiin, teemabileisiin sekä yritysjuhliin.<br /><br />

              Tavoitteenamme on helpottaa juhlien järjestämistä tarjoamalla äänentoisto- ja tunnelmavalaistusratkaisuja saman katon alta. Lisäksi tarjoamme vuokralle muita laitteita, jotka tukevat tapahtumasi tarpeita.<br /><br />

              Pyrimme tarjoamaan kilpailukykyisiä hintoja sekä räätälöityjä ja kattavia palveluita, jotka vastaavat juuri sinun tarpeitasi tapahtuman järjestämisessä.<br /><br />

              Pitkän kokemuksemme ansiosta tunnemme erilaiset tapahtumatilat ja osaamme luoda niihin sopivan äänimaailman ja tunnelmallisen valaistuksen. Kartoitamme tarpeesi tarkasti ja tarjoamme sinulle juuri oikean ratkaisun.<br /><br />

              Voit valita ja vuokrata mieleisesi laittteesi, noutaa ne itse ja palauttaa sovitun aikamääreen sisällä tai antaa meidän järjestää tapahtumaanne sopivat ratkaisut alusta loppuun asti.<br /><br />

              Palveluihimme kuuluu myös edullinen kuljetus- ja asennuspalvelu sekä maksuton opastus laitteiden käyttöön.<br /><br />

              Haluamme, että voit keskittyä nauttimaan juhlista ilman turhaa stressiä järjestelyistä. Tarjoamme monipuoliset ratkaisut ja tuemme sinua koko tapahtuman ajan.
            </p>
            <div className='speech-title ml-5 flex justify-center'>
              <TextToSpeech text={text} />
            </div>
          </div>
        </div>

        {/* Kuvaboxi */}
        <div className="lg:flex justify-center items-center shadow-lg dark:bg-violet bg-primary dark:bg-opacity-90 bg-opacity-90 p-8">
          <img src={Stage} alt="Stage setup" className="w-full h-full object-cover rounded-xl drop-shadow-lg" />
        </div>

      </div>

      <Team />
      <Experience />
      <Footer />
    </div>
  );
};

export default AboutUs;
