import { useState, useEffect } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { BsPlayFill, BsStopFill } from "react-icons/bs";
import { HiSpeakerWave } from "react-icons/hi2";

const TextToSpeech = ({ text }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [utterance, setUtterance] = useState(null);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);

    // Set the language to Finnish
    u.lang = 'fi-FI';

    // Listen for when the speech has ended
    u.addEventListener('end', () => {
      setIsPlaying(false); // Set isPlaying to false when reading is finished
    });

    setUtterance(u);

    return () => {
      synth.cancel();
    };
  }, [text]);

  const handleToggle = () => {
    const synth = window.speechSynthesis;

    if (isPlaying) {
      synth.cancel();
      setIsPlaying(false);
    } else {
      synth.speak(utterance);
      setIsPlaying(true);
    }
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;

    synth.cancel();
    setIsPlaying(false);
  };

  return (
    <div className="flex items-center space-x-2 py-2">
      <button
        onClick={handleToggle}
        className="text-darkest dark:text-primary rounded-md p-2 border-2 dark:bg-violet bg-lightbg dark:hover:border-2 dark:border-primary hover:bg-white hover:bg-opacity-60 dark:hover:bg-dark dark:hover:bg-opacity-75 border-darkest"
      >
        {isPlaying ? (
          <BsStopFill className="text-sm" />
        ) : (
          <BsPlayFill alt="play stop button" className="text-sm" title="Kuuntele teksti" />
        )}
      </button>
      <button
        onClick={handleStop}
        className="text-darkest dark:text-lightbg font-customFont1 rounded-md"
      >
        <HiSpeakerWave
          className="text-2xl cursor-text mx-2 text-white text-opacity-75 drop-shadow-[0px_0px_5px_rgba(0,0,0,0.80)]"
          alt="Kaiutin"
        />
      </button>
    </div>
  );
};

// Define prop types
TextToSpeech.propTypes = {
  text: PropTypes.string.isRequired, // Validate that 'text' is a required string
};

export default TextToSpeech;
