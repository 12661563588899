import { Link } from "react-router-dom"
import TextToSpeech from '../../TextToSpeech/textToSpeech';
import PrivacyPolicyHelmetComponent from './PrivacyPolicyHelmetComponent'; // Lisätty PrivacyPolicyHelmetComponent-importti

const text =
"Spiderlegs Productions, tietosuojalausunto";

const text1 =
"Yksi, Johdanto. Spiderlegs Productions Oy, Y-tunnus: kolmekaksiyksineljäkolmeseitsemänkahdeksan viiva kuusi, kunnioittaa asiakkaidensa yksityisyyttä ja on sitoutunut noudattamaan henkilötietolakia kaksinollayksikuusi, kautta, kuusiseitsemänyhdeksän ja muita tietosuojaan liittyviä säädöksiä. Tämä tietosuojalausunto kuvaa, miten keräämme, käytämme ja käsittelemme henkilötietojasi verkkosivustollamme ja sen kautta.";

const text2 =
"Kaksi. Henkilötietojen kerääminen. Emme kerää verkkosivustollamme olevan yhteydenottolomakkeen kautta saapuneita tietoja mihinkään erilliseen rekisteriin tai tietokantaan.";

const text3 =
"Kolme. Henkilötietojen käyttö. Käytämme lomakkeen kautta tulleita tietoja ainoastaan. Vastataaksemme kysymyksiisi ja yhteydenottopyyntöihisi. Ylläpitääksemme asiakassuhdetta kanssasi";

const text4 =
"Neljä. Henkilötietojen jakaminen. Emme jaa henkilötietojasi kolmansien osapuolten kanssa.";

const text5 =
"Viisi. Henkilötietojen säilytys. Säilytämme sähköpostiosoitettasi vain niin kauan kuin on tarpeen asiakassuhteen ylläpitämiseksi ja edellä mainittujen käyttötarkoitusten täyttämiseksi.";

const text6 =
"Kuusi. Tietoturva. Käytämme asianmukaisia teknisiä ja organisatorisia toimenpiteitä suojataksemme henkilötietojasi luvattomalta käsittelyltä, käytöltä, paljastamiselta, muuttamiselta tai tuhoamiselta.";

const text7 =
"Seitsemän. Sinulla on oikeus, Saada tietoa siitä, miten käsittelemme henkilötietojasi. Pyytää henkilötietojesi oikaisemista tai poistamista. Rajoittaa henkilötietojesi käsittelyä. Vastustaa henkilötietojesi käsittelyä";

const text8 =
"Kahdeksan. Yhteystiedot. Jos sinulla on kysyttävää henkilötietojesi käsittelystä tai haluat käyttää edellä mainittuja oikeuksiasi, ota meihin yhteyttä. Spiderlegs Productions. Arttu Stenius. Näsiätie 2. 04260 Kerava. +358 40 623 41 89. Sähköposti,   info@spiderlegsproductions.com";

const text9 =
"Yhdeksän. Muutokset. Tätä tietosuojalausuntoa voidaan päivittää ajoittain. Ilmoitamme sinulle kaikista merkittävistä muutoksista verkkosivustollamme.";

const text10 =
"Kymmenen. Tietosuoja. Lisätietoja tietosuojalainsäädännöstä saat tietosuojavaltuutetun verkkosivuilta. https://www.tietosuoja.fi/";

// Component import

import Footer from '../../components/Footer/Footer';

const PrivacyPolicy = () => {
    return (

        <div>

            <PrivacyPolicyHelmetComponent /> {/* Lisätty PrivacyPolicyHelmetComponent */}

            <div className="duration-300 pt-5 dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 p-5">

                <Link to="/" onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'auto' }); } }>

                    <button className='h-12 w-32 dark:bg-violet bg-lightbg dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out'>Etusivulle</button>

                </Link>

                {/* BUTTON */}

                {/* SPEECH */}

                <div className="flex flex-row items-center">
                    <h1 className="site-title font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-3xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold ">Spiderlegs Productions tietosuojalausunto</h1>
                    <div className='speech-title ml-5 flex justify-center'>

                        <TextToSpeech text={text} />

                    </div> 

                </div>

                {/* SPEECH */}
                        
                <div className="flex flex-row items-center">
                    <h2 className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
                        1. Johdanto
                    </h2>
                    <div className='speech-title ml-5 flex justify-center'>
                        <TextToSpeech text={text1} />
                    </div>
                </div>


                <br />

                <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">

                    Spiderlegs Productions Oy (Y-tunnus: 3214378-6) kunnioittaa asiakkaidensa yksityisyyttä ja on sitoutunut noudattamaan henkilötietolakia (2016/679) ja muita tietosuojaan liittyviä säädöksiä. Tämä tietosuojalausunto kuvaa, miten keräämme, käytämme ja käsittelemme henkilötietojasi verkkosivustollamme ja sen kautta.

                </p>
                
                <br />

                {/* SPEECH */}
                        
                <div className="flex flex-row items-center">

                    <h2 className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">2. Henkilötietojen kerääminen</h2>
                    <div className='speech-title ml-5'>

                        <TextToSpeech text={text2} />
                                
                    </div>

                </div>

                <br />

                <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">

                    Emme kerää verkkosivustollamme olevan yhteydenottolomakkeen kautta saapuneita tietoja mihinkään erilliseen rekisteriin tai tietokantaan.

                </p>
                
                <br />

                {/* SPEECH */}
                        
                <div className="flex flex-row items-center">

                    <h2 className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">3. Henkilötietojen käyttö</h2>
                    <div className='speech-title ml-5'>

                        <TextToSpeech text={text3} />
                                
                    </div>

                </div> 
                    
                <br />

                <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">

                    Käytämme lomakkeen kautta tulleita tietoja ainoastaan:<br /><br />

                    &#8226; Vastataaksemme kysymyksiisi ja yhteydenottopyyntöihisi <br />
                    &#8226; Ylläpitääksemme asiakassuhdetta kanssasi <br />

                </p>
                
                <br />

                {/* SPEECH */}
                        
                <div className="flex flex-row items-center">

                    <h2 className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">4. Henkilötietojen jakaminen</h2>
                    <div className='speech-title ml-5'>

                        <TextToSpeech text={text4} />
                                
                    </div>

                </div>
                
                <br />

                <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">

                    Emme jaa henkilötietojasi kolmansien osapuolten kanssa.

                </p>
                
                <br />

                {/* SPEECH */}
                        
                <div className="flex flex-row items-center">

                    <h2 className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">5. Henkilötietojen säilytys</h2>

                    <div className='speech-title ml-5'>

                        <TextToSpeech text={text5} />

                    </div>

                </div>
                
                <br />

                <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">

                    Säilytämme sähköpostiosoitettasi vain niin kauan kuin on tarpeen asiakassuhteen ylläpitämiseksi ja edellä mainittujen käyttötarkoitusten täyttämiseksi.

                </p>
                
                <br />

                {/* SPEECH */}
                        
                <div className="flex flex-row items-center">

                    <h2 className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">6. Tietoturva</h2>
                    <div className='speech-title ml-5'>

                        <TextToSpeech text={text6} />
                                
                    </div>

                </div>
                    
                <br />

                <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">

                    Käytämme asianmukaisia teknisiä ja organisatorisia toimenpiteitä suojataksemme henkilötietojasi luvattomalta käsittelyltä, käytöltä, paljastamiselta, muuttamiselta tai tuhoamiselta.

                </p>
                
                <br />

                {/* SPEECH */}
                        
                <div className="flex flex-row items-center">

                    <h2 className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">7. Oikeutesi</h2>
                    
                    <div className='speech-title ml-5'>

                        <TextToSpeech text={text7} />
                                
                    </div>

                </div>

                <br />

                <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">

                    Sinulla on oikeus:<br /><br />

                    &#8226; Saada tietoa siitä, miten käsittelemme henkilötietojasi <br />
                    &#8226; Pyytää henkilötietojesi oikaisemista tai poistamista <br />
                    &#8226; Rajoittaa henkilötietojesi käsittelyä <br />
                    &#8226; Vastustaa henkilötietojesi käsittelyä <br />

                </p>
                
                <br />

                {/* SPEECH */}
                        
                <div className="flex flex-row items-center">

                    <h2 className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">8. Yhteystiedot</h2>
                    
                    <div className='speech-title ml-5'>

                        <TextToSpeech text={text8} />
                                
                    </div>

                </div>
                    
                <br />

                <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">

                    Jos sinulla on kysyttävää henkilötietojesi käsittelystä tai haluat käyttää edellä mainittuja oikeuksiasi, ota meihin yhteyttä:

                    <br /><br />

                    Spiderlegs Productions<br />
                    Arttu Stenius<br />
                    Näsiätie 2<br />
                    04260 Kerava<br />
                    +358 44 351 92 65<br />
                    Sähköposti: <a href="mailto:info@spiderlegsproductions.com?body=My custom mail body" title='Lähetä sähköpostia'>&nbsp; info@spiderlegsproductions.com</a>

                </p>
                
                <br />

                {/* SPEECH */}
                        
                <div className="flex flex-row items-center">

                    <h2 className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">9. Muutokset</h2>
                    <div className='speech-title ml-5'>

                        <TextToSpeech text={text9} />
                                
                    </div>

                </div>
                    
                <br />

                <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">

                    Tätä tietosuojalausuntoa voidaan päivittää ajoittain. Ilmoitamme sinulle kaikista merkittävistä muutoksista verkkosivustollamme.

                </p>
                
                <br />
                
                {/* SPEECH */}
                        
                <div className="flex flex-row items-center">

                    <h2 className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">10. Tietosuoja</h2>
                    <div className='speech-title ml-5'>

                        <TextToSpeech text={text10} />
                                
                    </div>

                </div>
                
                <br />

                <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">

                    Lisätietoja tietosuojalainsäädännöstä saat tietosuojavaltuutetun verkkosivuilta: <br /><br />

                    <a href="https://www.tietosuoja.fi/" title='Klikkaa sivulle' className='text-violet'>https://www.tietosuoja.fi/</a>

                </p><br />

            </div>
            <Footer />
        </div>
    )    
};

export default PrivacyPolicy;