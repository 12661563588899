import { Helmet } from 'react-helmet-async';

// Tämä komponentti määrittelee tietosuojalausunnon sivun otsikon ja meta-tiedot.
function PrivacyPolicyHelmetComponent() {
  return (
      <Helmet>
        {/* Sivun otsikko */}
        <title>Spiderlegs productions | Tietosuojalausunto</title>
        
        {/* Meta-kuvaus */}
        <meta name="description" content="Tutustu Spiderlegs productions -yrityksen tietosuojakäytäntöihin ja siihen, miten käsittelemme henkilötietoja turvallisesti ja luottamuksellisesti." />
        
        {/* Avainsanat */}
        <meta name="keywords" content="tietosuojalausunto, tietosuoja, henkilötiedot, yksityisyys" />
        
        {/* Sivuston kirjoittaja */}
        <meta name="author" content="Arttu Stenius" />
        
        {/* Estä hakukoneiden indeksointi */}
        <meta name="robots" content="noindex" />
        
        {/* Kanoninen linkki */}
        <link rel='canonical' href='https://spiderlegsproductions.com/tietosuojalausunto' />
        
        {/* Open Graph -tunnisteet */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Spiderlegs productions | Tietosuojalausunto" />
        <meta property="og:description" content="Tutustu Spiderlegs productions -yrityksen tietosuojakäytäntöihin ja siihen, miten käsittelemme henkilötietoja turvallisesti ja luottamuksellisesti." />
        <meta property="og:image" content="https://spiderlegsproductions.com/assets/new_logo.webp" />
        <meta property="og:url" content="https://spiderlegsproductions.com/tietosuojalausunto" />
        
        {/* Twitter-kortin tunnisteet */}
        <meta name="twitter:title" content="Spiderlegs productions | Tietosuojalausunto" />
        <meta name="twitter:description" content="Tutustu Spiderlegs productions -yrityksen tietosuojakäytäntöihin ja siihen, miten käsittelemme henkilötietoja turvallisesti ja luottamuksellisesti." />
        <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/new_logo.webp" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
  );
}

export default PrivacyPolicyHelmetComponent;
