// src/components/OtherRentals/OtherRentals.jsx
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TextToSpeech from "../../TextToSpeech/textToSpeech";
import { toast } from "react-toastify"; // Importoi toast

const text =
  "Tähän osioon on koottu muita tuotteita tai palveluita, joita myymme tai tarjoamme vuokralle. Esittelyssä ensimmäisenä hauska Fujifilm Instax Mini-12 -kompaktikamera.";

const OtherRentals = () => {
  const [otherRentalData, setOtherRentalData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOtherRentals = async () => {
      try {
        const response = await fetch(
          "https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=muut"
        );

        if (!response.ok) {
          throw new Error(`Palvelin palautti virheen: ${response.status}`);
        }
        const data = await response.json();
        console.log("Other Rental Data:", data);

        if (data.success && data.categories && Array.isArray(data.categories.muut)) {
          setOtherRentalData(data.categories.muut);
        } else {
          throw new Error("API:n vastausrakenne ei ole odotettu.");
        }

        // Näytä onnistunut ilmoitus
      
      } catch (err) {
        console.error("Virhe tietojen haussa:", err);
        setError("Tietojen lataus epäonnistui. Yritä myöhemmin uudelleen.");
        toast.error("Tietojen lataus epäonnistui. Yritä myöhemmin uudelleen."); // Lisää toast-error
      }
    };

    fetchOtherRentals();
  }, []);

  const addToCart = (product) => {
    try {
      const cart = JSON.parse(localStorage.getItem("rentalCart")) || [];
      const existingProduct = cart.find((item) => item.id === product.id);

      if (existingProduct) {
        if (existingProduct.quantity >= product.available) {
          // Korvaa alert toastilla
          toast.warn(`Tuotetta ${product.name} ei voi lisätä enempää koriin, varastossa ${product.available} kpl.`);
          return;
        }
        existingProduct.quantity += 1;
      } else {
        if (product.available <= 0) {
          // Korvaa alert toastilla
          toast.error(`Tuote ${product.name} on loppunut varastosta.`);
          return;
        }
        cart.push({ ...product, quantity: 1 });
      }

      localStorage.setItem("rentalCart", JSON.stringify(cart));
      window.dispatchEvent(new Event("storage"));

      // Korvaa alert toastilla
      toast.success(`${product.name} lisätty vuokrauskoriin!`);
    } catch (error) {
      console.error("Virhe tuotteen lisäämisessä vuokrauskoriin:", error);
      // Lisää virhetoast
      toast.error("Virhe tuotteen lisäämisessä vuokrauskoriin. Yritä myöhemmin uudelleen.");
    }
  };

  return (
    <div
      id="muut-vuokrattavat-tuotteet"
      className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 pb-10 pt-7 pl-5 pr-5"
    >
      <div className="container">
        <div className="flex flex-row">
          <h2 className="text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight text-left font-customFont2 text-darkest dark:text-primary text-shadow-lg">
            MUUT VUOKRATTAVAT TUOTTEET
          </h2>

          <div className="speech-title ml-5 flex justify-center">
            <TextToSpeech text={text} />
          </div>
        </div>

        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl">
          Tähän osioon on koottu muita tuotteita tai palveluita, joita myymme tai
          tarjoamme vuokralle. Esittelyssä ensimmäisenä hauska Fujifilm Instax Mini-12
          -kompaktikamera.
        </p>

        {error ? (
          <div className="text-red-500 font-bold text-center mt-5">{error}</div>
        ) : (
          <div className="grid grid-cols-1 xxsm:grid-cols-2 xsm:grid-cols-2 sm:grid-cols-2 md:grid-cols-2 mdd:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-4">
            {otherRentalData.map((item) => (
              <div
                key={item.id}
                className="bg-white bg-opacity-50 hover:bg-lightbg hover:bg-opacity-60 rounded-3xl relative group p-5 flex flex-col justify-between"
              >
                <TextToSpeech text={item.description || "Ei kuvausta saatavilla."} />
                <div className="w-full h-[120px]">
                  <Link
                    to={item.link || "#"}
                    title={item.name}
                    onClick={() => {
                      if (item.link) {
                        window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                      }
                    }}
                    aria-label={`Näytä ${item.name} tuotekuvaus`}
                  >
                    <img
                      src={item.image || "assets/images/placeholder.jpg"}
                      alt={item.name || "Ei kuvaa saatavilla"}
                      title={item.name || "Ei kuvaa saatavilla"}
                      loading="lazy"
                      className="w-full h-[150px] object-contain sm:translate-x-0 transition duration-700 cursor-pointer drop-shadow-[3px_3px_5px_rgba(0,0,0,0.80)]"
                    />
                  </Link>
                </div>

                <div className="flex flex-col justify-between flex-grow">
                  <div className="grid grid-cols-1 place-items-center pt-10">
                    <h3
                      className="font-customFont1 text-shadow-lg text-center text-darkest dark:text-primary xxxsm:text-sm xxsm:text-base xsm:text-lg sm:text-xl md:text-2xl mdd:text-1xl lg:text-2xl xl:text-3xl font-normal pb-5"
                      title={item.name}
                    >
                      {item.name || "Tuntematon tuote"}
                    </h3>
                  </div>

                  <div className="mt-auto text-center">
                    <div className="font-customFont1 text-darkest dark:text-primary text-shadow-lg font-normal pb-2">
                      <p className="specs font-customFont1 text-darkest dark:text-primary text-shadow-lg xxxsm:text-base xxsm:text-lg xsm:text-xl sm:text-2xl md:text-3xl mdd:text-2xl lg:text-4xl xl:text-4xl font-normal">
                        {item.price ? `${item.price} € / 24h` : "Hinta ei saatavilla"}
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <Link
                        to={item.link || "#"}
                        title="Katso lisätiedot"
                        onClick={() => {
                          if (item.link) {
                            window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                          }
                        }}
                        aria-label={`Katso lisätiedot ${item.name}`}
                      >
                        <button
                          className="h-12 w-28 dark:bg-violet bg-lightbg dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out opacity-75"
                          title="Katso lisätiedot"
                        >
                          Lisätiedot
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="grid place-content-center mt-4">
                  <button
                    title="Lisää vuokrauskoriin"
                    onClick={() =>
                      addToCart({
                        id: item.id,
                        name: item.name,
                        price: item.price,
                        available: item.available,
                      })
                    }
                    className="h-12 w-32 sm:w-32 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none"
                    style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                  >
                    Lisää koriin
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default OtherRentals;
