import { Helmet } from 'react-helmet-async';

// Komponentti, joka määrittelee sivun otsikon ja metatiedot
function RentalHelmetComponent() {
  return (
    <Helmet>
      {/* Sivun otsikko ja kuvaus */}
      <title>Spiderlegs productions | Palvelut</title>
      <meta name="description" content="Tsekkaa vuokrattavat äänentoisto- ja esitystekniikkalaitteemme tältä sivulta kotibileistä isoihin tapahtumiin!" />

      {/* Avainsanat */}
      <meta name="keywords" content="palvelut, äänentoiston vuokraus, esitystekniikka vuokraus, kotibileet, yritystapahtumat, laitteet, mikrofonit, valot, helppo" />

      {/* Sivun kirjoittaja */}
      <meta name="author" content="Arttu Stenius" />

      {/* Canonical-linkki */}
      <link rel="canonical" href="https://spiderlegsproductions.com/palvelut" />

      {/* Open Graph -metatiedot */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Spiderlegs productions | Palvelut" />
      <meta property="og:description" content="Tsekkaa vuokrattavat äänentoisto- ja esitystekniikkalaitteemme tältä sivulta kotibileistä isoihin tapahtumiin!" />
      <meta property="og:image" content="https://spiderlegsproductions.com/assets/new_logo.webp" />
      <meta property="og:url" content="https://spiderlegsproductions.com/palvelut" />

      {/* Twitter-metatiedot */}
      <meta name="twitter:title" content="Spiderlegs productions | Palvelut" />
      <meta name="twitter:description" content="Tsekkaa vuokrattavat äänentoisto- ja esitystekniikkalaitteemme tältä sivulta kotibileistä isoihin tapahtumiin!" />
      <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/new_logo.webp" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default RentalHelmetComponent;
