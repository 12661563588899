import { Helmet } from 'react-helmet-async';

// Komponentti, joka määrittelee Helmet-elementit PreSonus2000W-sivulle
function PreSonus2000WHelmetComponent() {
  return (
    <Helmet>
      {/* Asetetaan sivun otsikko */}
      <title>Spiderlegs productions | 2000W äänentoistopaketti</title>
      
      {/* Määritellään meta-kuvaus */}
      <meta name="description" content="Äänentoistopaketti, joka tarjoilee tuhdin 2000W:n tehon. Sopii mainiosti keskisuuriin ja suuriin tapahtumiin! Lue lisätiedot sivuiltamme!" />
      
      {/* Määritellään avainsanat */}
      <meta name="keywords" content="äänentoistopaketti, mackie, dlm12, studiolive, presonus, 16.0.2, 2000W, mikseri, pa, vuokraa, äänentoisto" />
      
      {/* Määritellään sivuston kirjoittaja */}
      <meta name="author" content="Arttu Stenius" />
      
      {/* Asetetaan kanoninen linkki */}
      <link rel='canonical' href='https://spiderlegsproductions.com/2000w-aanentoistopaketti' />
      
      {/* Määritellään Open Graph -tunnisteet */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Spiderlegs productions | 2000W äänentoistopaketti" />
      <meta property="og:description" content="Äänentoistopaketti, joka tarjoilee tuhdin 2000W:n tehon. Sopii mainiosti keskisuuriin ja suuriin tapahtumiin! Lue lisätiedot sivuiltamme!" />
      <meta property="og:image" content="https://spiderlegsproductions.com/assets/Presonus_PA_Mackie_DLM12_2000W.webp" />
      <meta property="og:url" content="https://spiderlegsproductions.com/2000w-aanentoistopaketti" />
      
      {/* Määritellään Twitter-kortin tunnisteet */}
      <meta name="twitter:title" content="Spiderlegs productions | 2000W äänentoistopaketti" />
      <meta name="twitter:description" content="Äänentoistopaketti, joka tarjoilee tuhdin 2000W:n tehon. Sopii mainiosti keskisuuriin ja suuriin tapahtumiin! Lue lisätiedot sivuiltamme!" />
      <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/Presonus_PA_Mackie_DLM12_2000W.webp" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default PreSonus2000WHelmetComponent;
