// src/components/LEDLights/LEDLights.jsx
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import TextToSpeech from '../../TextToSpeech/textToSpeech';
import { toast } from 'react-toastify'; // Importoi toast
import 'react-toastify/dist/ReactToastify.css';

import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton 
} from 'react-share';

import { 
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

import Chauvet from '../../assets/chauvet_logo.webp';
import img1 from '../../assets/LED-valot Chauvet 56-24C.webp';
import img2 from '../../assets/LED-valot Chauvet 56-24C2.webp';

const LEDLights = () => {
    const [available, setAvailable] = useState(0); // Alkuperäinen varasto, oletuksena 0
    const [quantity, setQuantity] = useState(1); // Valittu määrä
    const productId = "9"; // Tuotteen ID
    const [activeImg, setActiveImage] = useState(img1); // Aktiivinen kuva

    // Haetaan tuotteiden saatavuus backendistä komponentin latautuessa
    useEffect(() => {
        const fetchAvailability = async () => {
            try {
                const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=muut");
                if (!response.ok) {
                    throw new Error(`Palvelin palautti virheen: ${response.status}`);
                }
                const data = await response.json();

                console.log("API response data:", data);

                if (!data.categories) {
                    throw new Error("Categories field is missing in API response");
                }

                // Yhdistetään kaikki kategoriat yhteen taulukkoon
                const inventory = Object.values(data.categories).flat(); // Tämä yhdistää kaikki kategoriat

                console.log("Yhdistetty inventory:", inventory);

                // Etsi tuote ID:n perusteella
                const currentProduct = inventory.find(item => item.id === productId); // ID pitää olla merkkijono

                if (currentProduct) {
                    setAvailable(currentProduct.available);
                    console.log("Nykyisen tuotteen kappalemäärä:", currentProduct.available);
                } else {
                    console.error(`Tuotetta ei löytynyt ID:llä ${productId}!`);
                }
            } catch (error) {
                console.error("Virhe haettaessa API-dataa:", error);
                toast.error("Virhe tuotteen saatavuuden haussa. Yritä myöhemmin uudelleen.", { position: "top-right" });
            }
        };

        fetchAvailability();
    }, [productId]);

    const handleImageClick = (image) => {
        setActiveImage(image);
    };

    // Funktio kokonaishinnan laskemiseksi
    const calculateTotalPrice = () => {
        return quantity * 10; // Hinta 10 € per kappale
    };

    const addToCart = () => {
        try {
            // Hae vuokrauskori localStoragesta tai alusta uusi tyhjä taulukko
            const cart = JSON.parse(localStorage.getItem("rentalCart")) || [];
            
            // Tarkista, onko tuote jo korissa
            const existingProduct = cart.find((item) => item.id === productId);
    
            // Laske uusi määrä ja tarkista saatavuus ennen tilan päivitystä
            const newQuantity = existingProduct ? existingProduct.quantity + quantity : quantity;
            const unitPrice = 10; // Yksikköhinta
            const remainingStock = available - quantity; // Lasketaan jäljellä oleva määrä
    
            if (remainingStock < 0) {
                toast.error("Valittu määrä ylittää saatavilla olevan varaston!", { position: "top-right" });
                return;
            }
    
            if (existingProduct) {
                // Päivitä olemassa olevan tuotteen määrä ja kokonaiskustannukset
                existingProduct.quantity = newQuantity;
                existingProduct.totalPrice = newQuantity * unitPrice;
            } else {
                // Lisää uusi tuote koriin
                cart.push({
                    id: productId,
                    name: "LED-valot Chauvet 56-24C",
                    price: unitPrice, // Yksikköhinta
                    quantity: quantity,
                    totalPrice: quantity * unitPrice, // Kokonaiskustannukset
                });
            }
    
            // Päivitä vuokrauskori localStorageen
            localStorage.setItem("rentalCart", JSON.stringify(cart));
    
            // Päivitä jäljellä oleva saatavuus suoraan laskettuun arvoon
            setAvailable(remainingStock);
    
            // Luo `storage`-tapahtuma muiden komponenttien päivitystä varten
            window.dispatchEvent(new Event("storage"));
    
            // Näytä ilmoitus onnistuneesta lisäyksestä
            toast.success(`${quantity} x LED-valot Chauvet 56-24C lisätty vuokrauskoriin!`, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } catch (error) {
            console.error("Virhe tuotteen lisäämisessä vuokrauskoriin:", error);
            toast.error("Virhe tuotteen lisäämisessä vuokrauskoriin. Yritä myöhemmin uudelleen.", { position: "top-right" });
        }
    };
    
    
    

    // Tekstit
    const text =
        "Tähän osioon on koottu muita tuotteita tai palveluita, joita myymme tai tarjoamme vuokralle. Esittelyssä ensimmäisenä hauska Fujifilm Instax Mini-12 -kompaktikamera.";

    const text1 =
        "Valojen ominaisuudet. Ääniohjatut valo-ohjelmat: Voit antaa musiikin ohjata valaistusta ja luoda upeita visuaalisia tehosteita. Manuaalisesti säädettävät värit: Voit valita itse haluamasi värit ja luoda juuri oikeanlaisen tunnelman tapahtumaasi. Tehokkaat LED-valot: kahdeksan punaista, kahdeksan vihreää ja kahdeksan sinistä diodia takaavat kirkkaan ja värikkään valaistuksen. Suuri kirkkaus. Valot ovat erittäin kirkkaat ja ne sopivat myös isojen tilojen valaisemiseen. Alhainen lämmöntuotanto ja energiankulutus. LED-valot ovat energiatehokkaita ja ne eivät kuumene käytössä. Kuljetuslaatikko pyörillä ja vetokahvalla.";

    const text2 =
        "Hinta. Alkaen viisikymmentä euroa vuorokaudessa. Tiedustele saatavuutta painamalla alla olevaa nappia.";

    return (
        <>
            {/* Määrittele ToastContainer vain App.jsx:ssä, poista tämä */}
            {/* <ToastContainer /> */}

            {/* Takaisin-painike */}
            <button 
                onClick={() => { window.history.back(); }} 
                className="h-12 w-32 dark:bg-violet bg-primary dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out focus:outline-none"
                aria-label="Palaa edelliselle sivulle"
            >
                Takaisin
            </button>

            {/* Tuotteen kuvat */}
            <div className='flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5'>
                <div className='flex flex-col gap-5'>
                    {/* Suuri kuva */}
                    <img 
                        src={activeImg} 
                        alt='LED-valot Chauvet 56-24C' 
                        title="LED-valot Chauvet 56-24C" 
                        className='w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'
                    />
                    {/* Pienet kuvat */}
                    <div className='flex flex-row justify-center gap-5 drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'>
                        {/* LED-valot Chauvet 56-24C */}
                        <button 
                            onClick={() => handleImageClick(img1)} 
                            className='w-32 h-32 rounded-md p-0 border-0 bg-transparent cursor-pointer focus:outline-none'
                            aria-label="Näytä LED-valot Chauvet 56-24C"
                        >
                            <img 
                                src={img1} 
                                alt="LED-valot Chauvet 56-24C" 
                                title="LED-valot Chauvet 56-24C" 
                                className='w-full h-full object-cover rounded-md' 
                            />
                        </button>
                        {/* LED-valot Chauvet 56-24C ulkona */}
                        <button 
                            onClick={() => handleImageClick(img2)} 
                            className='w-32 h-32 rounded-md p-0 border-0 bg-transparent cursor-pointer focus:outline-none'
                            aria-label="Näytä LED-valot Chauvet 56-24C ulkona"
                        >
                            <img 
                                src={img2} 
                                alt="LED-valot Chauvet 56-24C ulkona" 
                                title="LED-valot Chauvet 56-24C ulkona" 
                                className='w-full h-full object-cover rounded-md' 
                            />
                        </button>
                    </div>

                    {/* Tuotteen videot */}
                    <h2 className='font-customFont2 text-shadow-lg text-center text-primary dark:text-primary p-10 text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl font-bold'>
                        Videot
                    </h2>

                    <div className="flex gap-5 flex-wrap justify-center aspect-video">
                        <iframe
                            className="aspect-auto"
                            src="https://www.youtube.com/embed/rTkP4k3twK0?si=ih7ZY1o_M15Q34pJ"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="LED-valot Chauvet 56-24C äänentoistopaketti esittelyvideo"
                            aria-label="Katso LED-valot Chauvet 56-24C äänentoistopaketti esittelyvideo"
                        ></iframe>
                    </div>
                </div>
                
                {/* Tuotteen kuvaus */}
                <div className='dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl'>
                    <div>
                        {/* Otsikko ja TextToSpeech */}
                        <div className="flex flex-row items-center">
                            <h1 className='font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none'>
                                LED-valot Chauvet 56-24C 6kpl
                            </h1>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text} />
                            </div>    
                        </div>
                        
                        <br />

                        {/* Teksti */}
                        <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>
                            Näillä LED-valoilla saa luotua upeaa tunnelmaa ja näyttävän valoshow juhlatilaan.
                        </p>
                        <br />
                        <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                            Nämä monipuoliset valot sopivat täydellisesti tunnelman luomiseen kaikenlaisissa tiloissa.
                        </p>
                        <br />

                        {/* Valojen ominaisuudet ja TextToSpeech */}
                        <div className="flex flex-row items-center">
                            <h2 className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>
                                Valojen ominaisuudet:
                            </h2>
                            <div className='speech-title ml-5'>
                                <TextToSpeech text={text1} />
                            </div>
                        </div>

                        {/* Lista ominaisuuksista */}
                        <ul className="ominaisuudet list-disc font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal p-4">
                            <li><strong>Ääniohjatut valo-ohjelmat:</strong> Voit antaa musiikin ohjata valaistusta ja luoda upeita visuaalisia tehosteita.</li>
                            <li><strong>Manuaalisesti säädettävät värit:</strong> Voit valita itse haluamasi värit ja luoda juuri oikeanlaisen tunnelman tapahtumaasi.</li>
                            <li><strong>Tehokkaat LED-valot:</strong> 8 punaista, 8 vihreää ja 8 sinistä diodia takaavat kirkkaan ja värikkään valaistuksen.</li>
                            <li><strong>Suuri kirkkaus:</strong> Valot ovat erittäin kirkkaat ja ne sopivat myös isojen tilojen valaisemiseen.</li>
                            <li><strong>Alhainen lämmöntuotanto ja energiankulutus:</strong> LED-valot ovat energiatehokkaita ja ne eivät kuumene käytössä.</li>
                            <li>Kuljetuslaatikko pyörillä ja vetokahvalla</li>
                        </ul>

                        {/* Lisäsyyt ja TextToSpeech */}
                        <div className="flex flex-row items-center">
                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>
                                Tässä vielä muutama syy, miksi sinun pitäisi valita tämä paketti tapahtumaasi:
                            </p>
                            <div className='speech-title ml-5'>
                                <TextToSpeech text={text2} />
                            </div>
                        </div>

                        {/* Lista lisäsyistä */}
                        <ul className="ominaisuudet list-disc font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal p-4">
                            <li><strong>Monipuolisuus:</strong> Paketti sopii monenlaisiin tapahtumiin, kuten häihin, pikkujouluihin, yritystilaisuuksiin ja live-musiikkiesityksiin.</li>
                            <li><strong>Helppo kuljetttaa:</strong> Kaiuttimet lumpsahtavat kiltisti kestäviin kantokahvallisiin suojalaukkuihinsa ja mikseri omaan olkalaukkuun johtoineen. Koko komeus mahtuu myös helposti henkilöautoon.</li>
                        </ul>

                        {/* Lopetusviesti */}
                        <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>
                            Ota yhteyttä ja varaa tämä huippupaketti tapahtumaasi!
                        </p>

                        {/* Sosiaalisen median jakopainikkeet */}
                        <div className="flex flex-row py-5 gap-2" title='Jaa tämä sivu'>
                            <FacebookShareButton
                                url={"https://spiderlegsproductions.com/led-valot-chauvet-56-24c"}
                                className="facebook-share-button flex flex-row items-center"
                                aria-label="Jaa Facebookissa"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={"https://spiderlegsproductions.com/led-valot-chauvet-56-24c"}
                                className="twitter-share-button flex flex-row items-center" 
                                aria-label="Jaa Twitterissä"
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>

                            <WhatsappShareButton
                                url={"https://spiderlegsproductions.com/led-valot-chauvet-56-24c"}
                                className="whatsapp-share-button flex flex-row items-center" 
                                aria-label="Jaa WhatsAppissa"
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>

                            <LinkedinShareButton
                                url={"https://spiderlegsproductions.com/led-valot-chauvet-56-24c"}
                                className="linkedin-share-button flex flex-row items-center" 
                                aria-label="Jaa LinkedInissä"
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>

                            <EmailShareButton
                                url={"https://spiderlegsproductions.com/led-valot-chauvet-56-24c"}
                                className="email-share-button flex flex-row items-center" 
                                aria-label="Jaa sähköpostilla"
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                        </div>

                        {/* Hinta ja TextToSpeech */}
                        <div className="flex flex-row items-center">
                            <h2 
                                title="Hinta" 
                                className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold'
                            >
                                {quantity} kpl, {calculateTotalPrice()} € / vrk
                            </h2>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={`${quantity} kappaleen hinta on ${calculateTotalPrice()} euroa per vuorokausi.`} />
                            </div>
                        </div>

                        {/* Määrän valinta ja saatavuus */}
                        <div className="mt-5">
                            <label htmlFor="quantity" className="block text-lg font-medium text-darkest dark:text-primary">
                                Valitse määrä:
                            </label>
                            <input 
                                type="number" 
                                id="quantity" 
                                name="quantity" 
                                min="1" 
                                max={available} 
                                value={quantity} 
                                onChange={(e) => {
                                    const val = parseInt(e.target.value, 10);
                                    if (isNaN(val)) {
                                        setQuantity(1);
                                    } else {
                                        setQuantity(Math.min(Math.max(1, val), available));
                                    }
                                }}
                                className="mt-1 block w-24 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                            <p className="mt-1 font-medium text-darkest dark:text-primary">Saatavilla: {available} kpl</p>
                        </div>

                        {/* Lisää vuokrauskoriin-nappi */}
                        <div className="flex flex-col sm:flex-row items-center gap-4 pt-5">
                            <button
                                title="Lisää vuokrauskoriin"
                                onClick={addToCart}
                                className={`h-12 w-full sm:w-60 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none ${
                                    available === 0 ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                                style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                disabled={available === 0}
                            >
                                Lisää vuokrauskoriin
                            </button>

                            <Link 
                                to="/varauskalenteri" 
                                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                                className="w-full sm:w-60"
                            >
                                <button
                                    title="Siirry varaamaan"
                                    className="h-12 w-full dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none"
                                    style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                >
                                    Siirry varauskalenteriin
                                </button>
                            </Link>
                        </div>

                        {/* Tuotteen logo */}
                        <div className='flex flex-wrap place-items-start pt-5'>
                            <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">
                                {/* Tuotteen logo */}
                                <a href="https://www.chauvetprofessional.com/" aria-label="Vieraile Chauvet:in sivuilla." title="Vieraile Chauvet:in sivuilla.">
                                    <img 
                                        src={Chauvet}  
                                        alt="Chauvet logo" 
                                        loading="lazy" 
                                        className='w-full h-full object-contain rounded-xl' 
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LEDLights;
