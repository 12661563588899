import Footer from '../../components/Footer/Footer';
import ADJVolcano from '../../components/ADJ-Volcano/ADJ-Volcano';
import ADJVolcanoHelmetComponent from './ADJ-VolcanoHelmetComponent'; // Lisätty Helmet-komponentti

export default function aDJVolcano() {
    return (
        <>
            <ADJVolcanoHelmetComponent /> {/* Lisätty Helmet-komponentti */}
            <ADJVolcano />
            <Footer />
        </>
    );
}
