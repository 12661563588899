// src/components/DB600W/DB600W.jsx
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import TextToSpeech from '../../TextToSpeech/textToSpeech';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton 
} from 'react-share';

import { 
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

// Import images 
import dblogo from '../../assets/db_logo.webp';
import img1 from '../../assets/Lavamonitori_db_technologies_flexsys_FM12_600W_edesta.webp';
import img2 from '../../assets/Lavamonitori_db_technologies_flexsys_FM12_600W2.webp';
import img3 from '../../assets/Lavamonitori_db_technologies_flexsys_FM12_600W_panel.webp';

// Tekstit
const text = "Lavamonitori db äfäm kaksitoista kuusisataawattia, neljä kappaletta. Äfäm kaksitoista on erinomainen monitorivalinta kun haluaa kuulla itsensä ja muut lavalla. Se sopii täydellisesti koko bändin käyttöön ja tarjoaa erinomaisen äänenlaadun sekä laulajille että soittajille.";
const text1 = "Lisätiedot. Tehokas kuudensadanwatin vahvistin. Tarjoaa riittävästi äänenvoimakkuutta myös isoilla lavoilla. Kahdentoista tuuman bassokaiutin. Toistaa matalat taajuudet tarkasti ja voimakkaasti. Säädettävät ominaisuudet. Voit säätää äänentoistoa omien tarpeidesi ja mieltymystesi mukaan. Integroitu DSP. Tarjoaa crossoverin, digitaaliset monikaistarajoittimet, vaihekorjauksen ja kahdeksan muuttuvaa esiasetusta. Integroidut kahvat ja monipuoliset liitännät, Mahdollisuus asentaa kahteen eri asentoon.";
const text2 = "Hinta. Alkaen neljäkymmentä euroa vuorokausi kappale. Tiedustele saatavuutta painamalla alla olevaa nappia.";

const DB600W = () => {
    const [activeImg, setActiveImage] = useState(img1);
    const [available, setAvailable] = useState(0); 
    const [quantity, setQuantity] = useState(1);
    const productId = "5"; 

    // Haetaan tuotteiden saatavuus backendistä komponentin latautuessa
    useEffect(() => {
        const fetchAvailability = async () => {
            try {
                const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php");
                const data = await response.json();
                
                if (!data.categories) {
                    throw new Error("Categories field is missing in API response");
                }

                const inventory = Object.values(data.categories).flat();
                const currentProduct = inventory.find(item => item.id === productId);
                const productStock = currentProduct?.available || 0;
                setAvailable(productStock);
            } catch (error) {
                console.error("Virhe saatavuuden hakemisessa:", error);
                toast.error("Virhe saatavuuden hakemisessa. Yritä myöhemmin uudelleen.", { position: "top-right" });
            }
        };

        fetchAvailability();
    }, [productId]);

    const handleImageClick = (image) => {
        setActiveImage(image);
    };

    // Kun käyttäjä klikkaa "Lisää vuokrauskoriin" -nappia:
    const addToCart = async () => {
        try {
            const cart = JSON.parse(localStorage.getItem("rentalCart")) || [];
            const existingProduct = cart.find((item) => item.id === productId);
    
            // Lasketaan uusi määrä
            const currentQuantity = existingProduct ? existingProduct.quantity : 0;
            const newQuantity = currentQuantity + quantity;
    
            // Lasketaan jäljellä oleva määrä ennen tilan päivitystä
            const remainingStock = available - quantity;
    
            // Tarkistetaan, ettei ylitetä saatavilla olevaa varastoa
            if (remainingStock < 0) {
                toast.error("Valittu määrä ylittää saatavilla olevan varaston!", { position: "top-right" });
                return;
            }
    
            // Päivitetään kori
            if (existingProduct) {
                existingProduct.quantity = newQuantity;
            } else {
                cart.push({
                    id: productId,
                    name: "Lavamonitori db FM12 600W",
                    price: 40, // Vuorokausihinta
                    quantity: quantity,
                });
            }
    
            // Päivitetään vuokrauskori localStorageen
            localStorage.setItem("rentalCart", JSON.stringify(cart));
    
            // Päivitetään jäljellä oleva saatavuus
            setAvailable(remainingStock);
    
            // Kerrotaan muille komponenteille varaston päivittymisestä
            window.dispatchEvent(new Event("storage"));
    
            // Ilmoitetaan onnistuneesta lisäyksestä
            toast.success(`${quantity} x Lavamonitori db FM12 600W lisätty vuokrauskoriin!`, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } catch (error) {
            console.error("Virhe tuotteen lisäämisessä vuokrauskoriin:", error);
            toast.error("Virhe tuotteen lisäämisessä vuokrauskoriin. Yritä myöhemmin uudelleen.", { position: "top-right" });
        }
    };
    

    
    return (
        <>
            <ToastContainer
                toastClassName={() =>
                    "relative flex items-center justify-between p-4 mb-4 w-full max-w-xs dark:bg-lightbg bg-lightbg dark:text-darkest text-darkest rounded shadow-lg"
                }
            />

            <button 
                onClick={() => { window.history.back(); }} 
                className="h-12 w-32 dark:bg-violet bg-primary dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out focus:outline-none"
                aria-label="Takaisin"
            >
                Takaisin
            </button>
               
            <div className='flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5'>
                <div className='flex flex-col gap-5'>
                    <img 
                        src={activeImg} 
                        title="Lavamonitori dB FM12 600W" 
                        alt='Lavamonitori dB FM12 600W' 
                        className='w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]' 
                    />

                    <div className='flex gap-5 flex-wrap justify-center drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'>
                        <button 
                            onClick={() => handleImageClick(img1)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="View Lavamonitori dB FM12 600W Front View"
                        >
                            <img 
                                src={img1} 
                                alt="Lavamonitori dB FM12 600W Front View" 
                                title="Lavamonitori dB FM12 600W Front View" 
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>

                        <button 
                            onClick={() => handleImageClick(img2)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="View Lavamonitori dB FM12 600W Side View"
                        >
                            <img 
                                src={img2} 
                                alt="Lavamonitori dB FM12 600W Side View" 
                                title="Lavamonitori dB FM12 600W Side View" 
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>

                        <button 
                            onClick={() => handleImageClick(img3)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="View Lavamonitori dB FM12 600W Panel View"
                        >
                            <img 
                                src={img3} 
                                alt="Lavamonitori dB FM12 600W Panel View" 
                                title="Lavamonitori dB FM12 600W Panel View"  
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>
                    </div>

                    <h2 className='font-customFont2 text-shadow-lg text-center text-primary dark:text-primary p-10 text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl font-bold'>Videot</h2>
                    <div className="flex gap-5 flex-wrap justify-center aspect-video">
                        <iframe
                            className="aspect-auto"
                            src="https://www.youtube.com/embed/ZI2-dCuFFag?si=KJJP0YiIlUnTJ6SZ"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Lavamonitori dB FM12 600W Product Video"
                        ></iframe>
                    </div>
                </div>

                <div className='dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl'>
                    <div>
                        <div className="flex flex-row items-center">
                            <h1 className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none">
                                Lavamonitori db FM12 600W 4 kpl
                            </h1>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text} />
                            </div> 
                        </div>

                        <br />

                        <p className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl font-light'>
                            <strong>FM12</strong> on erinomainen monitorivalinta kun haluaa kuulla itsensä ja muut lavalla. Se sopii täydellisesti koko bändin käyttöön ja tarjoaa erinomaisen äänenlaadun sekä laulajille että soittajille.
                        </p>
                        <br />

                        <div className="flex flex-row items-center">
                            <h2 className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl font-bold'>
                                Lisätiedot:
                            </h2>
                            <div className='speech-title ml-5'>
                                <TextToSpeech text={text1} />
                            </div>
                        </div>

                        <ul className="ominaisuudet list-disc font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl font-light p-4">
                            <li>Tehokas 600 watin vahvistin: Tarjoaa riittävästi äänenvoimakkuutta myös isoilla lavoilla.</li>
                            <li><strong>12 tuuman bassokaiutin:</strong> Toistaa matalat taajuudet tarkasti ja voimakkaasti.</li>
                            <li><strong>Säädettävät ominaisuudet:</strong> Voit säätää äänentoistoa omien tarpeidesi ja mieltymystesi mukaan.</li>
                            <li><strong>Integroitu DSP:</strong> Tarjoaa crossoverin, digitaaliset monikaistarajoittimet, vaihekorjauksen ja kahdeksan muuttuvaa esiasetusta.</li>
                            <li><strong>Integroidut kahvat ja monipuoliset liitännät</strong></li>
                            <li><strong>Mahdollisuus asentaa kahteen eri asentoon.</strong></li>
                        </ul>

                        <div className="flex flex-row py-5 gap-2" title="Jaa tämä sivu">
                            <FacebookShareButton 
                                url={"https://spiderlegsproductions.com/lavamonitori-db-fm12-600w"} 
                                className="facebook-share-button flex flex-row items-center">
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <TwitterShareButton 
                                url={"https://spiderlegsproductions.com/lavamonitori-db-fm12-600w"} 
                                className="twitter-share-button flex flex-row items-center">
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>

                            <WhatsappShareButton 
                                url={"https://spiderlegsproductions.com/lavamonitori-db-fm12-600w"} 
                                className="whatsapp-share-button flex flex-row items-center">
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>

                            <LinkedinShareButton 
                                url={"https://spiderlegsproductions.com/lavamonitori-db-fm12-600w"} 
                                className="linkedin-share-button flex flex-row items-center">
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>

                            <EmailShareButton 
                                url={"https://spiderlegsproductions.com/lavamonitori-db-fm12-600w"} 
                                className="email-share-button flex flex-row items-center">
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                        </div>

                        <div className="flex flex-row items-center">
                            <h2 title="Hinta" className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold'>
                                Alk. 40€ / vrk
                            </h2>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text2} />
                            </div>
                        </div>

                        <div className="mt-5">
                            <label htmlFor="quantity" className="block text-lg font-medium text-darkest dark:text-primary">
                                Valitse määrä:
                            </label>
                            <input 
                                type="number" 
                                id="quantity" 
                                name="quantity" 
                                min="1" 
                                max={available} 
                                value={quantity} 
                                onChange={(e) => {
                                    const val = parseInt(e.target.value, 10);
                                    if (isNaN(val)) {
                                        setQuantity(1);
                                    } else {
                                        setQuantity(Math.min(Math.max(1, val), available));
                                    }
                                }}
                                className="mt-1 block w-24 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                            <p className="mt-1 font-medium text-darkest dark:text-primary">Saatavilla: {available} kpl</p>
                        </div>

                        <div className="flex flex-col sm:flex-row items-center gap-4 pt-5">
                            <button
                                title="Lisää vuokrauskoriin"
                                onClick={addToCart}
                                className={`h-12 w-full sm:w-60 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none ${
                                    available === 0 ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                                style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                disabled={available === 0}
                            >
                                Lisää vuokrauskoriin
                            </button>

                            <Link 
                                to="/varauskalenteri" 
                                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                                className="w-full sm:w-60"
                            >
                                <button
                                    title="Siirry varaamaan"
                                    className="h-12 w-full dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none"
                                    style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                >
                                    Siirry varauskalenteriin
                                </button>
                            </Link>
                        </div>

                        <div className='flex flex-wrap place-items-start pt-5'>
                            <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">
                                <a href="https://www.dbtechnologies.com/en/home/" aria-label="Visit dB Technologies' website">
                                    <img 
                                        src={dblogo} 
                                        alt="dB logo" 
                                        loading="lazy" 
                                        title="Vieraile dB:n sivuilla." 
                                        className='w-full h-full object-contain rounded-xl'
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DB600W;
