import Footer from '../../components/Footer/Footer';
import FujifilmInstaxMini12 from '../../components/FujifilmInstaxMini12/FujifilmInstaxMini12';
import FujifilmInstaxMini12HelmetComponent from '../../Pages/FujifilmInstaxMini12/FuijifilmInstaxMini12HelmetComponent';

export default function FujifilmInstaxMini12Page() {
    return (
        <>
            <FujifilmInstaxMini12HelmetComponent />
            <FujifilmInstaxMini12 />
            <Footer />
        </>
    );
}
