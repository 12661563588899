// src/components/BookingCalendar/BookingCalendar.jsx
import { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import fiLocale from "@fullcalendar/core/locales/fi";
import "@fullcalendar/common/main.css"; // Adjust based on your FullCalendar version
import "@fullcalendar/daygrid/main.css";
import "./BookingCalendar.css";

const BookingCalendar = () => {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  // Loading animation
  useEffect(() => {
    if (loading || progress < 100) {
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 5;
        });
      }, 100);
      return () => clearInterval(interval);
    }
  }, [loading, progress]);

  // Fetch reservations
  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await fetch("https://spiderlegsproductions.com/api/get_reservations.php");
        const data = await response.json();
    
        console.log("API data:", data); // Debugging: Tarkista saatu data
    
        if (data.success && data.reservations) {
          setEvents(
            data.reservations.map((reservation) => ({
              title: "Varattu",
              start: reservation.start,
              end: reservation.end || reservation.start,
              extendedProps: {
                tooltip: `Vuokrattu: ${reservation.title}`,
              },
            }))
          );
        } else {
          throw new Error(data.message || "Virhe varauksia hakiessa");
        }
      } catch (error) {
        console.error("Virhe palvelimeen yhdistettäessä:", error);
        setError("Varauskalenteria ei voitu ladata. Yritä myöhemmin uudelleen.");
      } finally {
        setLoading(false); // Lopeta latausanimaatio
      }
    };
  
    fetchReservations();
  }, []);
  
  if (loading || progress < 100) {
    return (
      <div className="loading-container flex flex-col items-center justify-center h-screen">
        <div className="relative w-64 h-4 dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 rounded-full overflow-hidden">
          <div
            className="absolute top-0 left-0 h-full bg-gradient-to-r from-blue-500 to-green-500 transition-all ease-linear"
            style={{ width: `${progress}%` }}
          />
        </div>
        <p className="mt-4 font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
          {progress}%
        </p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
          {error}
        </p>
      </div>
    );
  }

  return (
    <div className="booking-calendar-container p-5">
      <h2 className="font-customFont2 text-shadow-lg text-darkest dark:text-primary text-3xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-center">
        Varaukset
      </h2>
      <div className="custom-calendar-wrapper rounded-lg shadow-md mx-auto dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75">
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events}
          editable={false}
          eventClassNames={() => "fc-event"}
          eventContent={(eventInfo) => (
            <div
              className="fc-event"
              data-tooltip={eventInfo.event.extendedProps.tooltip}
            >
              {eventInfo.event.title}
            </div>
          )}
          locales={[fiLocale]}
          locale="fi"
        />
      </div>
    </div>
  );
};

export default BookingCalendar;
