// src/components/ADJ-Stinger2/ADJStinger2.jsx
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import TextToSpeech from '../../TextToSpeech/textToSpeech';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton 
} from 'react-share';

import { 
    FacebookIcon,
    XIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

// Import images
import ADJ from '../../assets/ADJ_logo.webp';
import img1 from '../../assets/Multiefekti_ADJ_Stinger_II.webp';
import img2 from '../../assets/Multiefekti_ADJ_Stinger_II_Back.webp';

const ADJStinger2 = () => {
    const [activeImg, setActiveImage] = useState(img1);
    const [available, setAvailable] = useState(0); // Määritellään 'available' tila
    const productId = "7"; // ID Multiefekti ADJ Stinger II:lle

    // Haetaan tuotteiden saatavuus backendistä
    useEffect(() => {
        const fetchAvailability = async () => {
            try {
                const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php");
                const data = await response.json();

                console.log("API response data:", data);

                if (!data.categories) {
                    throw new Error("Categories field is missing in API response");
                }

                // Yhdistetään kaikki kategoriat yhteen taulukkoon
                const inventory = Object.values(data.categories).flat();

                console.log("Yhdistetty inventory:", inventory);

                // Etsi tuote ID:n perusteella
                const currentProduct = inventory.find(item => item.id === productId);

                if (currentProduct) {
                    setAvailable(currentProduct.available);
                    console.log("Nykyisen tuotteen kappalemäärä:", currentProduct.available);
                } else {
                    console.error(`Tuotetta ei löytynyt ID:llä ${productId}!`);
                    toast.error("Tuotteen saatavuutta ei voitu hakea. Yritä myöhemmin uudelleen.", { position: "top-right" });
                }
            } catch (error) {
                console.error("Virhe haettaessa API-dataa:", error);
                toast.error("Virhe tuotteen saatavuuden haussa. Yritä myöhemmin uudelleen.", { position: "top-right" });
            }
        };

        fetchAvailability();
    }, [productId]);

    const handleImageClick = (image) => {
        setActiveImage(image);
    };

    // AddToCart function
    const addToCart = async () => {
        try {
            const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php");
            const data = await response.json();

            // Yhdistetään kaikki kategoriat yhteen taulukkoon
            const inventory = Object.values(data.categories).flat();

            const productStock = inventory.find(item => item.id === productId)?.available || 0;

            const cart = JSON.parse(localStorage.getItem("rentalCart")) || [];
            const product = {
                id: productId,
                name: "Multiefekti ADJ Stinger II",
                price: 50, // Vuorokausihinta
                quantity: 1,
            };
            
            const existingProduct = cart.find((item) => item.id === product.id);
            const currentQuantity = existingProduct ? existingProduct.quantity : 0;

            if (currentQuantity + 1 > productStock) {
                toast.error("Tuotetta ei voi lisätä enempää vuokrauskoriin kuin mitä varastossa on!", { position: "top-right" });
                return;
            }

            if (existingProduct) {
                existingProduct.quantity += 1; // Lisää määrä, jos tuote on jo korissa
            } else {
                cart.push(product); // Lisää uusi tuote koriin
            }

            // Päivitä localStorage
            localStorage.setItem("rentalCart", JSON.stringify(cart));

            // Luo `storage`-tapahtuma, jotta navbar päivittyy
            window.dispatchEvent(new Event("storage"));

            // Päivitä saatavuus tila
            setAvailable(prevAvailable => prevAvailable - 1);

            // Näytetään toast-ilmoitus onnistuneesta lisäyksestä
            toast.success(`${product.name} lisätty vuokrauskoriin!`, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } catch (error) {
            console.error("Virhe tuotteen lisäämisessä vuokrauskoriin:", error);
            toast.error("Virhe tuotteen lisäämisessä vuokrauskoriin. Yritä myöhemmin uudelleen.", { position: "top-right" });
        }
    };

    // Tekstit
    const text =
        "Multiefekti ADJ Stinger II. Valoshow yhdellä laitteella!";

    const text1 =
        "Täydellinen valoshow bileisiin: Tämä monipuolinen valo yhdistää moonflowerin, strobon ja laserin yhteen tehokkaaseen pakettiin. Sadat terävät valonsäteet täyttävät tanssilattian, laserit piirtävät upeita kuvioita ja strobo luo vauhdikasta tunnelmaa. Kaikki tämä yhdellä laitteella!";

    const text2 =
        "DMX-ohjaus: Stinger II on DMX-ohjattava, joten voit helposti integroida sen valaistuksen ohjausjärjestelmääsi tarvittaessa.";

    const text3 =
        "Sopii jokaiseen tilaan: Stinger II on kompakti ja kevyt, joten se sopii erinomaisesti kaikenkokoisiin tiloihin. Se on helppo asentaa ja käyttää kaukosäätimen avulla.";

    const text4 =
        "Tekniset tiedot. Mitat: 304 x 230,5 x 97,5 mm, Paino: 5 kg, Kaukosäädin, Kantolaukku, Vuokraa tämä monipuolinen valo tapahtumaasi piristämään tunnelmaa!";

    const text5 =
        "Hinta. Alkaen viisikymmentä euroa vuorokausi. Tiedustele saatavuutta painamalla alla olevaa nappia.";

    return (
        <>
            {/* ToastContainer for notifications */}
            <ToastContainer
                toastClassName={() =>
                    "relative flex items-center justify-between p-4 mb-4 w-full max-w-xs dark:bg-lightbg bg-lightbg dark:text-darkest text-darkest rounded shadow-lg"
                }
            />

            {/* Takaisin-nappula */}
            <button 
                onClick={() => { window.history.back(); }} 
                className="h-12 w-32 dark:bg-violet bg-primary dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out focus:outline-none"
                aria-label="Takaisin"
            >
                Takaisin
            </button>
               
            {/* Tuotteen kuvat */}
            <div className='flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5'>

                <div className='flex flex-col gap-5'>

                    <img 
                        src={activeImg} 
                        alt='Multiefekti ADJ Stinger II' 
                        title="Multiefekti ADJ Stinger II" 
                        className='w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'
                    />

                    <div className='flex gap-5 flex-wrap justify-center drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'>

                        {/* Korvattu <img> <button>-elementeillä */}
                        <button 
                            onClick={() => handleImageClick(img1)} 
                            className='w-32 h-32 rounded-md p-0 border-0 bg-transparent cursor-pointer focus:outline-none'
                            aria-label="Näytä Multiefekti ADJ Stinger II Etupuoli"
                        >
                            <img 
                                src={img1} 
                                alt="Multiefekti ADJ Stinger II Etupuoli" 
                                title="Multiefekti ADJ Stinger II Etupuoli" 
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>

                        <button 
                            onClick={() => handleImageClick(img2)} 
                            className='w-32 h-32 rounded-md p-0 border-0 bg-transparent cursor-pointer focus:outline-none'
                            aria-label="Näytä Multiefekti ADJ Stinger II Takapuoli"
                        >
                            <img 
                                src={img2} 
                                alt="Multiefekti ADJ Stinger II Takapuoli" 
                                title="Multiefekti ADJ Stinger II Takapuoli" 
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>

                    </div>

                    {/* Tuotteen videot */}
                    <h2 className='font-customFont2 text-shadow-lg text-center text-darkest dark:text-primary p-10 text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl font-bold'>Videot</h2>
                    <div className="flex gap-5 flex-wrap justify-center aspect-video">
                        <iframe
                            className="aspect-auto"
                            src="https://www.youtube.com/embed/XCDmUI576hg?si=6o9-dvPRpKIsp0Mk"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Multiefekti ADJ Stinger II Product Video"
                        ></iframe>
                    </div>
                </div>

                {/* Tuotteen kuvaus */}
                <div className='dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl'>

                    <div>

                        {/* SPEECH */}

                        <div className="flex flex-row items-center">
                            {/* Otsikko */}
                            <h1 className='font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none'>Multiefekti ADJ Stinger II</h1>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text} />
                            </div>    
                        </div>
                            
                        <br />

                        {/* Teksti */}
                        <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>Stinger II Multiefekti: Valoshow yhdellä laitteella!</p><br />

                        {/* SPEECH */}
                        
                        <div className="flex flex-row items-center">

                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                            
                            <strong>Täydellinen valoshow bileisiin:</strong> Tämä monipuolinen valo yhdistää moonflowerin, strobon ja laserin yhteen tehokkaaseen pakettiin. Sadat terävät valonsäteet täyttävät tanssilattian, laserit piirtävät upeita kuvioita ja strobo luo vauhdikasta tunnelmaa. Kaikki tämä yhdellä laitteella!</p>
                            <div className='speech-title ml-5'>

                                <TextToSpeech text={text1} />
                                
                            </div>

                        </div>
                        
                        <br />

                        {/* SPEECH */}
                        
                        <div className="flex flex-row items-center">

                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'><strong>DMX-ohjaus:</strong> Stinger II on DMX-ohjattava, joten voit helposti integroida sen valaistuksen ohjausjärjestelmääsi tarvittaessa.</p>
                            <div className='speech-title ml-5'>

                                <TextToSpeech text={text2} />
                                
                            </div>

                        </div>
                            
                        <br />

                        {/* SPEECH */}
                        
                        <div className="flex flex-row items-center">

                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'><strong>Sopii jokaiseen tilaan:</strong> Stinger II on kompakti ja kevyt, joten se sopii erinomaisesti kaikenkokoisiin tiloihin. Se on helppo asentaa ja käyttää kaukosäätimen avulla.</p>
                            <div className='speech-title ml-5'>

                                <TextToSpeech text={text3} />
                                
                            </div>

                        </div>
                            
                            
                        <br />

                        {/* SPEECH */}
                        
                        <div className="flex flex-row items-center">

                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>Tekniset tiedot:</p>
                            <div className='speech-title ml-5'>

                                <TextToSpeech text={text4} />

                            </div>

                        </div>

                        <ul className="ominaisuudet list-disc font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl 3xl:text-3xl font-normal p-4">
                            <li>Mitat: 304 x 230,5 x 97,5 mm</li>
                            <li>Paino: 5 kg</li>
                            <li>Kaukosäädin</li>
                            <li>Kantolaukku</li>
                        </ul>
                        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">Vuokraa tämä monipuolinen valo tapahtumaasi piristämään tunnelmaa!</p>

                        {/* Jaa-painikkeet */}
                        <div className="flex flex-row py-5 gap-2" title="Jaa tämä sivu">

                            <FacebookShareButton
                                url={"https://spiderlegsproductions.com/multiefekti-adj-stinger-ii"}
                                className="facebook-share-button flex flex-row items-center"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={"https://spiderlegsproductions.com/multiefekti-adj-stinger-ii"}
                                className="twitter-share-button flex flex-row items-center"
                            >
                                <XIcon size={32} round />
                            </TwitterShareButton>

                            <WhatsappShareButton
                                url={"https://spiderlegsproductions.com/multiefekti-adj-stinger-ii"}
                                className="whatsapp-share-button flex flex-row items-center"
                            >
                                <WhatsappIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </WhatsappShareButton>

                            <LinkedinShareButton
                                url={"https://spiderlegsproductions.com/multiefekti-adj-stinger-ii"}
                                className="linkedin-share-button flex flex-row items-center"
                            >
                                <LinkedinIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </LinkedinShareButton>

                            <EmailShareButton
                                url={"https://spiderlegsproductions.com/multiefekti-adj-stinger-ii"}
                                className="email-share-button flex flex-row items-center"
                            >
                                <EmailIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </EmailShareButton>

                        </div>

                        {/* SPEECH */}
                        <div className="flex flex-row items-center">
                            {/* Hinta */}
                            <h2 title="Hinta" className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold'>Alk. 50€ / vrk</h2>
                            <div className='speech-title ml-5 flex justify-center'>

                                <TextToSpeech text={text5} />
                                
                            </div>

                        </div>

                       {/* Lisää vuokrauskoriin-nappi */}
                        <div className="flex flex-col sm:flex-row items-center gap-4 pt-5">
                            <button
                                title="Lisää vuokrauskoriin"
                                onClick={addToCart}
                                className={`h-12 w-full sm:w-60 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none ${
                                    available === 0 ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                                style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                disabled={available === 0}
                            >
                                Lisää vuokrauskoriin
                            </button>

                            <Link 
                                to="/varauskalenteri" 
                                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                                className="w-full sm:w-60"
                            >
                                <button
                                    title="Siirry varaamaan"
                                    className="h-12 w-full dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none"
                                    style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                >
                                    Siirry varauskalenteriin
                                </button>
                            </Link>
                        </div>

                        {/* Tuotteen logo */}
                        <div className='flex flex-wrap place-items-start pt-5'>
                            <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">
                                {/* Tuotteen logo */}
                                <a href="https://www.adj.eu/" aria-label="Vieraile ADJ:n sivuilla" title="Vieraile ADJ:n sivuilla">
                                    <img 
                                        src={ADJ} 
                                        alt="ADJ logo" 
                                        loading="lazy" 
                                        title="Vieraile ADJ:n sivuilla."
                                        className='w-full h-full object-contain rounded-xl'
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ADJStinger2;
