// src/App.jsx
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import './styles.css';
import { Routes, Route, Link } from "react-router-dom";
import CookieConsent from 'react-cookie-consent';
import { FaArrowUp } from "react-icons/fa";

import { ToastContainer } from 'react-toastify'; // Importoi ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Importoi toastify CSS

import Navbar from "./components/Navbar/Navbar";
import Home from "./Pages/Home/Home";
import Rental from "./Pages/Rental/Rental";
import AboutUs from "./Pages/AboutUs/AboutUs";
import UKK from "./Pages/UKK/UKK";
import Booking from "./Pages/Contactpage/Contactpage";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import Partybox from "./Pages/PartyBox300/Partybox300";
import Mackie300W from "./Pages/Mackie300W/Mackie300W";
import JBL625W from "./Pages/JBL635W/JBL625W";
import PreSonus2000W from "./Pages/PreSonus2000W/PreSonus2000W";
import DB600W from "./Pages/DB600W/DB600";
import SoundcraftFX16 from "./Pages/SoundcraftFX16/SoundcraftFX16";
import NotFound from "./Pages/NotFound/NotFound";
import ADJStinger2 from "./Pages/ADJ-Stinger2/ADJ-Stinger2";
import ADJVolcano from "./Pages/ADJ-Volcano/ADJ-Volcano";
import LEDLights from "./Pages/LED-Lights/LED-lights";
import ADJmyDMX from "./Pages/ADJ-GO/ADJmyDMXGO";
import FujifilmInstaxMini12Page from "./Pages/FujifilmInstaxMini12/FujifilmInstaxMini12";

function App() {
  const [theme, setTheme] = useState(() => {
    const savedTheme = typeof window !== 'undefined' ? localStorage.getItem("theme") : null;
    return savedTheme || "dark";
  });
  
  const element = typeof document !== 'undefined' ? document.documentElement : null;

  useEffect(() => {
    if (theme === "dark" && element) {
      element.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else if (element) {
      element.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [theme, element]);

  const [showButton, setShowButton] = useState(false);

  const onScroll = () => {
    let pixelsFromTop = window.scrollY;
    let documentHeight = document.body.clientHeight;
    let windowHeight = window.innerHeight;
    let difference = documentHeight - windowHeight;
    let percentage = (100 * pixelsFromTop) / difference;
    const progressBar = document.getElementById("bar");
    if (progressBar) {
      progressBar.style.width = `${percentage}%`;
    }
    setShowButton(pixelsFromTop > 881);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 400,
      easing: "ease-in-sine",
      delay: 0,
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <Navbar theme={theme} setTheme={setTheme} />

      {/* ToastContainer on lisätty tähän */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        toastClassName={() =>
          "relative flex items-center justify-between p-4 mb-4 w-full max-w-xs dark:bg-lightbg bg-lightbg dark:text-darkest text-darkest rounded shadow-lg"
        }
      />

      <div
        className={`bg-fixed bg-center bg-cover ${
          theme === "dark" 
            ? "bg-[url('/src/assets/backgroundimage2.webp')]" // Varmista polku
            : "bg-lightbg" // Määritä light mode -väri
        }`}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/palvelut" element={<Rental />} />
          <Route path="/UKK" element={<UKK />} />
          <Route path="/meista" element={<AboutUs />} />
          <Route path="/varauskalenteri" element={<Booking />} />
          <Route path="/vuokrattavat/:laite" element={<Rental />} />
          <Route path="/bilekaiutin-mikrofoneilla" element={<Partybox />} />
          <Route path="/2000w-aanentoistopaketti" element={<PreSonus2000W />} />
          <Route path="/300w-aanentoistopaketti" element={<Mackie300W />} />
          <Route path="/625w-aanentoistopaketti" element={<JBL625W />} />
          <Route path="/multiefekti-adj-stinger-ii" element={<ADJStinger2 />} />
          <Route path="/savukone-adj-volcano" element={<ADJVolcano />} />
          <Route path="/led-valot-chauvet-56-24c" element={<LEDLights />} />
          <Route path="/ADJmyDMXGO" element={<ADJmyDMX />} />
          <Route path="/fujifilm-instax-mini-12" element={<FujifilmInstaxMini12Page />} />
          <Route path="/lavamonitori-db-fm12-600w" element={<DB600W />} />
          <Route path="/SoundcraftFX16" element={<SoundcraftFX16 />} />
          <Route path="/Tietosuojalausunto" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>

      <CookieConsent
        location="bottom"
        style={{
          background: "#0F0240",
          textAlign: "center",
          opacity: "95%",
          fontFamily: "Outfit",
          display: "block",
          boxShadow: "0px 13px 16px -1px rgba(0,0,0,0.75)",
        }}
        buttonStyle={{
          color: "#fafafa",
          background: "#3B0273",
          fontSize: "14px",
          position: "relative",
          margin: "5px",
          borderRadius: "10%",
        }}
        buttonText="Hyväksy"
        expires={365}
      >
        <div className="text-white">
          Tämä sivu käyttää evästeitä. Lue lisää{" "}
          <Link to="/Tietosuojalausunto" style={{ color: "#fafafa" }}>
            tietosuojalausunnostamme
          </Link>
          .
        </div>
      </CookieConsent>

      <div className="progress_wrapper">
        <div className="progress_bar" id="bar"></div>
      </div>
      <FaArrowUp
        className={showButton ? "showButton" : "hidden"}
        onClick={scrollToTop}
      />
    </>
  );
}

export default App;
