import { useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes

const Accordion = ({ title, answer }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="flex flex-col items-center justify-content-center sm:pr-20 py-4 font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
      {/* Button */}
      <button onClick={() => setAccordionOpen(!accordionOpen)} className="flex justify-between w-full text-left">
        <span>{title}</span>
        <svg
          className="fill-darkest dark:fill-primary shrink-0 ml-8"
          width="16"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="7" width="16" height="2" rx="1" className={`transform origin-center transition duration-200 ease-out ${accordionOpen && "!rotate-180"}`} />
          <rect y="7" width="16" height="2" rx="1" className={`transform origin-center rotate-90 transition duration-200 ease-out ${accordionOpen && "!rotate-180"}`} />
        </svg>
      </button>

      <div className={`grid overflow-hidden transition-all duration-300 ease-in-out font-customFont1 text-shadow-lg text-left text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl text-darkest font-light dark:text-linkcolhover dark:font-light ${accordionOpen ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"}`}>
        <div className="overflow-hidden">{answer}</div>
      </div>
    </div>
  );
};

// Define prop types
Accordion.propTypes = {
  title: PropTypes.string.isRequired, // Validate that 'title' is a required string
  answer: PropTypes.string.isRequired, // Validate that 'answer' is a required string
};

export default Accordion;
