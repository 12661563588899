import { FaCarRear } from "react-icons/fa6";
import { GrInstall } from "react-icons/gr";
import { FaHandsHelping } from "react-icons/fa";
import { Link } from "react-router-dom";
import TextToSpeech from '../../TextToSpeech/textToSpeech';

const text =
"Miksi valita meidät? Kuljetuspalvelu. - kilometrikorvauksen mukaan. Asennuspalvelu. - Laitteiden asennus käyttökuntoon tapahtumapaikalla. - Opastuspalvelu. - kaikkiin paketteihin ja laitteisiin maksutta. Lue lisää usein kysytyistä kysymyksistä nappia klikkaamalla.";

const skillsData = [
  {
    name: "Kuljetuspalvelu",
    icon: (
      <FaCarRear className="text-5xl text-darkest dark:text-primary text-shadow-lg drop-shadow-[1px_10px_5px_rgba(0,0,0,0.40)]" />
    ),
    description: "Kuljetuspalvelu voimassa olevan kilometrikorvauksen mukaan.",
   
  },
  {
    name: "Asennuspalvelu",
    icon: (
      <GrInstall className="text-5xl text-darkest dark:text-primary text-shadow-lg drop-shadow-[1px_10px_5px_rgba(0,0,0,0.40)]" />
    ),
    description: "Laitteiden asennus käyttökuntoon tapahtumapaikalla.",
 
  },
  {
    name: "Opastuspalvelu",
    icon: (
      <FaHandsHelping className="text-5xl text-darkest dark:text-primary text-shadow-lg drop-shadow-[1px_10px_5px_rgba(0,0,0,0.40)]" />
    ),
    description: "Opastuspalvelu kaikkiin paketteihin ja laitteisiin maksutta.",
 
  },
];
const Services = () => {
  return (
    <div className="pb-20">
  
      <span id="about"></span>

      <div className=" py-4 sm:min-h-[600px] sm:grid sm:place-items-center dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 rounded-3xl pb-10 p-5">
        
        <div className="container">

          {/* SPEECH */}

          <div className="flex flex-row items-center">
        
            <h2 data-aos="fade-up" className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight">Miksi valita meidät?</h2>

              <div data-aos="fade-up" className='speech-title ml-5 flex justify-center'>

                <TextToSpeech text={text} />

              </div> 

          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 mdd:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
            {skillsData.map((skill) => (

              <div key={skill.name} data-aos="fade-up" className="card text-center group space-y-3 sm:space-y-6 p-5 sm:py-16 bg-white bg-opacity-50 hover:bg-lightbg hover:bg-opacity-60 rounded-3xl relative group cursor-pointer">

                <div loading="lazy" title="Palvelu" className="grid place-items-center">{skill.icon}</div>
                <h2 className="font-customFont2 text-shadow-lg text-center text-darkest dark:text-primary text-3xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-3xl font-bold">{skill.name}</h2>

                <p className="font-customFont1 text-shadow-lg text-center text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">{skill.description}</p>

                <div className="grid place-content-center">

                    {/* BUTTON */}

                    <Link to="/UKK" onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'instant' }) } }>

                      <button title="Lue lisää!" className="h-12 w-52 
                      dark:bg-primary 
                        dark:hover:border-2
                        dark:hover:opacity-90
                      dark:border-white
                      dark:text-darkest 

                      bg-darkest 
                        hover:border-2
                        hover:border-opacity-90
                        hover:opacity-90
                      border-linkcolhover
                      text-primary 
                      
                        font-customFont1 
                        font-bold 
                        text-2xl 
                        overflow-hidden 
                        transform hover:scale-x-110 
                        hover:scale-y-105 transition 
                        duration-300 ease-in
                        items-center 
                        rounded-full 
                        cursor-pointer" 

                        alt="Lue lisää nappi"
                        style={{
                            boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)"}}>
                          Lue lisää!
                          
                          </button>

                    </Link>
            
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;