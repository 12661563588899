// src/Pages/Contactpage/Contactpage.jsx
import { useState, useEffect, useRef } from "react";
import Footer from "../../components/Footer/Footer";
import BookingCalendar from "../../components/BookingCalendar/BookingCalendar";
import ReCAPTCHA from "react-google-recaptcha";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import emailjs from "emailjs-com";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const libraries = ["places"];

const Contactpage = () => {
  // Ympäristömuuttujat
  const EMAILJS_USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;
  const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const HOME_ADDRESS = process.env.REACT_APP_HOME_ADDRESS;
  const KILOMETER_RATE = parseFloat(process.env.REACT_APP_KILOMETER_RATE) || 0.30;

  // Tilat
  const [cart, setCart] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [nimi, setNimi] = useState("");
  const [sahkoposti, setSahkoposti] = useState("");
  const [puhelinnumero, setPuhelinnumero] = useState("");
  const [tapahtumapaikka, setTapahtumapaikka] = useState("");
  const [viesti, setViesti] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [formError, setFormError] = useState(null);
  const [distance, setDistance] = useState(null);
  const [travelCost, setTravelCost] = useState(null);
  const [needsTransport, setNeedsTransport] = useState(false);
  const autocompleteRef = useRef(null);

  // Funktio vuokraushinnan laskemiseen
const calculateRentalPrice = (startDate, endDate, pricePerDay, quantity) => {
  // Muunna päivämäärät Date-objekteiksi
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Laske päivien määrä (yksinkertainen tapa)
  const days = Math.ceil((end - start) / (1000 * 60 * 60 * 24)); // Päivät

  // Laske kokonaisvuokrahinta
  const totalPrice = days * pricePerDay * quantity;
  return totalPrice;
};

  // Lasketaan kuljetuskustannukset
  const calculatedTravelCost = needsTransport ? travelCost : "0";

  // Korin yhteenveto
  const cartSummary = cart.map((item) => `${item.name} (x${item.quantity})`).join(", ");

  // Ladataan cart paikallisesta tallennuksesta komponentin latautuessa
  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem("rentalCart")) || [];
    setCart(cartItems);
  }, []);

  // Poistetaan tuote korista
  const handleRemoveFromCart = (index) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
    localStorage.setItem("rentalCart", JSON.stringify(updatedCart));
    window.dispatchEvent(new Event("storage")); // Synkronoi muiden komponenttien kanssa
  };

  // Käsitellään kuljetuksen valinta
  const handleTransportChange = (event) => {
    setNeedsTransport(event.target.value === "yes");
  };

  // Käsitellään Autocomplete-valinta
  const onPlaceChanged = () => {
    if (autocompleteRef.current !== null) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        setTapahtumapaikka(place.formatted_address);
        calculateDistance(place.geometry.location);
      }
    }
  };

  // Laskee matkan kotiosoitteen ja tapahtumapaikan välillä
  const calculateDistance = (destinationLocation) => {
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [HOME_ADDRESS],
        destinations: [destinationLocation],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.METRIC,
      },
      (response, status) => {
        if (status === "OK") {
          const element = response.rows[0].elements[0];
          if (element.status === "OK") {
            const distanceInKm = element.distance.value / 1000;
            setDistance(distanceInKm);

            const cost = distanceInKm * KILOMETER_RATE;
            setTravelCost(cost.toFixed(2));
          } else {
            setFormError("Matkan laskeminen epäonnistui. Yritä uudelleen.");
            setDistance(null);
            setTravelCost(null);
          }
        } else {
          setFormError("Matkan laskeminen epäonnistui. Yritä uudelleen.");
          setDistance(null);
          setTravelCost(null);
        }
      }
    );
  };

  const checkAvailability = async () => {
    // Tarkistetaan, että cart ei ole tyhjä ja sisältää odotetut tiedot
    console.log("Cart data:", cart);  // Tämä näyttää, mitä cart sisältää
    if (!Array.isArray(cart)) {
      console.error("Cart ei ole taulukko.");
      setFormError("Lomake on virheellinen. Yritä uudelleen.");
      return false;
    }
  
    const devices = cart.map((item) => item.name);  // Taulukko laitteiden nimistä
    console.log("Lähetettävät laitteet:", devices);  // Tarkista laitteet ennen lähettämistä
  
    if (devices.length === 0) {
      console.error("Ei valittuja laitteita.");
      setFormError("Valitse laitteet ennen varauksen tekemistä.");
      return false;  // Palataan, jos laitteita ei ole valittu
    }
  
    // Tarkistetaan, että päivämäärät ovat oikeassa formaatissa
    const isValidDate = (date) => !isNaN(Date.parse(date));
    if (!isValidDate(startDate) || !isValidDate(endDate)) {
      console.error("Virheellinen päivämäärämuoto.");
      setFormError("Syötä kelvolliset päivämäärät.");
      return false;
    }
  
    // Varmistetaan, että aloituspäivämäärä ei ole myöhempi kuin lopetuspäivämäärä
    if (new Date(startDate) > new Date(endDate)) {
      console.error("Aloituspäivämäärä on myöhempi kuin lopetuspäivämäärä.");
      setFormError("Aloituspäivämäärä ei voi olla myöhempään kuin lopetuspäivämäärä.");
      return false;
    }
  
    try {
      // Lähetetään laitteet ja päivämäärät palvelimelle
      const response = await fetch("https://spiderlegsproductions.com/api/get_reservations.php");
      
      // Tarkistetaan, että vastaus on onnistunut
      if (!response.ok) {
        throw new Error("API-virhe!");
      }
  
      // Jäsennetään API:n JSON-vastaus
      const result = await response.json();
      console.log("API-vastaus:", result);
  
      if (result.success) {
        // Tarkistetaan päällekkäisyydet
        const existingReservations = result.reservations; // API:n palauttamat varaukset
  
        const isOverlapping = existingReservations.some((reservation) => {
          // Tarkistetaan, onko laite jo varattu samalle ajanjaksolle
          const startOverlap = new Date(startDate) < new Date(reservation.end);
          const endOverlap = new Date(endDate) > new Date(reservation.start);
          return startOverlap && endOverlap && devices.includes(reservation.title);
        });
  
        if (isOverlapping) {
          setFormError("Laitteet eivät ole saatavilla valitulla ajanjaksolla.");
          return false;  // Jos löytyy päällekkäisyys
        }
  
        // Jos ei ole päällekkäisyyksiä, palauta true
        return true;
      } else {
        throw new Error("API ei palauttanut onnistunutta tulosta.");
      }
    } catch (error) {
      console.error("API-virhe:", error);
      setFormError("Laitteiden saatavuuden tarkistus epäonnistui: " + error.message);
      return false;
    }
  };
  
  
// Lähettää varauslomakkeen tiedot
const handleFormSubmit = async (e) => {
  e.preventDefault();
  setFormError(null); // Tyhjennä aiemmat virheet

  // Tarkista reCAPTCHA
  if (!recaptchaValue) {
    toast.error("Varmista, että olet ihminen!");
    return;
  }

  // Jos kuljetus tarvitaan, varmista että matka ja kustannukset on laskettu
  if (needsTransport && (!distance || !travelCost)) {
    toast.error("Matkan laskeminen tapahtumapaikkaan epäonnistui.");
    return;
  }

  // Tarkista laitteiden saatavuus
  const isAvailable = await checkAvailability();
  if (!isAvailable) {
    toast.error("Laite ei ole saatavilla valitulla ajanjaksolla.");
    return; // Lopeta, jos laite ei ole saatavilla
  }

  const formData = {
    nimi,
    sahkoposti,
    puhelinnumero,
    tapahtumapaikka,
    start_date: startDate,
    end_date: endDate,
    device: cartSummary,
    viesti,
  };

  try {
    // Lähetä tiedot tietokantaan
    const response = await fetch("https://spiderlegsproductions.com/api/save_reservation.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    });

    const saveResult = await response.json();

    if (!saveResult.success) {
      toast.error(saveResult.message);  // Näytetään virhe toastina
      return;
    }

    // Valmistele sähköpostin tiedot
    const templateParams = {
      user_name: nimi,
      user_email: sahkoposti,
      user_phone: puhelinnumero,
      date: `${startDate} - ${endDate}`,
      place: tapahtumapaikka,
      total_price: cart.reduce((total, item) => total + item.price * item.quantity, 0),
      user_message: viesti,
      cart_summary: cartSummary,
      distance: needsTransport ? `${distance} km` : "Ei kuljetusta",
      travel_cost: calculatedTravelCost,
      "g-recaptcha-response": recaptchaValue,
    };

    // Lähetä sähköposti EmailJS:n kautta
    const emailResult = await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_TEMPLATE_ID,
      templateParams,
      EMAILJS_USER_ID
    );

    if (emailResult.status === 200) {
      toast.success("Varaus onnistui! Sähköposti lähetetty.");
      // Tyhjennä lomake
      setNimi("");
      setSahkoposti("");
      setPuhelinnumero("");
      setTapahtumapaikka("");
      setViesti("");
      setRecaptchaValue(null);
      // Tyhjennä koritiedot
      setCart([]);
      localStorage.removeItem("rentalCart");
    } else {
      console.error("EmailJS status:", emailResult.status, emailResult.text);
      toast.error("Sähköpostin lähettäminen epäonnistui. Yritä myöhemmin uudelleen.");
    }
  } catch (error) {
    console.error("Virhe lomakkeen käsittelyssä:", error);
    toast.error("Tietojen lähettäminen epäonnistui. Tarkista yhteys tai kokeile uudelleen.");
  }
};



  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={libraries}>
      <div className="w-full mx-auto px-0"> {/* Poistettu container ja asetettu px-0 */}
        <h1 className="font-customFont2 text-shadow-lg text-darkest dark:text-primary text-3xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-center">
          Varauskalenteri
        </h1>

        {/* Poistettu tai kommentoitu tuoteluettelo */}
        {/*
        <div className="mb-8">
          <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-lg sm:text-xl md:text-lg lg:text-2xl xl:text-2xl font-normal mt-4">
            Tällä sivulla näet vuokrauskoriin lisäämäsi paketit tai laitteet. 
            Oikealla oleva varauskori näyttää valitsemasi tuotteet ja niiden kokonaishinnan. 
            Alla olevasta varauskalenterista voit tarkastella, mitkä tuotteet ovat jo varattuja ja milloin. 
            Täyttämällä varauslomakkeen saat vahvistuksen sähköpostiisi, ja varauksesi siirtyy kalenteriin.
          </p>

          <ul>
            {products.map((product, index) => (
              <li key={index} className="flex justify-between items-center mb-2">
                <span>{product.name} - {product.price} € / kpl</span>
                <button
                  onClick={() => handleAddToCart(product)}
                  className="bg-primary text-white px-4 py-2 rounded hover:bg-secondary transition"
                >
                  Lisää korille
                </button>
              </li>
            ))}
          </ul>
        </div>
        */}

        {/* Päägridi: Varauslomake ja Vuokrauskori */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4"> {/* Muutettu gap-8 -> gap-4 */}
          {/* Varauslomake */}
          <form
            onSubmit={handleFormSubmit}
            className="space-y-4 dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 rounded-lg shadow-md p-4 sm:p-6"
          >
            {/* Nimi */}
            <div>
              <label
                htmlFor="name"
                className="block text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2"
              >
                Nimesi:
              </label>
              <input
                id="name"
                type="text"
                placeholder="Etu- ja sukunimi.."
                value={nimi}
                onChange={(e) => setNimi(e.target.value)}
                required
                className="w-full p-3 sm:p-4 rounded-full bg-white border-none focus:outline-none text-base sm:text-lg"
              />
            </div>

            {/* Sähköposti */}
            <div>
              <label
                htmlFor="email"
                className="block text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2"
              >
                Sähköposti:
              </label>
              <input
                id="email"
                type="email"
                placeholder="Syötä sähköpostisi.."
                value={sahkoposti}
                onChange={(e) => setSahkoposti(e.target.value)}
                required
                className="w-full p-3 sm:p-4 rounded-full bg-white border-none focus:outline-none text-base sm:text-lg"
              />
            </div>

            {/* Puhelinnumero */}
            <div>
              <label
                htmlFor="puhelinnumero"
                className="block text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2"
              >
                Puhelinnumero:
              </label>
              <input
                id="puhelinnumero"
                type="tel"
                placeholder="Anna puhelinnumero.."
                value={puhelinnumero}
                onChange={(e) => setPuhelinnumero(e.target.value)}
                required
                className="w-full p-3 sm:p-4 rounded-full bg-white border-none focus:outline-none text-base sm:text-lg"
              />
            </div>

            {/* Tapahtumapaikka */}
            <div>
              <label
                htmlFor="location"
                className="block text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2"
              >
                Tapahtumapaikka:
              </label>
              <Autocomplete
                onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                onPlaceChanged={onPlaceChanged}
              >
                <div className="relative">
                  <input
                    id="location"
                    type="text"
                    placeholder="Etsi.."
                    value={tapahtumapaikka}
                    onChange={(e) => setTapahtumapaikka(e.target.value)}
                    required
                    className="w-full p-3 sm:p-4 rounded-full bg-white border-none focus:outline-none text-base sm:text-lg" /* Poistettu pr-10 */
                  />
                  {/* Poistettu CalendarIcon, jotta käytetään selaimen natiivikuvaketta */}
                  {/* <CalendarIcon className="h-5 w-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none" /> */}
                </div>
              </Autocomplete>
            </div>

            {/* Kuljetusvalinta */}
            <div>
              <label
                htmlFor="needsTransport"
                className="block text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2"
              >
                Tarvitsetko kuljetuksen?
              </label>
              <div className="flex space-x-4">
                {/* Kyllä vaihtoehto */}
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="needsTransport"
                    value="yes"
                    onChange={handleTransportChange}
                    className="form-radio text-blue-500 focus:ring-blue-300"
                  />
                  <span className="text-base sm:text-lg font-customFont1 text-shadow-lg text-darkest dark:text-primary">
                    Kyllä
                  </span>
                </label>

                {/* Ei vaihtoehto */}
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="needsTransport"
                    value="no"
                    onChange={handleTransportChange}
                    className="form-radio text-red-500 focus:ring-red-300"
                  />
                  <span className="text-base sm:text-lg font-customFont1 text-shadow-lg text-darkest dark:text-primary">
                    En
                  </span>
                </label>
              </div>
            </div>

            {/* Päivämäärät */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="relative">
                <label
                  htmlFor="startDate"
                  className="block text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2"
                >
                  Vuokraus alkaa:
                </label>
                <input
                  id="startDate"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                  className="w-full p-2 sm:p-3 rounded-lg bg-white border-none focus:outline-none text-base sm:text-lg" /* Säädetty padding ja reunukset */
                  placeholder="pp.kk.vvvv"
                />
                {/* Poistettu CalendarIcon päivämääräkentistä */}
                {/* <CalendarIcon className="h-5 w-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none" /> */}
              </div>
              <div className="relative">
                <label
                  htmlFor="endDate"
                  className="block text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2"
                >
                  Vuokraus päättyy:
                </label>
                <input
                  id="endDate"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  required
                  className="w-full p-2 sm:p-3 rounded-lg bg-white border-none focus:outline-none text-base sm:text-lg" /* Säädetty padding ja reunukset */
                  placeholder="pp.kk.vvvv"
                />
                {/* Poistettu CalendarIcon päivämääräkentistä */}
                {/* <CalendarIcon className="h-5 w-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none" /> */}
              </div>
            </div>

            {/* Viesti */}
            <div>
              <label
                htmlFor="message"
                className="block text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2"
              >
                Viesti:
              </label>
              <textarea
                id="message"
                placeholder="Kirjoita tähän viestisi.."
                value={viesti}
                onChange={(e) => setViesti(e.target.value)}
                className="w-full h-40 p-3 sm:p-4 rounded-lg bg-white border-none focus:outline-none text-base sm:text-lg"
              />
            </div>

            {/* ReCAPTCHA */}
            <div>
              <ReCAPTCHA
                sitekey="6LcVvMUpAAAAAN1-ERubrqfEf0ykwd_209t02iuo" // Varmista oikea API-avain
                onChange={(value) => setRecaptchaValue(value)} // Tallenna arvo
              />
            </div>

            {/* Virheilmoitus */}
            {formError && (
              <p className="text-lg sm:text-xl font-customFont1 text-shadow-lg text-darkest dark:text-primary font-bold mb-2">
                {formError}
              </p>
            )}

            {/* Lähetä varaus -painike */}
            <button
              type="submit"
              className="h-12 w-full dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-2xl sm:text-3xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer"
            >
              Lähetä varaus
            </button>
          </form>

          {/* Vuokrauskori */}
     
      <div className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 rounded-lg shadow-md mb-8 p-4 sm:p-6">
        <h2 className="font-customFont2 text-shadow-lg text-darkest dark:text-primary text-3xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-center mb-4">
          Vuokrauskori
        </h2>
        <ul>
          {cart.map((item, index) => (
            <li key={index} className="mb-4 flex justify-between items-center">
              <div>
                <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-sm sm:text-lg md:text-lg lg:text-lg xl:text-xl font-normal">
                  {item.name} (x{item.quantity})
                </p>
              </div>
              <div className="flex items-center">
                {/* Lasketaan kokonaishinta, jos startDate ja endDate ovat valittuja */}
                <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-sm sm:text-lg md:text-lg lg:text-lg xl:text-xl font-normal pr-4">
                  {startDate && endDate ? calculateRentalPrice(startDate, endDate, item.price, item.quantity).toFixed(2) : (item.price * item.quantity).toFixed(2)} € {/* Jos päivämäärät valittu, lasketaan hinta */}
                </p>
                <button
                  onClick={() => handleRemoveFromCart(index)}
                  className="h-10 w-16 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-sm sm:text-lg overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in-out items-center rounded-full cursor-pointer"
                >
                  Poista
                </button>
              </div>
            </li>
          ))}
        </ul>
        <p className="font-customFont2 text-shadow-lg text-darkest dark:text-primary text-4xl sm:text-5xl lg:text-5xl xl:text-6xl font-bold text-center">
          Yhteensä: {cart.reduce((total, item) => {
            if (startDate && endDate) {
              return total + calculateRentalPrice(startDate, endDate, item.price, item.quantity);
            } else {
              return total + item.price * item.quantity;
            }
          }, 0).toFixed(2)} €
        </p>
      </div>


        {/* Varauskalenteri */}
        <div className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 rounded-lg shadow-md mb-8 p-4 sm:p-6">
          <BookingCalendar />
        </div>

       
      </div>
      <Footer />
      </div>
    </LoadScript>
  );
};

export default Contactpage;
