import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TextToSpeech from "../../TextToSpeech/textToSpeech";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importoi AOS CSS

const MostPopular = () => {
  const [mostPopular, setMostPopular] = useState([]);
  const text =
    "Vuokratuimmat. Tutustu suosituimpiin ja vuokratuimpiin laitteisiimme. Näiden laadukkaiden tuotteiden avulla varmistat onnistuneen tapahtuman, olipa kyseessä bileet, teemabileet tai muu erityistilaisuus. Katso kaikki vuokrattavat klikkaamalla katso kaikki nappia.";

  // Funktio suosituimpien tuotteiden hakemiseen
  const fetchMostPopular = async () => {
    try {
      const devicesResponse = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php");
      if (!devicesResponse.ok) {
        throw new Error(`Laitteiden haku epäonnistui: ${devicesResponse.status}`);
      }
      const devicesData = await devicesResponse.json();
      console.log("Devices Data:", devicesData);

      if (devicesData.success && devicesData.categories) {
        const allDevices = [
          ...devicesData.categories["äänentoisto"],
          ...devicesData.categories["esitystekniikka"],
          ...devicesData.categories["muut"],
        ];

        const sortedItems = allDevices
          .filter((device) => device.rentals > 0) // Suodata laitteet, joilla on vuokrauskertoja
          .sort((a, b) => b.rentals - a.rentals); // Järjestä laskevasti

        if (sortedItems.length > 0) {
          setMostPopular(sortedItems);
         // toast.success("Suosituimmat tuotteet ladattu onnistuneesti!");
        } else {
          console.warn("Ei löytynyt suosituimpia tuotteita.");
          toast.warn("Ei suosituimpia tuotteita näytettäväksi.");
        }
      } else {
        throw new Error("API:n vastausrakenne ei ole odotettu.");
      }
    } catch (error) {
      console.error("Tietojen haku epäonnistui:", error);
      toast.error("Tietojen haku epäonnistui. Yritä myöhemmin uudelleen.");
    }
  };

  // Funktio tuotteen lisäämiseen ostoskoriin
  const addToCart = (item) => {
    try {
      // Hae nykyinen ostoskori localStoragesta tai alusta tyhjäksi
      const cart = JSON.parse(localStorage.getItem("rentalCart")) || [];
      const existingItem = cart.find((cartItem) => cartItem.id === item.id);

      if (existingItem) {
        // Tarkista, ettei lisäys ylitä saatavilla olevaa määrää
        if (existingItem.quantity >= item.available) {
          toast.warn(`Tuotetta ${item.name} ei voi lisätä enempää koriin, varastossa ${item.available} kpl.`);
          return;
        }
        existingItem.quantity += 1;
      } else {
        cart.push({ ...item, quantity: 1 });
      }

      // Tallenna päivitetty ostoskori localStorageen
      localStorage.setItem("rentalCart", JSON.stringify(cart));
      toast.success(`${item.name} lisätty vuokrauskoriin!`);
    } catch (error) {
      console.error("Tuotteen lisääminen koriin epäonnistui:", error);
      toast.error("Tuotteen lisääminen koriin epäonnistui. Yritä myöhemmin.");
    }
  };

  useEffect(() => {
    // Alusta AOS
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: true,
    });

    fetchMostPopular();

    // Aseta polling: hae tiedot joka 10 sekunti
    const interval = setInterval(() => {
      fetchMostPopular();
    }, 10000); // 10000 ms = 10 sekuntia

    // Puhdista interval kun komponentti poistuu
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      data-aos="fade-right"
      className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 pb-4 pl-5 pr-5 rounded-3xl cursor-pointer p-5"
    >
      <div className="container pb-10 pl-5 pr-5">
        {/* Sivun otsikko */}
        <div className="flex flex-row items-center">
          <h2
            className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight"
            aria-label="Vuokratuimmat laitteet"
          >
            VUOKRATUIMMAT
          </h2>
          <div className="speech-title ml-5 flex justify-center">
            <TextToSpeech text={text} />
          </div>
        </div>

        {/* Sivun kuvaus */}
        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal pb-10">
          Tutustu suosituimpiin ja vuokratuimpiin laitteisiimme. Näiden laadukkaiden tuotteiden avulla varmistat onnistuneen tapahtuman, olipa kyseessä bileet, teemabileet tai muu erityistilaisuus.
        </p>

        {/* Lista vuokrattavista */}
        <div>
          <div className="grid grid-cols-1 xxsm:grid-cols-2 xsm:grid-cols-2 bv9900:grid-cols-2 sm:grid-cols-2 md:grid-cols-2 mdd:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-4">
            {mostPopular.map((item) => (
              <div
                key={item.id}
                data-aos="fade-up"
                className="bg-white bg-opacity-50 hover:bg-lightbg hover:bg-opacity-60 rounded-3xl relative group p-5 flex flex-col justify-between"
              >
                <TextToSpeech text={item.name || "Lisätietoja ei ole saatavilla"} />
                {/* Sisääntulo efekti */}
                <div className="w-full h-[120px]">
                  <Link
                    to={item.link || "#"}
                    title={item.name}
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                    }}
                    aria-label={item.name}
                  >
                    <img
                      src={item.image || "https://via.placeholder.com/150"} // Placeholder-kuva
                      alt={item.name}
                      loading="lazy"
                      title={item.name}
                      className="w-full h-[150px] object-contain sm:translate-x-0 transition duration-700 cursor-pointer drop-shadow-[3px_3px_5px_rgba(0,0,0,0.80)]"
                    />
                  </Link>
                </div>
                <div className="flex flex-col justify-between flex-grow">
                  <div className="grid grid-cols-1 place-items-center pt-10">
                    {/* Laitteen kuvauksen teksti */}
                    <h3
                      className="font-customFont1 text-shadow-lg text-center text-darkest dark:text-primary xxxsm:text-sm xxsm:text-base xsm:text-lg sm:text-xl md:text-2xl mdd:text-1xl lg:text-2xl xl:text-3xl font-normal pb-5"
                      title={item.name}
                    >
                      {item.name}
                    </h3>
                  </div>
                  <div className="mt-auto text-center">
                    <div className="font-customFont1 text-darkest dark:text-primary text-shadow-lg font-normal pb-2">
                      <p className="specs font-customFont1 text-darkest dark:text-primary text-shadow-lg xxxsm:text-base xxsm:text-lg xsm:text-xl sm:text-2xl md:text-3xl mdd:text-2xl lg:text-4xl xl:text-4xl font-normal">
                        {item.price ? `${item.price} € / 24h` : "Hinta ei saatavilla"}
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <Link
                        to={item.link || "#"}
                        title="Katso lisätiedot"
                        onClick={() => {
                          if (item.link) {
                            window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                          }
                        }}
                        aria-label={`Katso lisätiedot ${item.name}`}
                      >
                        <button
                          className="h-12 w-28 dark:bg-violet bg-lightbg dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out opacity-75"
                          title="Katso lisätiedot"
                        >
                          Lisätiedot
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* Lisää "Lisää koriin" -nappi */}
                <div className="grid place-content-center mt-4">
                  <button
                    title="Lisää vuokrauskoriin"
                    onClick={() =>
                      addToCart({
                        id: item.id,
                        name: item.name,
                        price: item.price,
                        available: item.available,
                      })
                    }
                    className="h-12 w-32 sm:w-32 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none"
                    style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                  >
                    Lisää koriin
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="grid place-content-center mt-10">
            <Link
              to="/palvelut"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "instant" });
              }}
              aria-label="Katso kaikki vuokrattavat laitteet"
            >
              <button
                title="Katso kaikki!"
                className="h-12 w-52 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-2xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer"
                style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
              >
                Katso kaikki!
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostPopular;
