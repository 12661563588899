import { Helmet } from 'react-helmet-async';

function ADJVolcanoHelmetComponent() {
  return (
    <Helmet>
      {/* Page title */}
      <title>Spiderlegs productions | Savukone ADJ VF Volcano</title>
      
      {/* Meta description */}
      <meta name="description" content="Savukone (ADJ VF Volcano) on vertikaalisesti savua ampuva näyttävä tuprutin. RGB-valot luovat vaikuttavia erivärisiä savupylväitä." />
      
      {/* Meta keywords */}
      <meta name="keywords" content="savukone, adj, vf, volcano, led, rgb, pystysuora" />
      
      {/* Meta author */}
      <meta name="author" content="Arttu Stenius" />
      
      {/* Canonical link */}
      <link rel='canonical' href='https://spiderlegsproductions.com/savukone-adj-volcano' />
      
      {/* Open Graph tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Spiderlegs productions | Savukone ADJ VF Volcano" />
      <meta property="og:description" content="Savukone (ADJ VF Volcano) on vertikaalisesti savua ampuva näyttävä tuprutin. RGB-valot luovat vaikuttavia erivärisiä savupylväitä." />
      <meta property="og:image" content="https://spiderlegsproductions.com/assets/ADJ_VF_Volcano1.webp" />
      <meta property="og:url" content="https://spiderlegsproductions.com/savukone-adj-volcano" />
      
      {/* Twitter Card tags */}
      <meta name="twitter:title" content="Spiderlegs productions | Savukone ADJ VF Volcano" />
      <meta name="twitter:description" content="Savukone (ADJ VF Volcano) on vertikaalisesti savua ampuva näyttävä tuprutin. RGB-valot luovat vaikuttavia erivärisiä savupylväitä." />
      <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/ADJ_VF_Volcano1.webp" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default ADJVolcanoHelmetComponent;
