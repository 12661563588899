import ADJ from '../../assets/ADJ_logo.webp';
import JBL from '../../assets/JBL_logo.webp';
import MACKIE from '../../assets/Mackie_logo2.webp';
import PRESONUS from '../../assets/Presonus_logo.webp';
import DBLOGO from '../../assets/db_logo.webp';
import CHAUVET from '../../assets/chauvet_logo.webp';
import TextToSpeech from '../../TextToSpeech/textToSpeech';

const text =
  "Tuotemerkit. Takaamme tapahtumiesi laadun ja unohtumattoman tunnelman käyttämällä alan huippumerkkejä. Luotamme vain parhaisiin tuotemerkkeihin, jotka takaavat korkean laadun ja saumattoman suorituskyvyn jokaisessa tilaisuudessa. Tuotemerkit listattuna. PreSonus, ADJ, Chauvet, JBL, Mackie ja db technologies.";

const Experience = () => {
  return (
    <div className="container p-5 dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 mb-10" aria-labelledby="tuotemerkit-otsikko">
      <h2 id="tuotemerkit-otsikko" className="sr-only">Tuotemerkit</h2>
      
      <div data-aos="fade-in" loading="lazy" title="Näitä merkkejä käytämme">
        {/* Puhepainike */}
        <div className="flex flex-row" aria-labelledby="tuotemerkit-puhe">
          <h3 id="tuotemerkit-puhe" className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight">
            Tuotemerkit
          </h3>
          <div className='speech-title ml-5 flex justify-center'>
            <TextToSpeech text={text} />
          </div>
        </div>

        <p className="mt-5 mb-5 font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-light">
          Takaamme tapahtumiesi laadun ja unohtumattoman tunnelman käyttämällä alan huippumerkkejä. Luotamme vain parhaisiin tuotemerkkeihin, jotka takaavat korkean laadun ja saumattoman suorituskyvyn jokaisessa tilaisuudessa.
        </p>

        {/* Logo-osio */}
        <div className="flex gap-4 justify-center pb-5" aria-labelledby="merkkilogot">
          <h3 id="merkkilogot" className="sr-only">Käyttämämme merkkilogot</h3>
          <div className="logo-wrapper bg-dark/50 p-2 hover:border-solid hover:scale-110 transition duration-700 dark:hover:border-2 dark:border-primary hover:border-2 border-darkest">
            <a href="https://www.presonus.com/en/start" target="_blank" rel="noopener noreferrer" aria-label="Vieraile PreSonus:n sivuilla" title="Vieraile PreSonus:n sivuilla">
              <img src={PRESONUS} alt="PreSonus logo" loading="lazy" className="logo" />
            </a>
          </div>
          <div className="logo-wrapper bg-dark/50 p-2 hover:border-solid hover:scale-110 transition duration-700 dark:hover:border-2 dark:border-primary hover:border-2 border-darkest">
            <a href="https://www.adj.eu/" target="_blank" rel="noopener noreferrer" aria-label="Vieraile ADJ:n sivuilla" title="Vieraile ADJ:n sivuilla">
              <img src={ADJ} alt="ADJ logo" loading="lazy" className="logo" />
            </a>
          </div>
          <div className="logo-wrapper bg-dark/50 p-2 hover:border-solid hover:scale-110 transition duration-700 dark:hover:border-2 dark:border-primary hover:border-2 border-darkest">
            <a href="https://www.chauvetprofessional.com/" target="_blank" rel="noopener noreferrer" aria-label="Vieraile Chauvet:n sivuilla" title="Vieraile Chauvet:n sivuilla">
              <img src={CHAUVET} alt="Chauvet logo" loading="lazy" className="logo" />
            </a>
          </div>
          <div className="logo-wrapper bg-dark/50 p-2 hover:border-solid hover:scale-110 transition duration-700 dark:hover:border-2 dark:border-primary hover:border-2 border-darkest">
            <a href="https://fi.jbl.com/" target="_blank" rel="noopener noreferrer" aria-label="Vieraile JBL:n sivuilla" title="Vieraile JBL:n sivuilla">
              <img src={JBL} alt="JBL logo" loading="lazy" className="logo" />
            </a>
          </div>
          <div className="logo-wrapper bg-dark/50 p-2 hover:border-solid hover:scale-110 transition duration-700 dark:hover:border-2 dark:border-primary hover:border-2 border-darkest">
            <a href="https://mackie.com/" target="_blank" rel="noopener noreferrer" aria-label="Vieraile Mackie:n sivuilla" title="Vieraile Mackie:n sivuilla">
              <img src={MACKIE} alt="Mackie logo" loading="lazy" className="logo" />
            </a>
          </div>
          <div className="logo-wrapper bg-dark/50 p-2 hover:border-solid hover:scale-110 transition duration-700 dark:hover:border-2 dark:border-primary hover:border-2 border-darkest">
            <a href="https://www.dbtechnologies.com/en/home/" target="_blank" rel="noopener noreferrer" aria-label="Vieraile dB:n sivuilla" title="Vieraile dB:n sivuilla">
              <img src={DBLOGO} alt="dB Technologies logo" loading="lazy" className="logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
