import { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

const Counter = ({ end, label }) => {
  const [count, setCount] = useState(0);
  const [hasStarted, setHasStarted] = useState(false);
  const counterRef = useRef(null);

  const observeCounter = useCallback((node) => {
    if (node !== null) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !hasStarted) {
            setHasStarted(true);
          }
        },
        { threshold: 0.1 }
      );
      observer.observe(node);

      return () => observer.disconnect();
    }
  }, [hasStarted]);

  useEffect(() => {
    observeCounter(counterRef.current);
  }, [observeCounter]);

  useEffect(() => {
    if (hasStarted) {
      let start = 0;
      const duration = 2000; // Duration in milliseconds (2 seconds)
      const incrementTime = Math.abs(Math.floor(duration / end));

      const timer = setInterval(() => {
        start += 1;
        setCount(start);
        if (start === end) clearInterval(timer);
      }, incrementTime);

      return () => clearInterval(timer);
    }
  }, [hasStarted, end]);

  return (
    <div className="text-center mb-4" ref={counterRef}>
      <h3 className="text-5xl font-bold text-darkest dark:text-primary">{count}</h3>
      <p className="text-lg text-gray-600 dark:text-gray-300">{label}</p>
    </div>
  );
};

// Define prop types
Counter.propTypes = {
  end: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

export default Counter;
