import { Helmet } from 'react-helmet-async';

function FujifilmInstaxMini12HelmetComponent() {
  return (
    <div>
      <Helmet>
        {/* Page title */}
        <title>Spiderlegs productions | Fujifilm Instax Mini-12</title>
        
        {/* Meta description */}
        <meta name="description" content="Vuokraa Fujifilm Instax Mini-12 ja ikuista unohtumattomat hetket. Sopii täydellisesti juhliin ja tapahtumiin. Valitse sopiva paketti ja varmista tilaisuutesi menestys." />
        
        {/* Meta keywords */}
        <meta name="keywords" content="Fujifilm, Instax, Mini-12, kamera, polaroid, vuokraus, juhlat, valokuvaus, instant camera" />
        
        {/* Meta author */}
        <meta name="author" content="Arttu Stenius" />
        
        {/* Canonical link */}
        <link rel='canonical' href='https://spiderlegsproductions.com/fujifilm-instax-mini-12' />
        
        {/* Open Graph tags */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Spiderlegs productions | Fujifilm Instax Mini-12" />
        <meta property="og:description" content="Vuokraa Fujifilm Instax Mini-12 ja ikuista unohtumattomat hetket. Sopii täydellisesti juhliin ja tapahtumiin. Valitse sopiva paketti ja varmista tilaisuutesi menestys." />
        <meta property="og:image" content="https://spiderlegsproductions.com/assets/fujifilm_instax_mini_12.webp" />
        <meta property="og:url" content="https://spiderlegsproductions.com/fujifilm-instax-mini-12" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:title" content="Spiderlegs productions | Fujifilm Instax Mini-12" />
        <meta name="twitter:description" content="Vuokraa Fujifilm Instax Mini-12 ja ikuista unohtumattomat hetket. Sopii täydellisesti juhliin ja tapahtumiin. Valitse sopiva paketti ja varmista tilaisuutesi menestys." />
        <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/fujifilm_instax_mini_12.webp" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    </div>
  );
}

export default FujifilmInstaxMini12HelmetComponent;
