import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion"; // Framer Motion -kirjaston käyttö

const MultiLayerParallax: React.FC = () => {
    const ref = useRef<HTMLDivElement>(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start start", "end start"],
    });

    // Tekstin liike alas päin
    const textY = useTransform(scrollYProgress, [0, 1], ["20%", "300%"]);

    // Laitteiden liike ylöspäin ja fade-efekti (nopeampi)
    const deviceY = useTransform(scrollYProgress, [0, 1], ["0%", "-200%"]);
    const deviceOpacity = useTransform(scrollYProgress, [0, 0, 0.4], [1, 1, 0]); // Fade-efekti

    // Logon liike vasemmalle päin ja fade-efekti (nopeampi)
    const logoX = useTransform(scrollYProgress, [0, 1], ["0%", "-100%"]);
    const logoOpacity = useTransform(scrollYProgress, [0, 0.2, 1], [1, 1, 0]); // Fade-efekti

    return (
        <div ref={ref} className="parallax place-items-center md:object-scale-down w-full h-[600px] sm:translate-x-0 transition duration-700">
            <motion.h1 style={{ y: textY }} className="parallax-text pb-25 font-customFont2 text-lightbg dark:text-lightbg text-7xl xxxsm:text-6xl sm:text-7xl md:text-7xl lg:text-7xl relative">
                Palvelut
            </motion.h1>

            <div className="smoke absolute inset-0" /> {/* Pysyvä smoke-kerros */}
            <div 
                className="lattia absolute inset-0" 
                style={{ boxShadow: "0px 13px 16px -1px rgba(0,0,0,0.75)" }} 
            /> {/* Pysyvä lattia-kerros */}

            <motion.div 
                className="laitteet absolute inset-0"
                style={{ y: deviceY, opacity: deviceOpacity }} // Laitteiden ylöspäin liike ja fade-efekti
            />

            <motion.div 
                className="rental-logo absolute inset-0"
                style={{ y: deviceY, x: logoX, opacity: logoOpacity }} // Logon vasemmalle liike ja fade-efekti
            />
        
            <div className="seinä absolute inset-0" /> {/* Pysyvä seinä-kerros */}
        </div>
    );
}

export default MultiLayerParallax;
