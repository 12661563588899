import Footer from '../../components/Footer/Footer';
import DB600W from '../../components/DB600W/DB600W';
import DB600HelmetComponent from './DB600HelmetComponent';

export default function dB600W() {
    return (
        <>
            <DB600HelmetComponent />
            <DB600W />
            <Footer />
        </>
    );
}

