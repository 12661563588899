import Footer from '../../components/Footer/Footer';
import ADJStinger2 from '../../components/ADJ-Stinger2/ADJ-Stinger2';
import ADJStinger2HelmetComponent from './ADJ-Stinger2HelmetComponent'; // Lisätty Helmet-komponentti

export default function ADJStinger2Page() {
    return (
        <>
            <ADJStinger2HelmetComponent /> {/* Lisätty Helmet-komponentti */}
            <ADJStinger2 />
            <Footer />
        </>
    );
}
