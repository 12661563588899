import Footer from '../../components/Footer/Footer';
import LEDLights from '../../components/LED-Lights/LED-Lights';
import LEDlightsHelmetComponent from './LED-lightsHelmetComponent'; // Lisätään Helmet-komponentti

export default function lEDLights() {
    return (
        <>
            <LEDlightsHelmetComponent /> {/* Lisätään Helmet-komponentti */}
            <LEDLights />
            <Footer />
        </>
    );
}
