// src/Pages/SoundcraftFX16/SoundcraftFX16.jsx
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import TextToSpeech from '../../TextToSpeech/textToSpeech';
import { toast } from 'react-toastify'; // Poista ToastContainer tästä
import 'react-toastify/dist/ReactToastify.css';

import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton 
} from 'react-share';

import { 
    FacebookIcon,
    TwitterIcon, // Korjattu ikoni
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

// Import images with unique variable names to avoid conflict
import SoundcraftLogo from '../../assets/soundcraft_logo.webp';
import SoundcraftFX16Front from '../../assets/Soundcraft Spirit FX 16.webp';
import SoundcraftFX16Back from '../../assets/Soundcraft Spirit FX 16 back.webp';

const SoundcraftFX16 = () => {
    const [available, setAvailable] = useState(0); // Alkuperäinen varasto
    const productId = "6"; // ID Mikserille
    const [activeImg, setActiveImage] = useState(SoundcraftFX16Front);

    // Haetaan tuotteiden saatavuus backendistä
    useEffect(() => {
        const fetchAvailability = async () => {
            try {
                const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php");
                const data = await response.json();

                console.log("API response data:", data);

                // Yhdistetään kaikki kategoriat yhteen taulukkoon
                const inventory = Object.values(data.categories).flat();

                console.log("Yhdistetty inventory:", inventory);

                // Etsi tuote ID:n perusteella
                const currentProduct = inventory.find(item => item.id === productId);

                if (currentProduct) {
                    setAvailable(currentProduct.available);
                    console.log("Nykyisen tuotteen kappalemäärä:", currentProduct.available);
                } else {
                    console.error(`Tuotetta ei löytynyt ID:llä ${productId}!`);
                    toast.error("Tuotteen saatavuutta ei voitu hakea. Yritä myöhemmin uudelleen.", { position: "top-right" });
                }
            } catch (error) {
                console.error("Virhe haettaessa API-dataa:", error);
                toast.error("Virhe tuotteen saatavuuden haussa. Yritä myöhemmin uudelleen.", { position: "top-right" });
            }
        };

        fetchAvailability();
    }, [productId]);

    const handleImageClick = (image) => {
        setActiveImage(image);
    };

    const addToCart = async () => {
        try {
            // Hae varastotilanne uudelleen varmistaaksesi tuoreimman tiedon
            const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php");
            const data = await response.json();

            // Yhdistetään kaikki kategoriat yhteen taulukkoon
            const inventory = Object.values(data.categories).flat();

            const productStock = inventory.find(item => item.id === productId)?.available || 0;

            const quantity = 1; // Kiinteä määrä, koska valintaa ei ole

            if (quantity > productStock) {
                toast.error("Tuotetta ei voi lisätä vuokrauskoriin enemmän kuin mitä varastossa on!", { position: "top-right" });
                return;
            }

            // Hae vuokrauskori localStoragesta
            const cart = JSON.parse(localStorage.getItem("rentalCart")) || [];
            const product = {
                id: productId,
                name: "Mikseri Soundcraft FX 16",
                price: 50, // Vuorokausihinta (€ per päivä)
                quantity: quantity,
            };

            // Tarkista, onko tuote jo korissa
            const existingProduct = cart.find((item) => item.id === product.id);
            const newQuantity = existingProduct ? existingProduct.quantity + quantity : quantity;

            // Estä lisäämästä enemmän kuin varastossa
            if (newQuantity > productStock) {
                toast.error("Tuotetta ei voi lisätä vuokrauskoriin enemmän kuin mitä varastossa on!", { position: "top-right" });
                return;
            }

            // Lisää tuote joko uutena tai päivitä määrä
            if (existingProduct) {
                existingProduct.quantity = newQuantity;
            } else {
                cart.push(product);
            }

            // Päivitä vuokrauskori localStorageen
            localStorage.setItem("rentalCart", JSON.stringify(cart));

            // Luo "storage"-tapahtuma, jotta navbar päivittyy
            window.dispatchEvent(new Event("storage"));

            // Päivitä saatavuus
            setAvailable(prevAvailable => prevAvailable - quantity);

            // Ilmoita käyttäjälle lisäyksestä
            toast.success(`${quantity} x ${product.name} lisätty vuokrauskoriin!`, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } catch (error) {
            console.error("Virhe tuotteen lisäämisessä vuokrauskoriin:", error);
            toast.error("Virhe tuotteen lisäämisessä vuokrauskoriin. Yritä myöhemmin uudelleen.", { position: "top-right" });
        }
    };

    // Tekstit
    const text =
        "Mikseri Soundcraft FX 16. Studiolaatuinen ääni, olipa kyseessä live-esitys tai studiotyöskentely, Soundcraft Spirit FX16 tarjoaa erinomaisen äänenlaadun ja monipuoliset ominaisuudet, jotka tekevät tapahtumistasi unohtumattomia.";

    const text1 =
        "Ominaisuudet. Monipuolinen analoginen 4-väyläinen mikseri, joka tuottaa mainion äänenlaadun sekä live-esityksissä että studiokäytössä. Se tarjoaa laajan valikoiman ominaisuuksia, jotka tekevät musiikin teosta, miksaamisesta ja äänityksestä entistä nautinnollisempaa ja helpompaa.";

    const text2 =
        "Lisätietoja. Kuusitoista mikrofoniliitäntätuloa ja yhteensä 26 sisääntuloa. Voit miksata samanaikaisesti useita instrumentteja ja muita äänilähteitä. Kuusi suoraa ulostuloa. Tallenna ääni moniraitoihin studiossa tai lähetä sitä suoraan PA-järjestelmään keikoilla. Lexiconin tehosteprosessorin 16 ohjelmaa. Lisää ammattimaisia efektejä kahdella samanaikaisella efektillä. Brittiläinen kolmi-alueinen taajuuskorjain. Muokkaa ääntä tarkasti ja luo haluamasi soundi. Neljä apukanavavahvistusta. Lähetä ääntä monitorointiin, efekteihin tai muihin laitteisiin. Räkkiasennettu.";

    const text3 =
        "Hinta. Alkaen viisikymmentä euroa vuorokausi. Tiedustele saatavuutta painamalla alla olevaa nappia.";

    return (
        <>
            {/* Takaisin-painike */}
            <button 
                onClick={() => { window.history.back(); }} 
                className="h-12 w-32 dark:bg-violet bg-primary dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out focus:outline-none"
                aria-label="Palaa edelliselle sivulle"
            >
                Takaisin
            </button>
               
            {/* Tuotteen kuvat */}
            <div className='flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5'>

                <div className='flex flex-col gap-5'>

                    <img 
                        src={activeImg} 
                        title="Soundcraft FX 16 mikseri" 
                        alt='Soundcraft FX 16 mikseri' 
                        className='w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]' 
                    />

                    <div className='flex flex-row justify-center h-24 drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'>

                        {/* Klikattavat kuvat korvattu button-elementeillä */}
                        <button 
                            onClick={() => handleImageClick(SoundcraftFX16Front)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="Näytä Soundcraft Spirit FX 16 etupuoli"
                        >
                            <img 
                                src={SoundcraftFX16Front} 
                                alt="Soundcraft Spirit FX 16 etupuoli" 
                                title="Soundcraft Spirit FX 16 etupuoli" 
                                className='w-full h-full object-cover rounded-md' 
                            />
                        </button>

                        <button 
                            onClick={() => handleImageClick(SoundcraftFX16Back)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="Näytä Soundcraft Spirit FX 16 takapuoli"
                        >
                            <img 
                                src={SoundcraftFX16Back} 
                                alt="Soundcraft Spirit FX 16 takapuoli" 
                                title="Soundcraft Spirit FX 16 takapuoli" 
                                className='w-full h-full object-cover rounded-md' 
                            />
                        </button>

                    </div>

                </div>

                {/* Tuotteen kuvaus */}
                <div className='dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl'>

                    <div>

                        {/* SPEECH */}

                        <div className="flex flex-row items-center">
                            {/* Otsikko */}
                            <h1 className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none">
                                Mikseri Soundcraft FX 16
                            </h1>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text} />
                            </div> 
                        </div>

                        <br />
                        
                        {/* Teksti */}

                        <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>
                            Studiolaatuinen ääni, olipa kyseessä live-esitys tai studiotyöskentely - Soundcraft Spirit FX16:lla!
                        </p>
                        <br />

                        {/* SPEECH */}

                        <div className="flex flex-row items-center">
                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>
                                Ominaisuudet
                            </p>
                            <div className='speech-title ml-5'>
                                <TextToSpeech text={text1} />
                            </div>
                        </div>

                        <br />
                        
                        <p className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl font-light'>
                            Monipuolinen analoginen 4-väyläinen mikseri, joka tuottaa <strong>mainion äänenlaadun</strong> sekä live-esityksissä että studiokäytössä. Se tarjoaa laajan valikoiman ominaisuuksia, jotka tekevät musiikin teosta, miksaamisesta ja äänityksestä entistä nautinnollisempaa ja helpompaa.
                        </p>

                        <br />

                        {/* SPEECH */}

                        <div className="flex flex-row items-center">
                            <h2 className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl font-bold'>
                                Lisätietoja:
                            </h2>
                            <div className='speech-title ml-5'>
                                <TextToSpeech text={text2} />
                            </div>
                        </div>

                        <ul className="ominaisuudet list-disc font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl font-light p-4">
                            <li><strong>16 mikrofoniliitäntätuloa ja yhteensä 26 sisääntuloa:</strong> Voit miksata samanaikaisesti useita instrumentteja ja muita äänilähteitä.</li>
                            <li><strong>6 suoraa ulostuloa:</strong> Tallenna ääni moniraitoihin studiossa tai lähetä sitä suoraan PA-järjestelmään keikoilla.</li>
                            <li><strong>Lexiconin tehosteprosessorin 16 ohjelmaa:</strong> Lisää ammattimaisia efektejä kahdella samanaikaisella efektillä.</li>
                            <li><strong>Brittiläinen 3-alueinen taajuuskorjain:</strong> Muokkaa ääntä tarkasti ja luo haluamasi soundi.</li>
                            <li><strong>Neljä apukanavavahvistusta:</strong> Lähetä ääntä monitorointiin, efekteihin tai muihin laitteisiin.</li>
                            <li>Räkkiasennettu</li>
                        </ul>

                        {/* Näytä saatavuus */}
                        <div className='mt-5'>
                            <p className="text-lg font-medium text-darkest dark:text-primary">
                                Saatavilla: {available} kpl
                            </p>
                        </div>

                        {/* Jaa-sivulle -painikkeet */}
                        <div className="flex flex-row py-5 gap-2" title="Jaa tämä sivu">

                            <FacebookShareButton
                                url={"https://spiderlegsproductions.com/mikseri-soundcraft-fx16"}
                                className="facebook-share-button flex flex-row items-center">
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={"https://spiderlegsproductions.com/mikseri-soundcraft-fx16"}
                                className="twitter-share-button flex flex-row items-center ">
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>

                            <WhatsappShareButton
                                url={"https://spiderlegsproductions.com/mikseri-soundcraft-fx16"}
                                className="whatsapp-share-button flex flex-row items-center ">
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>

                            <LinkedinShareButton
                                url={"https://spiderlegsproductions.com/mikseri-soundcraft-fx16"}
                                className="linkedin-share-button flex flex-row items-center ">
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>

                            <EmailShareButton
                                url={"https://spiderlegsproductions.com/mikseri-soundcraft-fx16"}
                                className="email-share-button flex flex-row items-center ">
                                <EmailIcon size={32} round />
                            </EmailShareButton>

                        </div>

                        {/* SPEECH */}
                        <div className="flex flex-row items-center">
                            {/* Hinta */}
                            <h2 
                                title="Hinta" 
                                className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold'
                            >
                                Alk. 50€ / vrk
                            </h2>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text3} />
                            </div>
                        </div>

                       {/* Lisää vuokrauskoriin ja siirry varaukseen -painikkeet */}
                        <div className="flex flex-col sm:flex-row items-center gap-4 pt-5">
                            <button
                                title="Lisää vuokrauskoriin"
                                onClick={addToCart}
                                className={`h-12 w-full sm:w-60 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none ${
                                    available === 0 ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                                style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                disabled={available === 0}
                            >
                                Lisää vuokrauskoriin
                            </button>

                            <Link 
                                to="/varauskalenteri" 
                                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                                className="w-full sm:w-60"
                            >
                                <button
                                    title="Siirry varaamaan"
                                    className="h-12 w-full dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none"
                                    style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                >
                                    Siirry varauskalenteriin
                                </button>
                            </Link>
                        </div>

                        {/* Tuotteen logo */}
                        <div className='flex flex-wrap place-items-start pt-5'>

                            <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">

                                {/* Tuotteen logo */}

                                <a href="https://soundcraft.com/" aria-label="Vieraile Soundcraftin sivuilla.">
                                    <img 
                                        src={SoundcraftLogo}  
                                        alt="Soundcraft logo" 
                                        loading="lazy" 
                                        title="Vieraile Soundcraftin sivuilla." 
                                        className='w-full h-full object-contain rounded-xl' 
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SoundcraftFX16;
