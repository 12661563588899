import TextToSpeech from '../../TextToSpeech/textToSpeech';
import Counter from '../../components/Counter/Counter';
import ArttuImage from '../../assets/Arttu.webp';
import { FaLinkedin, FaInstagram, FaFacebook, FaTiktok } from "react-icons/fa";

const teamMembers = [
  {
    name: "Arttu Stenius",
    role: "Yrittäjä",
    image: ArttuImage,
    description: (
      <>
        Hei, olen Arttu, Spiderlegs Productionsin perustajajäsen. Minulla on laaja kokemus tapahtumien suunnittelusta ja toteutuksesta, erityisesti äänentoiston ja esitystekniikan saralla. <br /><br />
        Spiderlegs Productions on erikoistunut tarjoamaan huippuluokan äänentoisto- ja esitystekniikkapalveluita, jotka tekevät tilaisuuksistasi sekä äänimaailmaltaan että visuaalisesti vaikuttavia. <br /><br />
        Tavoitteenani on tehdä vuokrausprosessistasi mahdollisimman sujuva ja vaivaton. Olen tukenasi jokaisessa vaiheessa varmistaen, että kaikki sujuu saumattomasti ja että tilaisuutesi tekniset tarpeet täyttyvät täydellisesti.
      </>
    )
  },
];


const Team = () => {
  return (
    <div className="team-section py-10">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">

        {/* Ensimmäinen laatikko (Sinua palvelee) */}
        <div className="team-member font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl p-8 shadow-lg dark:bg-violet bg-primary dark:bg-opacity-90 bg-opacity-90 flex flex-col justify-between h-full">
          <div>
            <h2 className="text-center font-customFont2 text-shadow-lg text-darkest dark:text-primary text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight">
              Sinua palvelee
            </h2>
            <img
              src={teamMembers[0].image}
              alt={`${teamMembers[0].name} kuva`}
              className="rounded-full w-60 h-60 mx-auto mb-6 object-cover"
            />
            <h3 className="text-2xl font-bold text-darkest dark:text-primary text-left">
              {teamMembers[0].name}
            </h3>
            <p className="text-lg text-left">
              {teamMembers[0].role}
            </p>
            <p className="mt-4 text-lg text-darkest dark:text-primary text-left">
              {teamMembers[0].description}
            </p>
            <div className="mt-4 flex justify-center">
              <TextToSpeech text={teamMembers[0].description} />
            </div>
          </div>
        </div>

        {/* Toinen laatikko (Yhteystiedot) */}
        <div className="contact-info font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl p-8 shadow-lg dark:bg-violet bg-primary dark:bg-opacity-90 bg-opacity-90 flex flex-col justify-between h-full">
          <div>
            <h3 className="text-2xl font-bold text-darkest dark:text-primary text-left mb-4">
              <strong>Yhteystiedot:</strong>
            </h3>
            <p className="text-lg">
              <strong>Sähköposti: </strong><a href="mailto:arttu@spiderlegsproductions.com" className="text-darkest dark:text-primary underline" aria-label="Lähetä sähköposti Arttulle">arttu@spiderlegsproductions.com</a>
            </p>
            <p className="text-lg mt-2">
              <strong>Puhelin: </strong> <a href="tel:+358443519265" className="text-darkest dark:text-primary" aria-label="Soita Arttulle">+358 44 351 92 65</a>
            </p>
            <p className="text-lg mt-2">
              <strong>Aukioloajat: </strong><span className="text-darkest dark:text-primary">Ma–Pe: 8:00–18:00, La: 10:00–17:00. Myös joustavasti sopimuksen mukaan.</span>
            </p>
            <p className="mt-6 text-lg text-darkest dark:text-primary underline">
              <strong>Ota yhteyttä, jos tarvitset apua tai sinulla on kysyttävää. Olemme aina valmiita auttamaan.</strong>
            </p>
            <div className="mt-4 flex justify-center">
              <TextToSpeech text="Ota yhteyttä, jos tarvitset apua tai sinulla on kysyttävää. Olemme aina valmiita auttamaan." />
            </div>
          </div>

          <div className="flex flex-col items-center">
            <Counter end={36} label="Toteutuneet vuokrausprojektit" />
            <Counter end={324} label="Tyytyväisiä asiakkaita" />
            <Counter end={12} label="Vuokraustapahtumat vuodessa" />
          </div>

          <div className="mt-10 text-center">
            <p className="text-lg">Seuraa meitä!</p>
            <div className="flex justify-center space-x-4 my-5">
              <a href="https://www.facebook.com/spiderlegsproductions/" target="_blank" alt="Facebook logo" title="Seuraa Facebookissa!" rel="noopener noreferrer" aria-label="Seuraa Spiderlegs Productionsia Facebookissa">
                <FaFacebook size={30} />
              </a>
              <a href="https://www.instagram.com/spiderlegsproductions/" target="_blank" alt="Instagram logo" title="Seuraa Instagramissa!" rel="noopener noreferrer" aria-label="Seuraa Spiderlegs Productionsia Instagramissa">
                <FaInstagram size={30} />
              </a>
              <a href="https://www.linkedin.com/in/spiderlegs-productions/" target="_blank" alt="LinkedIn logo" title="Seuraa LinkedInissä!" rel="noopener noreferrer" aria-label="Seuraa Spiderlegs Productionsia LinkedInissä">
                <FaLinkedin size={30} />
              </a>
              <a href="https://www.tiktok.com/@spiderlegsproductions" target="_blank" alt="TikTok logo" title="Seuraa TikTokissa!" rel="noopener noreferrer" aria-label="Seuraa Spiderlegs Productionsia TikTokissa">
                <FaTiktok size={30} />
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Team;
