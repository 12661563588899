import PreSonus2000WHelmetComponent from './PreSonus2000WHelmetComponent'; // Lisätty Helmet-komponentti
import Footer from '../../components/Footer/Footer';
import PreSonus2000W from '../../components/PreSonus2000W/PreSonus2000W';

export default function preSonus2000W() {
    return (
        <>
            {/* Lisätty Helmet-komponentti */}
            <PreSonus2000WHelmetComponent />
            <PreSonus2000W />
            <Footer />
        </>
    );
}
