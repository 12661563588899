import { Helmet } from 'react-helmet-async';

function DB600HelmetComponent() {
  return (
    <Helmet>
      {/* Page title */}
      <title>Spiderlegs productions | Lavamonitori db FM12</title>
      
      {/* Meta description */}
      <meta name="description" content="Flexsys FM12 on hyvin varusteltu lavamonitori, se sopii ihanteellisesti lavalle koko bändin käyttöön. Voidaan asentaa kahteen eri asentoon." />
      
      {/* Meta keywords */}
      <meta name="keywords" content="db, technologies, 600W, fm12, monitori, bändi, bileet, lattiamonitori" />
      
      {/* Meta author */}
      <meta name="author" content="Arttu Stenius" />
      
      {/* Canonical link */}
      <link rel='canonical' href='https://spiderlegsproductions.com/lavamonitori-db-fm12-600w' />
      
      {/* Open Graph tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Spiderlegs productions | Lavamonitori db FM12" />
      <meta property="og:description" content="Flexsys FM12 on hyvin varusteltu lavamonitori, se sopii ihanteellisesti lavalle koko bändin käyttöön. Voidaan asentaa kahteen eri asentoon." />
      <meta property="og:image" content="https://spiderlegsproductions.com/assets/Lavamonitori_db_technologies_flexsys_FM12_600W_edesta.webp" />
      <meta property="og:url" content="https://spiderlegsproductions.com/lavamonitori-db-fm12-600w" />
      
      {/* Twitter Card tags */}
      <meta name="twitter:title" content="Spiderlegs productions | Lavamonitori db FM12" />
      <meta name="twitter:description" content="Flexsys FM12 on hyvin varusteltu lavamonitori, se sopii ihanteellisesti lavalle koko bändin käyttöön. Voidaan asentaa kahteen eri asentoon." />
      <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/Lavamonitori_db_technologies_flexsys_FM12_600W_edesta.webp" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default DB600HelmetComponent;
