import { Helmet } from 'react-helmet-async';

// Helmet-komponentti, joka määrittelee sivun otsikon ja meta-tiedot Soundcraft FX16 -mikserille
function SoundcraftFX16HelmetComponent() {
  return (
    <Helmet>
      {/* Sivun otsikko */}
      <title>Spiderlegs productions | Mikseri Soundcraft FX 16</title>
      
      {/* Meta-kuvaus */}
      <meta name="description" content="Soundcraft Spirit FX16 on monipuolinen 4-väyläinen mikseri, joka tuottaa digitaalisen äänenlaadun sekä live-esityksissä että studiokäytössä." />
      
      {/* Avainsanat */}
      <meta name="keywords" content="Soundcraft, FX, 16, mikseri, analogi" />
      
      {/* Sivuston kirjoittaja */}
      <meta name="author" content="Arttu Stenius" />
      
      {/* Kanoninen linkki */}
      <link rel='canonical' href='https://spiderlegsproductions.com/SoundcraftFX16' />
      
      {/* Open Graph -tunnisteet */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Spiderlegs productions | Mikseri Soundcraft FX 16" />
      <meta property="og:description" content="Soundcraft Spirit FX16 on monipuolinen 4-väyläinen mikseri, joka tuottaa digitaalisen äänenlaadun sekä live-esityksissä että studiokäytössä." />
      <meta property="og:image" content="https://spiderlegsproductions.com/assets/Soundcraft_Spirit_FX_16.webp" />
      <meta property="og:url" content="https://spiderlegsproductions.com/SoundcraftFX16" />
      
      {/* Twitter-kortin tunnisteet */}
      <meta name="twitter:title" content="Spiderlegs productions | Mikseri Soundcraft FX 16" />
      <meta name="twitter:description" content="Soundcraft Spirit FX16 on monipuolinen 4-väyläinen mikseri, joka tuottaa digitaalisen äänenlaadun sekä live-esityksissä että studiokäytössä." />
      <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/Soundcraft_Spirit_FX_16.webp" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default SoundcraftFX16HelmetComponent;
