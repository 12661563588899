import { Helmet } from 'react-helmet-async';

// Komponentti, joka määrittelee Helmet-elementit 300W äänentoistopaketin sivulle
function Mackie300WHelmetComponent() {
  return (
    <Helmet>
      {/* Sivun otsikko */}
      <title>Spiderlegs productions | 300W äänentoistopaketti</title>
      
      {/* Meta-kuvaus */}
      <meta name="description" content="Tämä äänentoistopaketti antaa 300W:n tehon. Se sopii mainiosti pieniin ja keskisuuriin tapahtumiin! Lue lisätiedot sivuiltamme!" />
      
      {/* Avainsanat */}
      <meta name="keywords" content="Mackie, 300W, ART-300A, thetmix, äänentoistopaketti, häät, bileet" />
      
      {/* Sivuston kirjoittaja */}
      <meta name="author" content="Arttu Stenius" />
      
      {/* Kanoninen linkki */}
      <link rel='canonical' href='https://spiderlegsproductions.com/300w-aanentoistopaketti' />
      
      {/* Open Graph -tunnisteet */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Spiderlegs productions | 300W Äänentoistopaketti" />
      <meta property="og:description" content="Tämä äänentoistopaketti antaa 300W:n tehon. Se sopii mainiosti pieniin ja keskisuuriin tapahtumiin! Lue lisätiedot sivuiltamme!" />
      <meta property="og:image" content="https://spiderlegsproductions.com/assets/PA_Mackie_RDF_ART-300A_300W.webp" />
      <meta property="og:url" content="https://spiderlegsproductions.com/300w-aanentoistopaketti" />
      
      {/* Twitter-kortin tunnisteet */}
      <meta name="twitter:title" content="Spiderlegs productions | 300W Äänentoistopaketti" />
      <meta name="twitter:description" content="Tämä äänentoistopaketti antaa 300W:n tehon. Se sopii mainiosti pieniin ja keskisuuriin tapahtumiin! Lue lisätiedot sivuiltamme!" />
      <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/PA_Mackie_RDF_ART-300A_300W.webp" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default Mackie300WHelmetComponent;
