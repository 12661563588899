import PartyBox300HelmetComponent from './PartyBox300HelmetComponent';
import Footer from '../../components/Footer/Footer';
import Partybox from '../../components/PartyBox300/PartyBox300';

const PartyBox = () => {
    return (
        <>
            <PartyBox300HelmetComponent />
            <Partybox />
            <Footer />
        </>
    );
};

export default PartyBox;
