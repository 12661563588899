import TextToSpeech from '../../TextToSpeech/textToSpeech';

// Component import

import Footer from '../../components/Footer/Footer';
import Accordion from '../../components/Accordion/Accordion';
import Header from '../../components/Headers/UKKHeader';

import UKKHelmetComponent from './UKKHelmetComponent';

const text =
"Ensimmäinen kysymys. Miten vuokraan?. Vastaus: Bilekaiuttimen tai äänentoistopaketin vuokraaminen on helppoa! Valitse haluamasi kaiutin tai äänentoistopaketti vuokrattavien laitteiden sivulta ja klikkaa tiedustele saatavuutta. Täytä lomake, jossa kysytään vuokrauksen ajankohtaa ja yhteystietojasi. Lähetä lomake! Vastaamme sinulle vuorokauden kuluessa.";

const text1 =
"Toinen kysymys. Miten kuljetuspalvelunne toimii?. Vastaus: Tarjoamme kätevän kuljetuspalvelun vuokratuille äänentoistolaitteille! Kuljetuksen hinta perustuu kilometrikorvaukseen. Hinta on 57 senttiä per kilometri. 10 senttiä lisää hintaan per kilometri peräkärryn kanssa ja +4 senttiä per kilometri jos kuljetettavan kaluston paino ylittää 80 kiloa. Tiedustele ja tilaa kuljetus hyvissä ajoin, jotta varmistamme sen saatavuuden.";

const text2 =
"Kolmas kysymys. En löytänyt osoitettanne sivuiltanne, miksei sitä ole näkyvissä?. Vastaus: Osoitteemme ei ole julkisesti näkyvillä verkkosivustollamme, koska meillä ei ole kivijalkakauppaa tai erikseen vuokrattua varastotilaa. Vuokrattavat äänentoistolaitteet sijaitsevat yksityisalueella. Mikäli toimitustapana on nouto, annamme osoitteen asiakkaalle sähköpostitse, viestillä tai puhelimitse vahvistuksen yhteydessä.";

const text3 =
"Neljäs kysymys. Onko mahdollista palkata äänimies illaksi käyttämään laitteita?. Vastaus: Kyllä, äänimiehen palkkaaminen on mahdollista! Kun palkkaat äänimiehen niin hyödyt ovat mittavat. Säästyt mm. näiltä huolilta: Laitteet eivät toimi optimaalisesti tapahtuman aikana, vähäntää stressiäsi, voit keskittyä olennaiseen ja äänentoistonlaatu on ammattilaisen käsissä parempi.";

const text4 =
"Viides kysymys. Voitteko tulla kytkemään laitteet toimintakuntoon juhlapaikalle?. Vastaus: Kyllä, tarjoamme kätevän asennus- ja opastuspalvelun. Palvelumme sisältää mm. laitteiden asennuksen, kytkemisen, testaamisen sekä säätämisen toimintakuntoon. Lopuksi opastamme teitä laitteiden käytössä tarpeentullen.";

const text5 =
"Kuudes kysymys. Mitä minun tulee ottaa huomioon äänentoistolaitteita vuokratessa?. Vastaus: Vuokrausta tehdessäsi sinulla tulisi olla ainakin nämä tiedot: tapahtuman päivämäärä, paikka ja osallistujamäärä.";

const text6 =
"Seitsemäs kysymys. Tarvitsemme tilaisuuteemme mikrofonin ja sille telineen, voimmeko vuokrata myös nämä teiltä?. Vastaus: Kyllä, vuokraamme myös mikrofoneja ja telineitä. Yleensä nämä räätälöidään äänentoistopakettiin asiakkaan tarpeiden mukaan. Voit tilata mikrofonit ja telineet myös erikseen, jos sinulla on jo äänentoistolaitteet. Muistathan tilata mikrofonit ja telineet hyvissä ajoin, jotta varmistamme niiden saatavuuden.";

const UKK = () => {

    return (

        <>
            <section>
                <UKKHelmetComponent />
                <Header />

                <div className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 pb-10 pt-7 rounded-t-3xl p-5 mb-10 mx-5">

                    <div className='flex'>

                        <TextToSpeech text={text} />
                            
                        <Accordion title="Miten vuokraan?" 
                    
                        answer="Bilekaiuttimen tai äänentoistopaketin vuokraaminen on helppoa! Valitse haluamasi kaiutin tai äänentoistopaketti vuokrattavien laitteiden sivulta ja klikkaa tiedustele saatavuutta. Täytä lomake, jossa kysytään vuokrauksen ajankohtaa ja yhteystietojasi. Lähetä lomake! Vastaamme sinulle vuorokauden kuluessa."/>
                
                    </div>

                    <div className='flex'>
                
                        <TextToSpeech text={text1} />

                        <Accordion title="Miten kuljetuspalvelunne toimii?" answer="Tarjoamme kätevän kuljetuspalvelun vuokratuille äänentoistolaitteille! 

                        Kuljetuksen hinta perustuu kilometrikorvaukseen: 57 senttiä/km, 
                        +10 senttiä/km peräkärryn kanssa ja +4 senttiä/km jos kuljetettavan kaluston paino ylittää 80 kg.
                        
                        Tiedustele ja tilaa kuljetus hyvissä ajoin, jotta varmistamme sen saatavuuden."/>

                    </div>

                    <div className='flex'>
                
                        <TextToSpeech text={text2} />

                        <Accordion title="En löytänyt osoitettanne sivuiltanne, miksei sitä ole näkyvissä?" answer="Osoitteemme ei ole julkisesti näkyvillä verkkosivustollamme, koska meillä ei ole kivijalkakauppaa tai erikseen vuokrattua varastotilaa. Vuokrattavat äänentoistolaitteet sijaitsevat yksityisalueella. Mikäli toimitustapana on nouto, annamme osoitteen asiakkaalle sähköpostitse, viestillä tai puhelimitse vahvistuksen yhteydessä."/>

                    </div>

                    <div className='flex'>

                        <TextToSpeech text={text3} />

                        <Accordion title="Onko mahdollista palkata äänimies illaksi käyttämään laitteita?" answer="Kyllä, äänimiehen palkkaaminen on mahdollista! Kun palkkaat äänimiehen niin hyödyt ovat mittavat. Säästyt mm. näiltä huolilta: Laitteet eivät toimi optimaalisesti tapahtuman aikana, vähäntää stressiäsi, voit keskittyä olennaiseen ja äänentoistonlaatu on ammattilaisen käsissä parempi."/>

                    </div>

                    <div className='flex'>

                        <TextToSpeech text={text4} />

                        <Accordion title="Voitteko tulla kytkemään laitteet toimintakuntoon juhlapaikalle?" answer="Kyllä, tarjoamme kätevän asennus- ja opastuspalvelun. Palvelumme sisältää mm. laitteiden asennuksen, kytkemisen, testaamisen sekä säätämisen toimintakuntoon. Lopuksi opastamme teitä laitteiden käytössä tarpeentullen."/>

                    </div>

                    <div className='flex'>

                        <TextToSpeech text={text5} />

                        <Accordion title="Mitä minun tulee ottaa huomioon äänentoistolaitteita vuokratessa?" answer="Vuokrausta tehdessäsi sinulla tulisi olla ainakin nämä tiedot: tapahtuman päivämäärä, paikka ja osallistujamäärä."/>

                    </div>

                    <div className='flex'>

                        <TextToSpeech text={text6} />

                        <Accordion title="Tarvitsemme tilaisuuteemme mikrofonin ja sille telineen, voimmeko vuokrata myös nämä teiltä?" answer="Kyllä, vuokraamme myös mikrofoneja ja telineitä. Yleensä nämä räätälöidään äänentoistopakettiin asiakkaan tarpeiden mukaan. Voit tilata mikrofonit ja telineet myös erikseen, jos sinulla on jo äänentoistolaitteet. Muistathan tilata mikrofonit ja telineet hyvissä ajoin, jotta varmistamme niiden saatavuuden."/>
        
                   </div>

                </div>
            </section>
            
            <Footer />
        </>
    );
};

export default UKK;
    