// src/components/LightRental/LightRental.jsx
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TextToSpeech from '../../TextToSpeech/textToSpeech';
import { toast } from 'react-toastify'; // Importoi toast
import "react-toastify/dist/ReactToastify.css"; // Varmista, että CSS on ladattu

const text = "Esitystekniikan vuokraus. Äänentoisto on vasta alkua! Nosta tapahtumasi seuraavalle tasolle upeilla valaistus- ja savutehosteilla. Oikeanlainen esitystekniikka luo unohtumattoman tunnelman ja tekee vaikutuksen yleisöösi.";

const LightRental = () => {
  const [lightRentalData, setLightRentalData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedQuantities, setSelectedQuantities] = useState({}); // Tallentaa valitut määrät

  useEffect(() => {
    const fetchLightRentals = async () => {
      try {
        const category = encodeURIComponent("esitystekniikka"); // Varmista URL-koodaus
        const response = await fetch(`https://spiderlegsproductions.com/api/get_vuokrattavat.php?category=${category}`);

        if (!response.ok) {
          throw new Error(`Palvelin palautti virheen: ${response.status}`);
        }

        const data = await response.json();
        console.log("Light Rental Data:", data);

        if (data.success && data.categories && Array.isArray(data.categories["esitystekniikka"])) {
          setLightRentalData(data.categories["esitystekniikka"]);
        } else {
          throw new Error("API:n vastausrakenne ei ole odotettu.");
        }
      } catch (err) {
        console.error("Virhe tietojen haussa:", err);
        setError("Tietojen lataus epäonnistui. Yritä myöhemmin uudelleen.");
        toast.error("Tietojen lataus epäonnistui. Yritä myöhemmin uudelleen."); // Lisää toast-error
      }
    };

    fetchLightRentals();
  }, []);

  const addToCart = (product) => {
    try {
      const quantityToAdd = selectedQuantities[product.id] || 1; // Käytä valittua määrää tai oletus 1

      // Tarkista, onko tuotetta riittävästi varastossa
      if (quantityToAdd > product.available) {
        toast.warn(
          `Tuotetta ${product.name} ei voi lisätä enempää koriin. Varastossa jäljellä ${product.available} kpl.`
        );
        return;
      }

      const cart = JSON.parse(localStorage.getItem("rentalCart")) || [];
      const existingProduct = cart.find((item) => item.id === product.id);

      // Päivitä olemassa olevan tuotteen määrä tai lisää uusi tuote
      if (existingProduct) {
        existingProduct.quantity += quantityToAdd;
      } else {
        cart.push({ ...product, quantity: quantityToAdd });
      }

      // Tallenna päivitetty ostoskori localStorageen
      localStorage.setItem("rentalCart", JSON.stringify(cart));
      window.dispatchEvent(new Event("storage")); // Ilmoita muille komponenteille tarvittaessa

      // Päivitä käyttöliittymän jäljellä oleva varasto
      setLightRentalData((prevData) =>
        prevData.map((item) =>
          item.id === product.id
            ? { ...item, available: item.available - quantityToAdd }
            : item
        )
      );

      toast.success(`${quantityToAdd} kpl ${product.name} lisätty vuokrauskoriin!`);
    } catch (error) {
      console.error("Virhe tuotteen lisäämisessä vuokrauskoriin:", error);
      toast.error("Virhe tuotteen lisäämisessä vuokrauskoriin. Yritä myöhemmin.");
    }
  };

  // Käsittelee määrän lisäämisen
  const incrementQuantity = (productId, available) => {
    setSelectedQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[productId] || 1;
      if (currentQuantity < available) {
        return { ...prevQuantities, [productId]: currentQuantity + 1 };
      }
      return prevQuantities;
    });
  };

  // Käsittelee määrän vähentämisen
  const decrementQuantity = (productId) => {
    setSelectedQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[productId] || 1;
      if (currentQuantity > 1) {
        return { ...prevQuantities, [productId]: currentQuantity - 1 };
      }
      return prevQuantities;
    });
  };

  return (
    <div className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 pb-10 pt-7 pl-5 pr-5">
      <div className="container">
        <div className="flex flex-row">
          <h2 className="text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight text-left font-customFont2 text-darkest dark:text-primary text-shadow-lg">
            ESITYSTEKNIIKAN VUOKRAUS
          </h2>

          <div className="speech-title ml-5 flex justify-center">
            <TextToSpeech text={text} />
          </div>
        </div>

        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl pb-10">
          Äänentoisto on vasta alkua! Nosta tapahtumasi seuraavalle tasolle upeilla valaistus- ja savutehosteilla. Oikeanlainen esitystekniikka luo unohtumattoman tunnelman ja tekee vaikutuksen yleisöösi.
        </p>

        {error ? (
          <div className="text-red-500 font-bold text-center mt-5">{error}</div>
        ) : (
          <div className="grid grid-cols-1 xxsm:grid-cols-2 xsm:grid-cols-2 bv9900:grid-cols-2 sm:grid-cols-2 md:grid-cols-2 mdd:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-4">
            {lightRentalData.map((item) => (
              <div key={item.id} className="bg-white bg-opacity-50 hover:bg-lightbg hover:bg-opacity-60 rounded-3xl relative group p-5 flex flex-col justify-between">
                <TextToSpeech text={item.kuvaus || "Ei kuvausta saatavilla."} />
                <div className="w-full h-[120px]">
                  <Link
                    to={item.link || "#"}
                    title={item.name}
                    onClick={() => {
                      if (item.link) {
                        window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                      }
                    }}
                    aria-label={`Näytä ${item.name} tuotekuvaus`}
                  >
                    <img
                      src={item.image || "assets/images/placeholder.jpg"}
                      alt={item.name || "Ei kuvaa saatavilla"}
                      title={item.name || "Ei kuvaa saatavilla"}
                      loading="lazy"
                      className="w-full h-[150px] object-contain sm:translate-x-0 transition duration-700 cursor-pointer drop-shadow-[3px_3px_5px_rgba(0,0,0,0.80)]"
                    />
                  </Link>
                </div>

                <div className="flex flex-col justify-between flex-grow">
                  <div className="grid grid-cols-1 place-items-center pt-10">
                    <h3 className="font-customFont1 text-shadow-lg text-center text-darkest dark:text-primary xxxsm:text-sm xxsm:text-base xsm:text-lg sm:text-xl md:text-2xl mdd:text-1xl lg:text-2xl xl:text-3xl font-normal pb-5" title={item.name}>
                      {item.name || "Tuntematon tuote"}
                    </h3>
                  </div>

                  {/* Näytä varastotiedot vain LED-valot Chauvet 56-24C */}
                  {item.name === "LED-valot Chauvet 56-24C" && (
                    <div className="text-center mb-2">
                      <p className="font-customFont1 text-darkest dark:text-primary text-shadow-lg text-lg font-normal">
                        Vuokrattavissa: {item.available} kpl
                      </p>
                    </div>
                  )}

                  <div className="mt-auto text-center">
                    <div className="font-customFont1 text-darkest dark:text-primary text-shadow-lg font-normal pb-2">
                      <p className="specs font-customFont1 text-darkest dark:text-primary text-shadow-lg xxxsm:text-base xxsm:text-lg xsm:text-xl sm:text-2xl md:text-3xl mdd:text-2xl lg:text-4xl xl:text-4xl font-normal">
                        {item.price ? `${item.price} € / 24h` : "Hinta ei saatavilla"}
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <Link
                        to={item.link || "#"}
                        title="Katso lisätiedot"
                        onClick={() => {
                          if (item.link) {
                            window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                          }
                        }}
                        aria-label={`Katso lisätiedot ${item.name}`}
                      >
                        <button
                          className="h-12 w-28 dark:bg-violet bg-lightbg dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out opacity-75"
                          title="Katso lisätiedot"
                        >
                          Lisätiedot
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>

              {/* Lisää "Lisää koriin" -nappi ja määrävalitsin vain LED-valot Chauvet 56-24C */}
                {item.name === "LED-valot Chauvet 56-24C" ? (
                  <div className="grid place-content-center mt-4">
                    <div className="flex flex-col items-center space-y-2">
                      {/* Määrän valitsin */}
                      <div className="flex items-center justify-center space-x-2">
                        {/* Vähennä määrää */}
                        <button
                          onClick={() => decrementQuantity(item.id)}
                          className="h-8 w-8 rounded-full bg-darkest text-primary font-bold flex items-center justify-center hover:bg-blue-900 dark:bg-violet dark:text-primary dark:hover:bg-blue-900"
                          title="Vähennä määrää"
                        >
                          -
                        </button>

                        {/* Näytä valittu määrä */}
                        <span className="text-xl font-bold text-darkest dark:text-primary">
                          {selectedQuantities[item.id] || 1}
                        </span>

                        {/* Lisää määrää */}
                        <button
                          onClick={() => incrementQuantity(item.id, item.available)}
                          className="h-8 w-8 rounded-full bg-darkest text-primary font-bold flex items-center justify-center hover:bg-blue-900 dark:bg-violet dark:text-primary dark:hover:bg-blue-900"
                          title="Lisää määrää"
                        >
                          +
                        </button>
                      </div>

                      {/* Lisää koriin -nappi */}
                      <button
                        title="Lisää vuokrauskoriin"
                        onClick={() => addToCart(item)}
                        className="h-12 w-32 sm:w-32 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none"
                        style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                      >
                        Lisää koriin
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center mt-4">
                    {/* Lisää koriin -nappi kaikille muille tuotteille */}
                    <button
                      title="Lisää vuokrauskoriin"
                      onClick={() => addToCart(item)}
                      className="h-12 w-32 sm:w-32 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none"
                      style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                    >
                      Lisää koriin
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};


    export default LightRental;
