import Hero from '../../components/Hero/Hero';
import About from '../../components/About/About';
import Services from '../../components/Services/Services';
import MostPopular from '../../components/MostPopular/MostPopular';
import Footer from '../../components/Footer/Footer';
import HomeHelmetComponent from './HomeHelmetComponent';

const Home = () => {
    return (
        <>
            <HomeHelmetComponent />
            <Hero />
            <MostPopular />
            <About />
            <Services />
            <Footer />
        </>
    );
};

export default Home;
