// src/components/PreSonus2000W/PreSonus2000W.jsx
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TextToSpeech from '../../TextToSpeech/textToSpeech';
import { toast } from 'react-toastify';
import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

// Import images
import MACKIE from '../../assets/Mackie_logo2.webp';
import PRESONUS from '../../assets/Presonus_logo.webp';
import PreSonus2000Wall from '../../assets/Presonus PA Mackie DLM12 2000W.webp';
import PRESONUSMIXER from '../../assets/Presonus Studiolive 16.0.2 mixer.webp';
import PRESONUSMIXERBACK from '../../assets/Presonus Studiolive 16.0.2 mixer back.webp';
import DB600W from '../../assets/Lavamonitori_db_technologies_flexsys_FM12_600W_edesta.webp';
import KAUKOKAAPELI from '../../assets/Kaukokaapeli.webp';

const PreSonus2000W = () => {
    const [available, setAvailable] = useState(0); // Alkuperäinen varasto
    const productId = "4"; // Tuotteen ID PreSonus 2000W -paketille
    const [activeImg, setActiveImage] = useState(PreSonus2000Wall);

    // Haetaan tuotteiden saatavuus backendistä
    useEffect(() => {
        const fetchAvailability = async () => {
            try {
                const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php");
                const data = await response.json();

                console.log("API response data:", data);

                // Hae varastotilanne kaikista kategorioista
                const inventory = Object.values(data.categories).flat();

                console.log("Yhdistetty inventory:", inventory);

                // Etsi tuote ID:n perusteella
                const currentProduct = inventory.find(item => item.id === productId);

                if (currentProduct) {
                    setAvailable(currentProduct.available);
                    console.log("Nykyisen tuotteen kappalemäärä:", currentProduct.available);
                } else {
                    console.error(`Tuotetta ei löytynyt ID:llä ${productId}!`);
                    toast.error("Tuotteen saatavuutta ei voitu hakea. Yritä myöhemmin uudelleen.", { position: "top-right" });
                }
            } catch (error) {
                console.error("Virhe haettaessa API-dataa:", error);
                toast.error("Virhe tuotteen saatavuuden haussa. Yritä myöhemmin uudelleen.", { position: "top-right" });
            }
        };

        fetchAvailability();
    }, [productId]);

    const handleImageClick = (image) => {
        setActiveImage(image);
    };

    const addToCart = async () => {
        try {
            const response = await fetch("https://spiderlegsproductions.com/api/get_vuokrattavat.php");
            const data = await response.json();

            // Hae varastotilanne kaikista kategorioista
            const inventory = Object.values(data.categories).flat();

            // Määritä tuotteen varasto
            const productStock = inventory.find(item => item.id === productId)?.available || 0;

            const quantity = 1; // Kiinteä määrä, koska määrän valinta on poistettu

            if (quantity > productStock) {
                toast.error("Tuotetta ei voi lisätä enempää vuokrauskoriin kuin mitä varastossa on!", { position: "top-right" });
                return;
            }

            // Hae vuokrauskori localStoragesta
            const cart = JSON.parse(localStorage.getItem("rentalCart")) || [];
            const product = {
                id: productId,
                name: "PreSonus 2000W äänentoistopaketti",
                price: 220, // Hinta (€ per päivä)
                quantity: quantity,
            };

            // Tarkista, onko tuote jo korissa
            const existingProduct = cart.find((item) => item.id === product.id);
      
            const newQuantity = existingProduct ? existingProduct.quantity + quantity : quantity;

            // Estä lisäämästä enemmän kuin varastossa
            if (newQuantity > productStock) {
                toast.error("Tuotetta ei voi lisätä enempää vuokrauskoriin kuin mitä varastossa on!", { position: "top-right" });
                return;
            }

            // Lisää tuote joko uutena tai päivitä määrä
            if (existingProduct) {
                existingProduct.quantity = newQuantity;
            } else {
                cart.push(product);
            }

            // Päivitä vuokrauskori localStorageen
            localStorage.setItem("rentalCart", JSON.stringify(cart));

            // Päivitä navbarin tila
            window.dispatchEvent(new Event("storage"));

            // Päivitä saatavuus
            setAvailable(prevAvailable => prevAvailable - quantity);

            // Ilmoita käyttäjälle lisäyksestä
            toast.success(`${quantity} x ${product.name} lisätty vuokrauskoriin!`, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } catch (error) {
            console.error("Virhe tuotteen lisäämisessä vuokrauskoriin:", error);
            toast.error("Virhe tuotteen lisäämisessä vuokrauskoriin. Yritä myöhemmin uudelleen.", { position: "top-right" });
        }
    };

    // Tekstit
    const text =
        "Mackie deeäläm kaksitoista kahdentuhannenwatin äänentoistopaketti digitaalimikserillä. Tällä setillä saa luotua kunnon bilemeiningin isompaankin tilaan, erilliset subbarit takaavat jytisevän basson tanssilattialle. Tämä paketti täyttää äänellään jopa viidensadan, kuudensadan ihmisen tilan. Se sopii erinomaisesti keskikokoisiin ja suuriin tapahtumiin.";

    const text1 =
        "Täydellinen saundi koko bändille. Pakettiin sisältyy myös PreSonuksen StudioLive kuusitoista piste nolla piste kaksi digitaalimikseri, joka tarjoilee kuusitoista kanavaa, neljä AUX, (monitorikanavaa) ja erinomaisen kirkkaan äänenlaadun. Tähän pakettiin on mahdollista myös lisävuokrata mukaan kaukokaapeli ja neljä kappaletta db technologies flexsys äfäm kaksitoista kuudensadanwatin monitoreita. Täydellinen paketti siis myös bändikäyttöön. Ota yhteyttä ja varaa tämä jämerä äänentoistopaketti tapahtumaasi nyt!";

    const text2 =
        "Hinta. Alkaen kaksisataakaksikymmentä euroa vuorokausi. Tiedustele saatavuutta kilkkaamalla alla olevaa nappia.";

    return (
        <>
            {/* Takaisin-painike */}
            <button 
                onClick={() => { window.history.back(); }} 
                className="h-12 w-32 dark:bg-violet bg-primary dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out focus:outline-none"
                aria-label="Takaisin"
            >
                Takaisin
            </button>

            {/* Tuotteen kuvat */}
            <div className="flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5">
                <div className="flex flex-col gap-5">
                    <img 
                        src={activeImg} 
                        alt="2000W äänentoistopaketti" 
                        title="2000W äänentoistopaketti" 
                        className="w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]" 
                    />

                    <div className="flex gap-5 flex-wrap justify-center drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]">
                        {/* Korvattu <img> <button>-elementeillä */}
                        <button 
                            onClick={() => handleImageClick(PreSonus2000Wall)} 
                            className="w-32 h-32 rounded-md p-0 border-0 bg-transparent cursor-pointer focus:outline-none"
                            aria-label="Näytä PreSonus 2000W äänentoistopaketti"
                        >
                            <img 
                                src={PreSonus2000Wall} 
                                alt="PreSonus PA Mackie DLM12 2000W" 
                                title="PreSonus PA Mackie DLM12 2000W" 
                                className="w-full h-full object-cover rounded-md" 
                            />
                        </button>

                        <button 
                            onClick={() => handleImageClick(PRESONUSMIXER)} 
                            className="w-32 h-32 rounded-md p-0 border-0 bg-transparent cursor-pointer focus:outline-none"
                            aria-label="Näytä Presonus Studiolive 16.0.2 mikseri"
                        >
                            <img 
                                src={PRESONUSMIXER} 
                                alt="Presonus Studiolive 16.0.2 mikseri" 
                                title="Presonus Studiolive 16.0.2 mikseri" 
                                className="w-full h-full object-cover rounded-md" 
                            />
                        </button>

                        <button 
                            onClick={() => handleImageClick(PRESONUSMIXERBACK)} 
                            className="w-32 h-32 rounded-md p-0 border-0 bg-transparent cursor-pointer focus:outline-none"
                            aria-label="Näytä Presonus Studiolive 16.0.2 mikseri takana"
                        >
                            <img 
                                src={PRESONUSMIXERBACK} 
                                alt="Presonus Studiolive 16.0.2 mikseri takana" 
                                title="Presonus Studiolive 16.0.2 mikseri takana" 
                                className="w-full h-full object-cover rounded-md" 
                            />
                        </button>

                        <button 
                            onClick={() => handleImageClick(DB600W)} 
                            className="w-32 h-32 rounded-md p-0 border-0 bg-transparent cursor-pointer focus:outline-none"
                            aria-label="Näytä db technologies Flexsys FM12 600W lavamonitori"
                        >
                            <img 
                                src={DB600W} 
                                alt="Lavamonitori db technologies Flexsys FM12 600W" 
                                title="Lavamonitori db technologies Flexsys FM12 600W" 
                                className="w-full h-full object-cover rounded-md" 
                            />
                        </button>

                        <button 
                            onClick={() => handleImageClick(KAUKOKAAPELI)} 
                            className="w-32 h-32 rounded-md p-0 border-0 bg-transparent cursor-pointer focus:outline-none"
                            aria-label="Näytä kaukokaapeli"
                        >
                            <img 
                                src={KAUKOKAAPELI} 
                                alt="Kaukokaapeli" 
                                title="Kaukokaapeli" 
                                className="w-full h-full object-cover rounded-md" 
                            />
                        </button>
                    </div>

                    {/* Tuotteen videot */}
                    <h2 className="font-customFont2 text-shadow-lg text-center text-primary dark:text-primary p-10 text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl font-bold">
                        Videot
                    </h2>
                    <div className="flex gap-5 flex-wrap justify-center aspect-video">
                        <iframe
                            className="aspect-auto"
                            src="https://www.youtube.com/embed/dgqSOB_8dwM?si=r7EgXnmANk9E1LN4"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Esittelyvideo PreSonus 2000W äänentoistopaketti"
                        ></iframe>

                        <iframe
                            className="aspect-auto"
                            src="https://www.youtube.com/embed/ZI2-dCuFFag?si=KJJP0YiIlUnTJ6SZ"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Demo PreSonus Studiolive 16.0.2 mixer"
                        ></iframe>

                        <iframe
                            className="aspect-auto"
                            src="https://www.youtube.com/embed/NiEwft_H5BE?si=2Vr83PPMDosoqZVY"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Tutorial: PreSonus PA Mackie DLM12 2000W setup"
                        ></iframe>
                    </div>
                </div>

                {/* Tuotteen kuvaus */}
                <div className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl">
                    <div>
                        {/* SPEECH */}
                        <div className="flex flex-row items-center">
                            {/* Otsikko */}
                            <h1 className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none">
                                Mackie DLM12 2000W äänentoistopaketti mikserillä
                            </h1>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text} />
                            </div> 
                        </div>

                        <br />

                        {/* Teksti */}
                        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
                            Tällä setillä saa luotua kunnon bilemeiningin isompaankin tilaan, erilliset subbarit takaavat jytisevän basson tanssilattialle.
                        </p>
                        <br />

                        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
                            Tämä paketti täyttää äänellään jopa 500-600 ihmisen tilan. Se sopii erinomaisesti keskikokoisiin ja suuriin tapahtumiin.
                        </p>

                        <br />

                        {/* SPEECH */}
                        <div className="flex flex-row items-center">
                            <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
                                Täydellinen saundi koko bändille
                            </p>
                            <div className='speech-title ml-5'>
                                <TextToSpeech text={text1} />
                            </div>
                        </div>
                            
                        <br />

                        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
                            Pakettiin sisältyy myös PreSonuksen StudioLive 16.0.2 -digitaalimikseri, joka tarjoilee 16 kanavaa, 4 AUX (monitorikanavaa) ja erinomaisen kirkkaan äänenlaadun. <br /><br />
                            Tähän pakettiin on mahdollista myös lisävuokrata mukaan kaukokaapeli ja 4 kpl db technologies flexsys FM12 600W:n monitoreita. Täydellinen paketti siis myös bändikäyttöön.
                        </p>
                        <br />

                        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
                            Ota yhteyttä ja varaa tämä jämerä äänentoistopaketti tapahtumaasi!
                        </p>

                        <div className="flex flex-row py-5 gap-2" title="Jaa tämä sivu">
                            <FacebookShareButton
                                url={"https://spiderlegsproductions.com/2000w-aanentoistopaketti"}
                                className="facebook-share-button flex flex-row items-center"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={"https://spiderlegsproductions.com/2000w-aanentoistopaketti"}
                                className="twitter-share-button flex flex-row items-center"
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>

                            <WhatsappShareButton
                                url={"https://spiderlegsproductions.com/2000w-aanentoistopaketti"}
                                className="whatsapp-share-button flex flex-row items-center"
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>

                            <LinkedinShareButton
                                url={"https://spiderlegsproductions.com/2000w-aanentoistopaketti"}
                                className="linkedin-share-button flex flex-row items-center"
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>

                            <EmailShareButton
                                url={"https://spiderlegsproductions.com/2000w-aanentoistopaketti"}
                                className="email-share-button flex flex-row items-center"
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                        </div>

                        {/* Hinta ja TextToSpeech */}
                        <div className="flex flex-row items-center">
                            {/* Hinta */}
                            <h2 
                                title="Hinta" 
                                className="font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold"
                            >
                                Alk. 220€ / vrk
                            </h2>

                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text2} />
                            </div>
                        </div>

                        {/* Lisää vuokrauskoriin-nappi */}
                        <div className="flex flex-col sm:flex-row items-center gap-4 pt-5">
                                <button
                                    title="Lisää vuokrauskoriin"
                                    onClick={addToCart}
                                    className={`h-12 w-full sm:w-60 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none ${
                                        available === 0 ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                                    style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                    disabled={available === 0}
                                >
                                    Lisää vuokrauskoriin
                                </button>

                                <Link 
                                    to="/varauskalenteri" 
                                    onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
                                    className="w-full sm:w-60"
                                >
                                    <button
                                        title="Siirry varaamaan"
                                        className="h-12 w-full dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer focus:outline-none"
                                        style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                    >
                                        Siirry varauskalenteriin
                                    </button>
                                </Link>
                            </div>

                        {/* Tuotteen logot */}
                        <div className="flex flex-wrap place-content-around pt-5">
                            <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">
                                {/* Tuotteen logo */}
                                <a href="https://mackie.com/" aria-label="Vieraile Mackien sivuilla.">
                                    <img 
                                        src={MACKIE} 
                                        alt="Mackie logo" 
                                        loading="lazy" 
                                        title="Vieraile Mackien sivuilla." 
                                        className="w-full h-full object-contain rounded-xl" 
                                    />
                                </a>
                            </div>
                            <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">
                                {/* Tuotteen logo */}
                                <a href="https://www.presonus.com/en/start" aria-label="Vieraile PreSonuksen sivuilla.">
                                    <img 
                                        src={PRESONUS} 
                                        alt="Presonus logo" 
                                        loading="lazy" 
                                        title="Vieraile PreSonuksen sivuilla." 
                                        className="w-full h-full object-contain rounded-xl" 
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PreSonus2000W;
