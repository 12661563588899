import Footer from '../../components/Footer/Footer';
import DMXgo from '../../components/DMXgo/DMXgo';
import ADJmyDMXGOHelmetcomponent from './ADJmyDMXGOHelmetComponent';

export default function ADJmyDMX() {
    return (
        <>
            <ADJmyDMXGOHelmetcomponent />
            <DMXgo />
            <Footer />
        </>
    );
}
