import { Link } from "react-router-dom";

import TextToSpeech from '../../TextToSpeech/textToSpeech';

// Images
import Kaukokaapeli from "../../assets/Kaukokaapeli.webp";

const text =
"Usein kysytyt kysymykset. Tervetuloa usein kysyttyjen kysymysten-osioon! Tässä osiossa vastaamme yleisimpiin kysymyksiin, joita asiakkaamme ovat tiedustelleet meiltä. Toivottavasti tämä osio auttaa sinua löytämään vastauksia kysymyksiisi. Mikäli ette löydä vastausta kysymykseenne, ota rohkeasti yhteyttä! Kysy lomakkeella! paina alla olevaa nappulaa ja kysy lomakkeen kautta! ";

const Header = () => {

  return (

    <div className="container min-h-[620px] flex p-5 ">

      <div className="grid grid-cols-1 place-items-center">

        <div data-aos="fade-in" className="order-1">

          {/* Image with alt text for accessibility */}
          <img 
            src={Kaukokaapeli} 
            title="Kaukokaapeli" 
            alt="Kaukokaapeli kaapeli, joka symboloi yhteydenpitoa" 
            className="xxxsm:scale-110 xxsm:scale-110 xsm:scale-110 sm:scale-125 md:scale-125 mdd:scale-125 pt-10 lg:scale-150 xl:scale-150 2xl:scale-150 rotate-25 relative z-20 max-h-[500px] drop-shadow-[10px_10px_8px_rgba(0,0,0,0.80)]"
          />

        </div>

        <div className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 p-5 order-2 sm:pr-20 rounded-3xl ">

          {/* Heading with TextToSpeech accessibility */}
          <div className="flex flex-row">
            <h1 
              className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight" 
              aria-label={text}  // Added aria-label for better accessibility
            >
              Usein kysytyt kysymykset
            </h1>
            <div className='speech-title ml-5 flex justify-center'>
              <TextToSpeech text={text} />
            </div>
          </div>

          {/* Body text with speech */}
          <p 
            className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal leading-8"
            aria-live="polite" // Ensures text is read out when it changes
          >
            Tervetuloa usein kysyttyjen kysymysten (FAQ) osioon! Tässä osiossa vastaamme yleisimpiin kysymyksiin, joita asiakkaamme ovat tiedustelleet meiltä.<br /><br />
            Toivottavasti tämä FAQ-osio auttaa sinua löytämään vastauksia kysymyksiisi.

            Mikäli ette löydä vastausta kysymykseenne, ota rohkeasti yhteyttä!
          </p>

          {/* Contact Form Button */}
          <div className="hero-button pt-4">
            <Link 
              to="/otayhteytta" 
              onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'auto' }); }}
            >
              <button 
                title="Kysy lomakkeella!" 
                className="h-12 w-64      
                  dark:bg-primary 
                    dark:hover:border-2
                    dark:hover:opacity-90
                  dark:border-white
                  dark:text-darkest 
                  bg-darkest 
                    hover:border-2
                    hover:border-opacity-90
                    hover:opacity-90
                  border-linkcolhover
                  text-primary 
                  font-customFont1 
                  font-bold 
                  text-2xl 
                  overflow-hidden 
                  transform hover:scale-x-110 
                  hover:scale-y-105 transition 
                  duration-300 ease-in
                  items-center 
                  rounded-full 
                  cursor-pointer" 
                alt="Kysy lomakkeella nappi"
                aria-label="Kysy lomakkeella" // Added aria-label for button
                style={{
                  boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)"
                }}
              >
                Kysy lomakkeella!
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
