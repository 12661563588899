import { Helmet } from 'react-helmet-async';

function AboutUsHelmetComponent() {
  return (
    <Helmet>
      {/* Page title */}
      <title>Spiderlegs productions | Meistä</title>
      
      {/* Meta description */}
      <meta name="description" content="Spiderlegs productions on Keravalla vaikuttava äänentoistoa- ja esitystekniikkaa vuokraava yritys. Lue lisää sivultamme!" />
      
      {/* Meta keywords */}
      <meta name="keywords" content="tietoa, meistä, info, spiderlegs, productions, about, us" />
      
      {/* Meta author */}
      <meta name="author" content="Arttu Stenius" />
      
      {/* Canonical link */}
      <link rel="canonical" href="https://spiderlegsproductions.com/meista" />
      
      {/* Open Graph tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Spiderlegs productions | Meistä" />
      <meta property="og:description" content="Spiderlegs productions on Keravalla vaikuttava äänentoistoa- ja esitystekniikkaa vuokraava yritys. Lue lisää sivultamme!" />
      <meta property="og:image" content="https://spiderlegsproductions.com/assets/new_logo.webp" />
      <meta property="og:url" content="https://spiderlegsproductions.com/meista" />
      
      {/* Twitter Card tags */}
      <meta name="twitter:title" content="Spiderlegs productions | Meistä" />
      <meta name="twitter:description" content="Spiderlegs productions on Keravalla vaikuttava äänentoistoa- ja esitystekniikkaa vuokraava yritys. Lue lisää sivultamme!" />
      <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/new_logo.webp" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default AboutUsHelmetComponent;
