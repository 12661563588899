import Footer from '../../components/Footer/Footer';
import Mackie300W from '../../components/Mackie300W/Mackie300';
import Mackie300WHelmetComponent from './Mackie300WHelmetComponent';


export default function mackie300W() {
    return (
        <>
            <Mackie300WHelmetComponent />
            <Mackie300W />
            <Footer />
        </>
    );
}

