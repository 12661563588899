import { Link } from 'react-router-dom';
import TextToSpeech from '../../TextToSpeech/textToSpeech';

// Images
import logo from '../../assets/new_logo.webp'

const text =
  "Tietoa meistä. Spiderlegs Productions on Keravalla, Keski-Uudellamaalla sijaitseva yritys, joka erikoistuu tarjoamaan laadukkaita tapahtumaratkaisuja kaikenlaisiin tilaisuuksiin. Tutustu meihin ja toimintaamme paremmin painamalla alla olevaa nappia!";

const About = () => {
  return (
    <div data-aos="fade-up" className="sm:min-h-[600px] sm:grid sm:place-items-center duration-300 pb-20 pt-20">
      <div className="container">
        <div className="grid grid-cols-1 xsm:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 mdd:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 place-items-center">
          <div data-aos="fade-right">
            <img src={logo} alt="Spiderlegs Productionsin logo" loading="lazy" title="Spiderlegs Productionsin logo" className="p-5 xxxsm:scale-100 xxsm:scale-100 xsm:scale-100 sm:scale-125 md:scale-125 mdd:scale-125 lg:scale-150 xl:scale-150 2xl:scale-150 sm:-translate-x-15 max-h-[300px] drop-shadow-[3px_3px_5px_rgba(0,0,0,0.80)]"/>
          </div>
          <div>
            <div className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 space-y-4 p-5 order-2 sm:order-1 rounded-3xl">
              <div data-aos="fade-up" className="flex flex-row items-center">
                <h2 className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight">TIETOA MEISTÄ</h2>
                <div className='speech-title ml-5 flex justify-center'>
                  <TextToSpeech text={text} aria-label="Lue tekstisisältö ääneen" />
                </div> 
              </div>
              <p data-aos="fade-up" className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
                Spiderlegs Productions on Keravalla, Keski-Uudellamaalla sijaitseva yritys, joka erikoistuu tarjoamaan laadukkaita tapahtumaratkaisuja kaikenlaisiin tilaisuuksiin.
              </p>
              <p data-aos="fade-up" className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
                Tutustu meihin ja toimintaamme paremmin painamalla alla olevaa nappia!
              </p>
              <div data-aos="fade-up" className="grid place-content-center">
                <div className="about-button p-2">
                  <Link to="/meista" onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'instant' }) }} role="button" aria-label="Lue lisää meistä">
                    <button className="h-12 w-52 dark:bg-primary 
                      dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest 
                      bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 
                      border-linkcolhover text-primary font-customFont1 font-bold text-2xl overflow-hidden 
                      transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center 
                      rounded-full cursor-pointer"
                      style={{
                          boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)"
                      }}>
                      Lue lisää!
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
