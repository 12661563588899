import { Helmet } from 'react-helmet-async';

// Komponentti, joka määrittelee Helmet-elementit JBL625W-sivulle
function JBL625WHelmetComponent() {
  return (
    <Helmet>
      {/* Sivun otsikko */}
      <title>Spiderlegs productions | 625W äänentoistopaketti</title>
      
      {/* Meta-kuvaus */}
      <meta name="description" content="Tämä äänentoistopaketti tarjoilee 625W:n tehon. Sopii mainiosti pieniin ja keskisuuriin tapahtumiin! Lue lisätiedot sivuiltamme!" />
      
      {/* Avainsanat */}
      <meta name="keywords" content="JBL, 625W, EON, Mackie, äänentoistopaketti, PROfxV3, häät, bileet" />
      
      {/* Sivuston kirjoittaja */}
      <meta name="author" content="Arttu Stenius" />
      
      {/* Kanoninen linkki */}
      <link rel='canonical' href='https://spiderlegsproductions.com/625w-aanentoistopaketti' />
      
      {/* Open Graph -tunnisteet */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Spiderlegs productions | 625W äänentoistopaketti" />
      <meta property="og:description" content="Tämä äänentoistopaketti tarjoilee 625W:n tehon. Sopii mainiosti pieniin ja keskisuuriin tapahtumiin! Lue lisätiedot sivuiltamme!" />
      <meta property="og:image" content="https://spiderlegsproductions.com/assets/JBL_EON_515_625W.webp" />
      <meta property="og:url" content="https://spiderlegsproductions.com/625w-aanentoistopaketti" />
      
      {/* Twitter-kortin tunnisteet */}
      <meta name="twitter:title" content="Spiderlegs productions | 625W äänentoistopaketti" />
      <meta name="twitter:description" content="Tämä äänentoistopaketti tarjoilee 625W:n tehon. Sopii mainiosti pieniin ja keskisuuriin tapahtumiin! Lue lisätiedot sivuiltamme!" />
      <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/JBL_EON_515_625W.webp" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default JBL625WHelmetComponent;
