import { Helmet } from 'react-helmet-async';

function LEDlightsHelmetComponent() {
  return (
    <Helmet>
      {/* Sivun otsikko */}
      <title>Spiderlegs productions | LED-valot Chauvet 56-24C</title>
      
      {/* Meta-kuvaus */}
      <meta name="description" content="LED-valot luovat tunnelmaa ja näyttävän valoshown juhlatilaan. Ääniohjatut valo-ohjelmat ja manuaalisesti säädettävä värit." />
      
      {/* Avainsanat */}
      <meta name="keywords" content="led, valot, chauvet, valoshow" />
      
      {/* Sivuston kirjoittaja */}
      <meta name="author" content="Arttu Stenius" />
      
      {/* Kanoninen linkki */}
      <link rel='canonical' href='https://spiderlegsproductions.com/led-valot-chauvet-56-24c' />
      
      {/* Open Graph -tunnisteet */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Spiderlegs productions | LED-valot Chauvet 56-24C" />
      <meta property="og:description" content="LED-valot luovat tunnelmaa ja näyttävän valoshown juhlatilaan. Ääniohjatut valo-ohjelmat ja manuaalisesti säädettävä värit." />
      <meta property="og:image" content="https://spiderlegsproductions.com/assets/LED-valot_Chauvet_56-24C.webp" />
      <meta property="og:url" content="https://spiderlegsproductions.com/led-valot-chauvet-56-24c" />
      
      {/* Twitter-kortin tunnisteet */}
      <meta name="twitter:title" content="Spiderlegs productions | LED-valot Chauvet 56-24C" />
      <meta name="twitter:description" content="LED-valot luovat tunnelmaa ja näyttävän valoshown juhlatilaan. Ääniohjatut valo-ohjelmat ja manuaalisesti säädettävä värit." />
      <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/LED-valot_Chauvet_56-24C.webp" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default LEDlightsHelmetComponent;
