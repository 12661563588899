import SoundcraftFX16HelmetComponent from './SoundcraftFX16HelmetComponent';
import Footer from '../../components/Footer/Footer';
import SoundcraftFX16 from '../../components/SoundcraftFX16/SoundcraftFX16';

// SoundcraftFX16-sivun pääkomponentti
export default function SoundcraftFX16Page() {
    return (
        <>
            {/* SoundcraftFX16-sivun otsikko ja meta-tiedot */}
            <SoundcraftFX16HelmetComponent />
            
            {/* SoundcraftFX16-sisältökomponentti */}
            <SoundcraftFX16 />
            
            {/* Sivualareunan footer */}
            <Footer />
        </>
    );
}
